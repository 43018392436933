import { Injectable } from '@angular/core';
import { PortfolioTypeEnum } from '@shared/enums/portfolio-type.enum';

@Injectable()
export class TranslationService {

  constructor() {}

  getTranslation(eniAlbedaKey: string, mooBmhKey: string, epaKey: string, portfolioTypeId: number) {
    switch(portfolioTypeId) {
      case PortfolioTypeEnum.ENI:
      case PortfolioTypeEnum.ALBEDA:
        return eniAlbedaKey;
      case PortfolioTypeEnum.MOO:
      case PortfolioTypeEnum.BMH:
        return mooBmhKey;
      default:
        return epaKey;
    }
  }

}
