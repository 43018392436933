import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {sortFilter} from '@shared/models/sort-filter.model';

@Component({
  selector: 'xf-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss'],
})
export class SortFilterComponent implements OnInit {
  @Input() isSortedBy = false;
  @Input() sort = '';

  @Output() sortFilterChanged = new EventEmitter<any>();

  sortFilterDisplayed = sortFilter;

  sortFilterSelected: {
    id: number;
    value: string;
    label: string;
    checked: boolean;
  };

  constructor() {
  }

  ngOnInit(): void {
    if (this.isSortedBy) {
      this.sortFilterSelected = this.sortFilterDisplayed.find(
        (f) => f.value === this.sort,
      );
    }
  }

  sortFilterClick(sortFilterId: number) {
    const sortFilterSelected = this.sortFilterDisplayed.find(
      (f) => f.id === sortFilterId,
    );
    sortFilterSelected.checked = true;
    this.sortFilterSelected = sortFilterSelected;

    this.sortFilterChanged.emit(sortFilterSelected);
  }
}
