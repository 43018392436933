import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberSetting } from '@client-shared-modules/models/member-setting.model';
import { HttpUploadedFile } from '@core/models/http-uploaded-file.model';
import { PasswordUpdate, UserProfileUpdate } from '@shared/models/user-profile-update.model';
import { UserProfile } from '@shared/models/user-profile.model';
import { Observable } from 'rxjs';
import { apiClientUserProfile } from '@api-client/api-urls';

@Injectable()
export class ProfileHttpService {

  constructor(private http: HttpClient) { }

  getUserProfile(studentId?: number): Observable<UserProfile> {
    let params = new HttpParams();
    if (studentId) {
      params = params.append('studentId', studentId.toString());
    }
    return this.http.get<UserProfile>(apiClientUserProfile, { params });
  }

  updateCurrentProfile(model: UserProfileUpdate): Observable<HttpResponse<any>> {
    return this.http.put('client/UserProfile', model, { observe: 'response' });
  }

  updatePassword(password: PasswordUpdate): Observable<HttpResponse<any>> {
    return this.http.put('client/UserProfile/ChangePassword', password, { observe: 'response' });
  }

  uploadProfilePic(imageData: FormData): Observable<HttpUploadedFile> {
    return this.http.post<HttpUploadedFile>('client/UserProfile/UploadTempPicture', imageData);
  }

  updatePortfolioMemberSetting(memberSetting: MemberSetting): Observable<any> {
    return this.http.put('client/UserProfile/UpdatePortfolioGroupMemberSettings', memberSetting);
  }
}
