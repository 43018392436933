export enum ErrorCode {
  None = 0,
  SecurityTokenInvalidSignatureException = 1,
  RequiredToLogin = 2,
  TokenExpired = 3,
  RefreshTokenExpired = 4,
  InvalidOwnerOrSupervisor = 5,
  AnonymousUser_SubmissionCompleteOrDenied = 6,
  AnonymousUser_SubmissionInvalidSupervisorOrAssessor = 7,
  AnonymousUser_SubmissionInvalidAssessor = 8,
  AnonymousUser_SubmissionInvalidSupervisor = 9,
  RequiredToLoginAndRedirectToSubmission = 10,
  SubmissionIsDeleted = 14,
  SubmissionNotFound = 15,
  MembershipRoleChanged = 16,
  AnonymousUser_SubmissionDeletedAssessor = 17,
  SubmissionIsRejected = 20,
  SubmissionAlreadyCompleted = 21,
  IOPTrainingProgramme_InvalidEndDate = 22,
  IOPTrainingProgramme_UnableToCalculate = 23,
  AnonymousUser_DeclinedSubmission = 24,
}
