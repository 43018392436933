import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private router: Router) {
  }

  logError(key: 'appErrorLogs' | 'missingTranslationLogs', message: string) {
    const currentRoute = this.router.url;
    const logs = JSON.parse(localStorage.getItem(key) || '[]');
    const messageExists = logs.some(log => log.message === message);

    if (!messageExists) {
      logs.push({message, route: currentRoute});
      localStorage.setItem(key, JSON.stringify(logs));
    }

    // Remove duplicates based on the "message" property
    const uniqueLogs = this.removeDuplicatesByMessage(logs);
    localStorage.setItem(key, JSON.stringify(uniqueLogs));
  }

  removeDuplicatesByMessage(logs: Array<{ message: string, route: string }>) {
    const seenMessages = new Set<string>();
    return logs.filter(item => {
      const isDuplicate = seenMessages.has(item.message);
      seenMessages.add(item.message);
      return !isDuplicate;
    });
  }

  getLogs(key: 'appErrorLogs' | 'missingTranslationLogs'): any[] {
    return JSON.parse(localStorage.getItem(key) || '[]');
  }
}
