<div class="container">
  <div class="main-content" fxLayout="column">
    <div class="header" fxLayoutAlign="start center">
      <ng-content select="epa-advance-dialog-header, [epa-advance-dialog-header], [advanceDialogHeader]"></ng-content>
    </div>
    <div class="content">
      <ng-content select="epa-advance-dialog-content, [epa-advance-dialog-content], [advanceDialogContent]"></ng-content>
    </div>
  </div>
  <div class="actions" fxLayout="column" fxLayoutAlign="start center">
    <div class="close-button-container" fxLayoutAlign="center center">
      <button
        mat-mini-fab
        color="primary"
        (click)="closeDialog.emit(true)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-content select="epa-advance-dialog-actions, [epa-advance-dialog-actions], [advanceDialogActions]"></ng-content>
  </div>
</div>
