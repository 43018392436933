import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IAppState } from '@core/store/app.reducers';
import { getCurrentInfo } from '@core/store/user-info/user-info.actions';
import { selectIsUserInfoLoaded } from '@core/store/user-info/user-info.selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

@Injectable()
export class UserInfoResolver implements Resolve<boolean> {

  constructor(private store: Store<IAppState>) { }

  resolve(): Observable<boolean> {

    return this.store.pipe(
      select(selectIsUserInfoLoaded),
      tap(isUserInfoLoaded => {
        if (!isUserInfoLoaded) {
          this.store.dispatch(getCurrentInfo({}));
        }
      }),
      filter(isUserInfoLoaded => !!isUserInfoLoaded),
      first(),
    );

  }

}
