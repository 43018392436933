import { Injectable, OnDestroy } from '@angular/core';
import { IAppState } from '@core/store/app.reducers';
import { blockAppUsage, refreshToken, semiLogout } from '@core/store/auth/auth.actions';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { IdleService } from './idle.service';
import { UrlService } from './url.service';

@Injectable()
export class TokenExpiryService implements OnDestroy {

  canRefreshToken = true;
  appBlockerEnabled = true;

  private _tokenExpiredTimeout: any;
  private _forcedLogoutTimeout: any;

  constructor(
    private store: Store<IAppState>,
    private idleService: IdleService,
    private urlService: UrlService) {}

  ngOnDestroy() {
    this.clear();
  }

  setExpiration(expiration: string | Date | number, forceReloginAfterInMilliseconds: number): number {
    this.clear();
    this.canRefreshToken = true;

    let expirationMilliseconds: number;
    if (typeof(expiration) === 'string') {
      expirationMilliseconds = Date.parse(expiration) - new Date().getTime();
    } else if (expiration instanceof Date) {
      expirationMilliseconds = expiration.getTime() - new Date().getTime();
    } else {
      expirationMilliseconds = expiration;
    }

    if (environment.idle.autoLogoutEnabled) {
      this._tokenExpiredTimeout = setTimeout(this._tokenExpired.bind(this), expirationMilliseconds);
    }

    if (environment.idle.forcedReloginAfterNoResponseEnabled) {
      this._forcedLogoutTimeout = setTimeout(this._forcedRelogin.bind(this), expirationMilliseconds + forceReloginAfterInMilliseconds);
    }

    return expirationMilliseconds;
  }

  public clear() {
    clearTimeout(this._tokenExpiredTimeout);
    clearTimeout(this._forcedLogoutTimeout);
  }

  // At this point, the token has already expired.
  // If the user was active in the last X seconds, we auto-refresh token.
  // If not, we block app usage.
  private _tokenExpired() {
    if (this.idleService.isIdle && this.canRefreshToken && this.appBlockerEnabled && environment.idle.autoLogoutEnabled) {
      this.store.dispatch(blockAppUsage());
      this.canRefreshToken = false;
    } else {
      this.canRefreshToken = true;
      if (environment.idle.autoLogoutEnabled) {
        this.store.dispatch(refreshToken());
      }
    }
  }

  // At this point, the token has already expired.
  private _forcedRelogin() {
    if (!environment.idle.autoLogoutEnabled || !environment.idle.forcedReloginAfterNoResponseEnabled) {
      return;
    }

    const loadRoute = escape(this.urlService.getCurrentRouteWithQueryAndFragments());
    this.store.dispatch(semiLogout());
  }

}
