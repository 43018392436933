import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'xf-datepicker-filter-table',
  templateUrl: './datepicker-filter.component.html',
  styleUrls: ['./datepicker-filter.component.scss'],
})
export class DatepickerFilterTableComponent implements OnInit {
  @Output() dateFromChanged = new EventEmitter<Date>();
  @Output() dateToChanged = new EventEmitter<Date>();

  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() isRequiredFields: boolean = false;

  inputDateFrom: Date;
  inputDateTo: Date;

  constructor() {}

  ngOnInit(): void {
    if (this.dateFrom) {
      this.inputDateFrom = new Date(new Date().setDate(new Date().getDate()));
      this.inputDateFrom = this.dateFrom;
    } else {
      this.dateFromChanged.emit(null);
    }
    if (this.dateTo) {
      this.inputDateTo = new Date(new Date().setDate(new Date().getDate()));
      this.inputDateTo = this.dateTo;
    } else {
      this.dateToChanged.emit(null);
    }
  }

  dateFromInput(event: MatDatepickerInputEvent<Date>) {
    const { value } = event;

    if (value) {
      if (!this.inputDateFrom) {
        this.inputDateFrom = new Date(new Date().setDate(new Date().getDate()));
        this.inputDateFrom = value;
      }
      if (!this.inputDateTo) {
        this.inputDateTo = new Date(new Date().setDate(new Date().getDate()));
      }
      this.inputDateFrom = value;
      if (this.inputDateFrom > this.inputDateTo) {
        this.inputDateTo = value;
      }
      this.dateFromChanged.emit(this.inputDateFrom);
      this.dateToChanged.emit(this.inputDateTo);
    } else {
      this.inputDateFrom = null;
      this.dateFromChanged.emit(null);
      this.dateToChanged.emit(this.inputDateTo);
      return;
    }
  }
  dateToInput(event: MatDatepickerInputEvent<Date>) {
    if (!this.inputDateTo) {
      this.inputDateTo = new Date(new Date().setDate(new Date().getDate()));
    }
    if (event.value) {
      if (event.value < this.inputDateFrom) {
        let dateFrom = new Date(new Date().setDate(new Date().getDate()));
        dateFrom = event.value;
        this.inputDateFrom = dateFrom;
        this.dateFromChanged.emit(this.inputDateFrom);
      }
      this.inputDateTo = event.value;
      this.dateToChanged.emit(this.inputDateTo);
    } else {
      this.dateToChanged.emit(null);
    }
  }

  requiredDateFromInput(event: MatDatepickerInputEvent<Date>) {
    const { value } = event;

    if (value) {
      if (!this.inputDateFrom) {
        this.inputDateFrom = new Date(new Date().setDate(new Date().getDate()));
        this.inputDateFrom = value;
      }
      if (!this.inputDateTo) {
        this.inputDateTo = new Date(new Date().setDate(new Date().getDate()));
      }
      this.inputDateFrom = value;
      if (this.inputDateFrom > this.inputDateTo) {
        this.inputDateTo = value;
      }
    } else {
      this.inputDateTo = null;
      this.inputDateFrom = null;
    }
    this.dateFromChanged.emit(this.inputDateFrom);
    this.dateToChanged.emit(this.inputDateTo);
  }
  requiredDateToInput(event: MatDatepickerInputEvent<Date>) {
    if (!this.inputDateTo) {
      this.inputDateTo = new Date(new Date().setDate(new Date().getDate()));
    }

    if (event.value) {
      if (event.value < this.inputDateFrom) {
        let dateFrom = new Date(new Date().setDate(new Date().getDate()));
        dateFrom = event.value;
        this.inputDateFrom = dateFrom;
        this.dateFromChanged.emit(this.inputDateFrom);
      }
    } else {
      this.inputDateFrom = null;
      this.dateFromChanged.emit(null);
      this.dateToChanged.emit(null);
    }
    this.inputDateTo = event.value;
    this.dateToChanged.emit(this.inputDateTo);
  }
}
