<div class="mat-elevation-z2">
  <mat-paginator hidePageSize [pageSize]="5" [pageIndex]="0"></mat-paginator>
  <mat-table [dataSource]="datasource">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.def">
      <mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="column.def === 'uploadDate' else multiSelect">
          <mat-form-field fxFlex>
            <mat-label>Upload Date</mat-label>
            <input matInput autocomplete="off" [matDatepicker]="picker" (click)="picker.open()">
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-template #multiSelect>
          <xf-dropdown-input-filter
            fxFlex
            [multiple]="true"
            [placeholder]="column.label">
          </xf-dropdown-input-filter>
        </ng-template>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row[column.def] }}</mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="actionColumn.def">
      <mat-header-cell *matHeaderCellDef>
        <span fxFlex>{{ actionColumn.label }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button>
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnDef"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnDef"></mat-row>
  </mat-table>
</div>
