import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAppState } from '@core/store/app.reducers';
import { Store } from '@ngrx/store';
import { IUserAuth } from '@regular-page-modules/auth/auth.model';
import { logout } from '@core/store/auth/auth.actions';
import { UrlService } from '@core/services/url.service';

@Component({
  selector: 'xf-relogin-notification-dialog',
  templateUrl: './relogin-notification-dialog.component.html',
  styleUrls: ['./relogin-notification-dialog.component.scss'],
})
export class ReloginNotificationDialogComponent {
  isLoading: boolean;

  constructor(
    private store: Store<IAppState>,
    private urlService: UrlService,
    private matDialogRef: MatDialogRef<ReloginNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      token: IUserAuth;
      hideClose: boolean;
      title: string;
      message: string;
    }
  ) {}

  onReLogin() {
    this.isLoading = true;

    const loadRoute = escape(
      this.urlService.getCurrentRouteWithQueryAndFragments()
    );
    this.store.dispatch(
      logout({
        reLogin: !!this.dialogData.token,
        userToken: this.dialogData.token,
        loadRoute,
      })
    );

    this.matDialogRef.close();
  }
}
