import { PortfolioGroupMemberItem } from '@client-services/models/portfolio-group-member.model';
import { EPAItem } from '@client-shared-modules/models/analytics/epa.model';
import { FeedbackItem } from '@client-shared-modules/models/analytics/feedback.model';
import { Internship } from '@client-shared-modules/models/internship.model';
import { IPortfolioGroupEpaItem } from '@management-shared/models/portfolio-group-epa.model';
import { IPortfolioGroupFormItem } from '@management-shared/models/portfolio-group-form.model';
import { IPortfolioGroupSubEpaItem } from '@management-shared/models/portfolio-group-sub-epa.model';
import { PortfolioGroup } from '@management-shared/models/portfolio-group.model';
import { IPortfolioType } from '@management-shared/models/potfolio-type.model';
import { ISpecialty } from '@management-shared/models/specialty.model';
import { ITranslation } from '@management-shared/models/translation.model';
import { UploadManagerItem } from '@management-shared/models/upload-manager.model';
import { NotificationEmailSender } from '@management/email-manager/models/notification-email-sender.model';
import { NotificationTemplateType } from '@management/email-manager/models/notification-template-type.model';
import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';
import { HttpFormUpload } from '@api-client/form-upload/models/http-form-upload.model';
import { HttpPortfolioGroupMember } from '@api-client/portfolio-group-member/portfolio-group-member-api.model';
import { HttpClientPortfolioType } from '@api-client/portfolio-type/portfolio-type-api.model';
import { HttpClientSpeciality } from '@api-client/speciality/speciality-api.model';
import { HttpClientEpa } from '@api-client/epa/models/http-client-epa.model';
import { HttpClientStudentEpa, HttpClientTrainingProgram } from '@api-client/student-epa/student-epa-api.model';
import { HttpEpaProgress } from '@api-client/epa/models/http-epa-progress.model';
import { HttpSubmissionReview } from '@api-client/form-upload/models/http-submission-review.model';
import { HttpFormSubmission } from '@api-client/form-upload/models/http-form-submissions.model';
import { HttpEvaluationAndReflection } from '@api-client/form-upload/models/http-evaluation-and-reflection.model';
import { HttpLinkedSubEpa } from '@api-client/epa/models/http-linked-subepa.model';
import { HttpClientEpaCompetenceLevel } from '@api-client/epa/models/http-client-epa-competence-level.model';
import { HttpClientPendingAssessment } from '@api-client/form-upload/models/http-client-pending-assessments.model';
import { IHttpEpa } from '@api-admin/epa/epa-api.model';
import { IHttpEpaBackground } from '@api-admin/epa-background/epa-background-api.model';
import { IHttpEpaBackgroundContent } from '@api-admin/epa-background-content/epa-background-content-api.model';
import { IHttpEpaFormCount } from '@api-admin/epa-form-count/epa-form-count-api.model';
import { IHttpEpaFormSubTypeCount } from '@api-admin/epa-form-subtype-count/epa-form-subtype-count-api.model';
import { IHttpFormSubType } from '@api-admin/form-subtype/form-subtype-api.model';
import { IHttpPortfolioType } from '@api-admin/portfolio-type/portfolio-type-api.model';
import { IHttpSpeciality } from '@api-admin/speciality/speciality-api.model';
import { IHttpSubEpa } from '@api-admin/sub-epa/sub-epa-api.model';
import { IHttpSubEpaBackground } from '@api-admin/sub-epa-background/sub-epa-background-api.model';
import {
  IHttpSubEpaBackgroundContent
} from '@api-admin/sub-epa-background-content/sub-epa-background-content-api.model';
import { IHttpSubEpaFormCount } from '@api-admin/sub-epa-form-count/sub-epa-form-count-api.model';
import {
  IHttpSubEpaFormSubTypeCount
} from '@api-admin/sub-epa-form-subtype-count/sub-epa-form-subtype-count-api.model';
import { MembershipItem } from '@management-shared/models/membership.model';
import { HttpInternshipSubmission } from '@api-client/form-upload/models/http-internship-submission.model';
import { HttpClientCategory } from '@api-client/category/category-api.model';

const entityMetadata: EntityMetadataMap = {
  User: {},
  AdminEpa: {
    entityName: 'AdminEpa',
    selectId: (epa: IHttpEpa) => epa.id,
  },
  AdminSubEpa: {
    entityName: 'AdminSubEpa',
    selectId: (subEpa: IHttpSubEpa) => subEpa.id,
  },
  AdminEpaBackground: {
    entityName: 'AdminEpaBackground',
    selectId: (epaBackground: IHttpEpaBackground) => epaBackground.id,
  },
  AdminSubEpaBackground: {
    entityName: 'AdminSubEpaBackground',
    selectId: (subEpaBackground: IHttpSubEpaBackground) => subEpaBackground.id,
  },
  AdminEpaBackgroundContent: {
    entityName: 'AdminEpaBackgroundContent',
    selectId: (epaBackgroundContent: IHttpEpaBackgroundContent) => epaBackgroundContent.id,
  },
  AdminSubEpaBackgroundContent: {
    entityName: 'AdminSubEpaBackgroundContent',
    selectId: (subEpaBackgroundContent: IHttpSubEpaBackgroundContent) => subEpaBackgroundContent.id,
  },
  AdminPortfolioType: {
    entityName: 'AdminPortfolioType',
    selectId: (portfolioType: IHttpPortfolioType) => portfolioType.id,
  },
  AdminSpeciality: {
    entityName: 'AdminSpeciality',
    selectId: (speciality: IHttpSpeciality) => speciality.id,
  },
  AdminEpaFormCount: {
    entityName: 'AdminEpaFormCount',
    selectId: (epaFormCount: IHttpEpaFormCount) => epaFormCount.id,
  },
  AdminSubEpaFormCount: {
    entityName: 'AdminSubEpaFormCount',
    selectId: (subEpaFormCount: IHttpSubEpaFormCount) => subEpaFormCount.id,
  },
  AdminEpaFormSubTypeCount: {
    entityName: 'AdminEpaFormSubTypeCount',
    selectId: (epaFormSubTypeCount: IHttpEpaFormSubTypeCount) => epaFormSubTypeCount.id,
  },
  AdminSubEpaFormSubTypeCount: {
    entityName: 'AdminSubEpaFormSubTypeCount',
    selectId: (subEpaFormSubTypeCount: IHttpSubEpaFormSubTypeCount) => subEpaFormSubTypeCount.id,
  },
  AdminFormSubType: {
    entityName: 'AdminFormSubType',
    selectId: (formSubType: IHttpFormSubType) => formSubType.id,
  },
  ClientPortfolioGroupMember: {
    entityName: 'ClientPortfolioGroupMember',
    selectId: (entity: HttpPortfolioGroupMember) => entity.portfolioGroupMemberId,
  },
  Translation: {
    entityName: 'Translation',
    selectId: (translation: ITranslation) => translation.resourceKey,
  },
  PortfolioGroup: {
    entityName: 'PortfolioGroup',
    selectId: (portfolioGroup: PortfolioGroup) => portfolioGroup.id,
  },
  ClientPortfolioGroup: {
    entityName: 'ClientPortfolioGroup',
    selectId: (portfolioGroup: PortfolioGroup) => portfolioGroup.id,
  },
  PortfolioType: {
    entityName: 'PortfolioType',
    selectId: (portfolioType: IPortfolioType) => portfolioType.id,
  },
  ClientPortfolioType: {
    entityName: 'ClientPortfolioType',
    selectId: (portfolioType: HttpClientPortfolioType) => portfolioType.id,
  },
  ClientSpeciality: {
    entityName: 'ClientSpeciality',
    selectId: (speciality: HttpClientSpeciality) => speciality.id,
  },
  ClientEpa: {
    entityName: 'ClientEpa',
    selectId: (speciality: HttpClientEpa) => speciality.id,
  },
  ClientStudentEpa: {
    entityName: 'ClientStudentEpa',
    selectId: (studentEpa: HttpClientStudentEpa) => studentEpa.epaId,
  },
  Speciality: {
    entityName: 'Speciality',
    selectId: (speciality: ISpecialty) => speciality.id,
  },
  NotificationTemplate: {},
  Internship: {
    entityName: 'Internship',
    selectId: (internship: Internship) => internship.id,
  },
  EpaManagerFormSelection: {
    entityName: 'EpaManagerFormSelection',
    selectId: (epaManagerFormSelection: IPortfolioGroupFormItem) => epaManagerFormSelection.id,
  },
  EpaManagerEpaContent: {
    entityName: 'EpaManagerEpaContent',
    selectId: (epaManagerEpaContent: IPortfolioGroupEpaItem) => epaManagerEpaContent.id,
  },
  EpaManagerSubEpaContent: {
    entityName: 'EpaManagerSubEpaContent',
    selectId: (epaManagerSubEpaContent: IPortfolioGroupSubEpaItem) => epaManagerSubEpaContent.id,
  },
  EpaManagerBackgroundInfo: {
    entityName: 'EpaManagerBackgroundInfo',
  },
  EpaManagerSubEpaBackgroundInfo: {
    entityName: 'EpaManagerSubEpaBackgroundInfo',
  },
  EpaManagerBackgroundInfoContent: {
    entityName: 'EpaManagerBackgroundInfoContent',
  },
  EpaManagerSubEpaBackgroundInfoContent: {
    entityName: 'EpaManagerSubEpaBackgroundInfoContent',
  },
  EpaManagerFormDescription: {
    entityName: 'EpaManagerFormDescription',
  },
  EpaManagerSubEpaFormDescription: {
    entityName: 'EpaManagerSubEpaFormDescription',
  },
  EpaManagerFormSubtypeCount: {
    entityName: 'EpaManagerFormSubtypeCount',
  },
  EpaManagerSubEpaFormSubtypeCount: {
    entityName: 'EpaManagerSubEpaFormSubtypeCount',
  },
  ClientInternshipLinkedAssessment: {
    entityName: 'ClientInternshipLinkedAssessment',
  },
  ClientFormUpload: {
    entityName: 'ClientFormUpload',
    selectId: (formUpload: HttpFormUpload<any>) => formUpload.id,
  },
  HeadTeacherCourseGroupTeacher: {
    entityName: 'HeadTeacherCourseGroupTeacher',
    selectId: (teacher: PortfolioGroupMemberItem) => teacher.portfolioGroupMemberId,
  },
  HeadTeacherCourseGroupStudent: {
    entityName: 'HeadTeacherCourseGroupStudent',
    selectId: (student: PortfolioGroupMemberItem) => student.portfolioGroupMemberId,
  },
  AnalyticsFeedback: {
    entityName: 'AnalyticsFeedback',
    selectId: (feedback: FeedbackItem) => feedback.formUploadId,
  },
  AnalyticsEPA: {
    entityName: 'AnalyticsEPA',
    selectId: (EPA: EPAItem) => EPA.ownerId,
  },
  TeacherAnalyticsEPA: {
    entityName: 'TeacherAnalyticsEPA',
    selectId: (EPA: EPAItem) => EPA.ownerId,
  },
  Membership: {
    entityName: 'Membership',
    selectId: (membership: MembershipItem) => membership.id,
  },
  UploadManager: {
    entityName: 'UploadManager',
    selectId: (uploadManager: UploadManagerItem) => uploadManager.id,
  },
  NotificationEmailSender: {
    entityName: 'NotificationEmailSender',
    selectId: (notificationEmailSender: NotificationEmailSender) => notificationEmailSender.id,
  },
  NotificationTemplateType: {
    entityName: 'NotificationTemplateType',
    selectId: (notificationTemplateType: NotificationTemplateType) => notificationTemplateType.id,
  },
  ClientTrainingProgram: {
    entityName: 'ClientTrainingProgram',
    selectId: (data: HttpClientTrainingProgram) => data.portfolioTypeId + ' ' + data.categoryId,
  },
  ClientEpaProgress: {
    entityName: 'ClientEpaProgress',
    selectId: (data: HttpEpaProgress) => data.epaId,
  },
  ClientSubmissionReview: {
    entityName: 'ClientSubmissionReview',
    selectId: (data: HttpSubmissionReview) => data.id,
  },
  ClientFormSubmission: {
    entityName: 'ClientFormSubmission',
    selectId: (data: HttpFormSubmission) => data.formId,
  },
  ClientEvaluationAndReflection: {
    entityName: 'ClientEvaluationAndReflection',
    selectId: (data: HttpEvaluationAndReflection) => data.formUploadId,
  },
  ClientEpaLinkedSubEpa: {
    entityName: 'ClientEpaLinkedSubEpa',
    selectId: (data: HttpLinkedSubEpa) => data.subEpaId,
  },
  ClientEpaLevelOfCompetence: {
    entityName: 'ClientEpaLevelOfCompetence',
    selectId: (data: HttpClientEpaCompetenceLevel) => data.ownerId,
  },
  ClientFormUploadPendingAssessments: {
    entityName: 'ClientFormUploadPendingAssessments',
    selectId: (data: HttpClientPendingAssessment) => data.formUploadId,
  },
  ClientInternshipSubmission: {
    entityName: 'ClientInternshipSubmission',
    selectId: (data: HttpInternshipSubmission) => data.formUploadId,
  },
  ClientCategory: {
    entityName: 'ClientCategory',
    selectId: (category: HttpClientCategory) => category.id,
  },
  ClientGetAssessmentRequests: {
    entityName: 'ClientGetAssessmentRequests',
  },
  ClientGetEpaRequests: {
    entityName: 'ClientGetEpaRequests',
  },
  ClientGetReviewNewlyCreatedEpas: {
    entityName: 'ClientGetReviewNewlyCreatedEpas',
  },
  ClientGetSystemActions: {
    entityName: 'ClientGetSystemActions',
  },
  ClientGetDepartmentTimeSavedWithAiImplementation: {
    entityName: 'ClientGetDepartmentTimeSavedWithAiImplementation',
  },
  ClientGetEmployeeTimeSavedWithAiImplementation: {
    entityName: 'ClientGetEmployeeTimeSavedWithAiImplementation',
  },
  ClientGetEPAImplementation: {
    entityName: 'ClientGetEPAImplementation',
  },
  ClientGetCompletedAssessments: {
    entityName: 'ClientGetCompletedAssessments',
  },
};

const pluralNames = {};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames,
};
