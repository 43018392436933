<mat-label id="dateFrom">{{ "from" | localize }}</mat-label>
<mat-form-field appearance="outline">
  <input
    matInput
    formControlName="dateFrom"
    [matDatepicker]="dateFrom"
    (click)="dateFrom.open()"
    (dateInput)="
      isRequiredFields ? requiredDateFromInput($event) : dateFromInput($event)
    "
    [value]="inputDateFrom"
  />

  <mat-datepicker-toggle matSuffix [for]="dateFrom"> </mat-datepicker-toggle>

  <mat-datepicker #dateFrom> </mat-datepicker>
</mat-form-field>

<mat-label id="dateTo">{{ "to" | localize }}</mat-label>
<mat-form-field appearance="outline">
  <input
    matInput
    formControlName="dateTo"
    [matDatepicker]="dateTo"
    (click)="dateTo.open()"
    (dateInput)="
      isRequiredFields ? requiredDateToInput($event) : dateToInput($event)
    "
    [value]="inputDateTo"
  />

  <mat-datepicker-toggle matSuffix [for]="dateTo"> </mat-datepicker-toggle>

  <mat-datepicker #dateTo> </mat-datepicker>
</mat-form-field>
