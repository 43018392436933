<button *ngIf="!hideClose" mat-icon-button type="button" aria-label="Close dialog" (click)="onClose()">
  <mat-icon aria-label="Close">close</mat-icon>
</button>
<div class="content" fxLayout="column" fxLayoutGap="2em">
  <h2 mat-dialog-title>
    {{ dialogTitle }}
    <mat-icon *ngIf="tooltipMessage" matTooltip='{{ tooltipMessage }}'>help_outline</mat-icon>
  </h2>
  <ng-content select="mat-dialog-content"></ng-content>
  <ng-content select="mat-dialog-actions"></ng-content>
</div>
<div *ngIf="isLoading" class="loader">
  <mat-spinner></mat-spinner>
</div>
