import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpBannerNotification } from '@core/models/banner-notification-api.model';
import { Observable } from 'rxjs';

@Injectable()
export class BannerNotificationHttpService {

  constructor(private _httpClient: HttpClient) {}

  getBannerNotification(): Observable<IHttpBannerNotification> {
    return this._httpClient.get<IHttpBannerNotification>('client/BannerMessage');
  }
}
