import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FORMS } from '@shared/enums/forms.enum';

@Injectable()
export class FormRedirectionService {

  constructor(private router: Router) {
  }

  navigate(formId: number) {
    this.router.navigateByUrl(`/${ FORMS[formId].routeName }`);
  }

}
