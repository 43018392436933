import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setDataLoaded } from '@core/store/dashboard/dashboard.actions';
import { DashboardData } from '@shared/models/dashboard-data';
import { DashboardFilters } from '@shared/models/filters-list.model';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from '@core/store/app.reducers';
import {
  DepartmentUserActives,
  DepartmentUserActivesInput,
  EmployeeUserActives,
  EmployeeUserActivesInput,
  FiltersList,
} from '@shared/models/user-activity.model';

@Injectable()
export class DashboardHttpService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {}

  getDashboardData(
    departmentId = 0,
    periodOptionId = 0
  ): Observable<DashboardData> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    let params = new HttpParams();
    params = params.append('DepartmentId', departmentId.toString());
    params = params.append('PeriodOptionId', periodOptionId.toString());
    return this.http
      .get<DashboardData>('Dashboard', { params })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  getDepartmentUserActives(
    input: DepartmentUserActivesInput
  ): Observable<DepartmentUserActives> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    let params = new HttpParams();
    for (const key of Object.keys(input)) {
      if (input[key] || input[key] === 0) {
        if (input[key] instanceof Array) {
          input[key].forEach((id) => {
            params = params.append(`${key.toString()}`, id);
          });
        } else {
          params = params.append(key, input[key].toString());
        }
      }
    }
    return this.http
      .get<DepartmentUserActives>('Dashboard/GetDepartmentUserActivies', {
        params,
      })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  getEmployeeUserActives(
    input: EmployeeUserActivesInput
  ): Observable<EmployeeUserActives> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    let params = new HttpParams();
    for (const key of Object.keys(input)) {
      if (input[key] || input[key] === 0) {
        params = params.append(key, input[key].toString());
      }
    }
    return this.http
      .get<EmployeeUserActives>('Dashboard/GetEmployeeUserActivies', { params })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  getFiltersList(): Observable<FiltersList> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    return this.http
      .get<FiltersList>('Dashboard/GetFilterLists')
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  getFiltersListParameters(): Observable<DashboardFilters> {
    return this.http.get<DashboardFilters>('Dashboard/GetFilterLists');
  }
}
