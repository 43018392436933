import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface MyReviews {
  uploadDate: string;
  submissionType: string;
  subType: string;
  subEpa: string;
  resident: string;
  status: string;
}

export interface Column {
  def: string;
  label: string;
}

const ELEMENT_DATA: MyReviews[] = [
  {
    uploadDate: '07/08/2019',
    submissionType: 'Case based discussion',
    subType: 'General',
    subEpa: 'Shock',
    resident: 'Dr. Test Test',
    status: 'Pending Assessment',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'CAT',
    subType: 'Intensive Care',
    subEpa: 'Reanimation',
    resident: 'Dr. Test Test',
    status: 'Completed',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'KPB',
    subType: 'Aterial puncture',
    subEpa: 'Critically ill patient',
    resident: 'Dr. Test Test',
    status: 'Approved',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'OSATS',
    subType: 'Urine catheter placement',
    subEpa: 'Low complex patient',
    resident: 'Dr. Test Test',
    status: 'None',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'Theoretical exam',
    subType: 'Cardioversion',
    subEpa: 'Knowledge',
    resident: 'Dr. Test Test',
    status: 'Rejected',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'Procedure registration',
    subType: 'Defibrillation',
    subEpa: 'Management',
    resident: 'Dr. Test Test',
    status: 'Invalid',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'Certificate',
    subType: 'Stitching',
    subEpa: 'High complex patient (initial care)',
    resident: 'Dr. Test Test',
    status: 'Partially rejected',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'Scientific activity',
    subType: 'Eyepatch placement',
    subEpa: 'High complex patient (definitive care)',
    resident: 'Dr. Test Test',
    status: 'Concept',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'Individual training plan',
    subType: 'Brain CT assessment',
    subEpa: 'Science',
    resident: 'Dr. Test Test',
    status: 'Not supervised',
  },
  {
    uploadDate: '07/08/2019',
    submissionType: 'Case based discussion',
    subType: 'ATLS',
    subEpa: 'Respiratory',
    resident: 'Dr. Test Test',
    status: 'Reported',
  },
];

@Component({
  selector: 'xf-my-reviews-table-template',
  templateUrl: './my-reviews-table-template.component.html',
  styleUrls: ['./my-reviews-table-template.component.scss'],
})
export class MyReviewsTableTemplateComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  columns: Column[] = [
    { def: 'uploadDate', label: 'Upload Date' },
    { def: 'submissionType', label: 'Submission Type' },
    { def: 'subType', label: 'Subtype' },
    { def: 'subEpa', label: '(sub-)EPA' },
    { def: 'resident', label: 'Resident' },
    { def: 'status', label: 'Status' },
  ];

  columnDef: string[];
  actionColumn: Column = { def: 'action', label: '' };
  datasource: MatTableDataSource<MyReviews>;

  ngOnInit() {
    this.datasource = new MatTableDataSource<MyReviews>(ELEMENT_DATA);
    this.columnDef = this.columns.map((column) => column.def).slice();
    this.columnDef.push(this.actionColumn.def);
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }
}
