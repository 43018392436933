<xf-base-dialog
  dialogTitle="{{ dialogData.title | localize }}"
  [isLoading]="isLoading"
  [hideClose]="dialogData.hideClose"
>
  <mat-dialog-content>
    <div fxFlex="100" fxLayoutAlign="center center">
      <div
        fxFlex="70"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="2em"
      >
        <span>
          <strong>{{ dialogData.message | localize }}</strong>
        </span>
        <span>{{ "re_login" | localize }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="center center">
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="onReLogin()"
    >
      {{ "ok" | localize }}
    </button>
  </mat-dialog-actions>
</xf-base-dialog>
