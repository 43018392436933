import { Pipe, PipeTransform } from '@angular/core';

/**
 * TODO: Enhance. Accept multiple replacer
 *       and replace multiple keys
 *
 */

@Pipe({
  name: 'localizeKeyReplacer',
})
export class LocalizationReplacePipe implements PipeTransform {

  transform(text: string, replacer: string): string {
    const firstKey = '{0}';
    return text.replace(firstKey, replacer);
  }

}
