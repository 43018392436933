import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheKeyService } from '@core/services/cache-key.service';
import { CacheService } from '@core/services/cache.service';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private static _blacklistedApis: string[] = [
    'client/Accounts',
    'client/Notifications',
    'client/PortfolioGroups',
    'client/PortfolioGroupMember/GetRequiredActions',
    'client/StudentEPA/RequiredSubmission/LatestStudentUpdate',
    'client/Epa/GetEPADefaultRequiredSubmissions',
    'admin/FormSubType',
    'client/IOP/GetStudentSpecialitySchedule',
    'client/FormUpload',
    'client/Localizations',
    'api.openai.com',
    'client/EPALibrary/SearchEmployee',
  ];

  constructor(
    private _cacheService: CacheService,
    private _cacheKeyService: CacheKeyService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      request.method !== 'GET' ||
      !request.url.toLowerCase().startsWith(environment.apiUrl.toLowerCase()) ||
      CacheInterceptor._blacklistedApis.some(
        (x) => request.url.toLowerCase().indexOf(x.toLowerCase()) >= 0,
      ) ||
      request.url.toLowerCase().indexOf('mock.json') >= 0 ||
      request.urlWithParams.toLowerCase().indexOf('nohttpcache=true') >= 0 ||
      !(
        environment.cache.enabled && environment.cache.httpRequestCachingEnabled
      )
    ) {
      return next.handle(request);
    }

    const requestKey = this._cacheKeyService.getRequestCacheKey(request);
    if (this._cacheService.hasKey(requestKey)) {
      const response = this._cacheService.get(requestKey)
        .data as HttpResponse<any>;

      if (environment.cache.keepAlive) {
        this._cacheService.set(requestKey, response); // Set again to get new expiry date
      }

      return of(response.clone());
    } else {
      return next.handle(request).pipe(
        tap((stateEvent) => {
          if (stateEvent instanceof HttpResponse) {
            this._cacheService.set(requestKey, stateEvent.clone());
          }
        }),
      );
    }
  }
}
