import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {HubConnectionBuilder, IHttpConnectionOptions, LogLevel} from '@aspnet/signalr';
import {Notification, SignalRNotification} from '@client-shared-modules/models/notification.model';
import {AuthService} from '@core/services/auth.service';
import {environment} from '@env/environment';
import {select, Store} from '@ngrx/store';
import {IUserAuth} from '@regular-page-modules/auth/auth.model';
import {
  ReloginNotificationDialogComponent
} from '@shared/components/relogin-notification-dialog/relogin-notification-dialog.component';
import {ChangeApiTokenType} from '@shared/enums/change-api-token-type.enum';
import {UserRole} from '@shared/enums/user-role.enum';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {concatMap, distinctUntilChanged, filter, first, map, takeUntil, tap} from 'rxjs/operators';
import {NotificationHttpService} from '../https/notification-http.service';
import {IAppState} from '../store/app.reducers';
import {
  selectMyReviewCount,
  selectUnreadNotificationCount,
  selectUserRoleId
} from '../store/user-info/user-info.selectors';
import {BannerNotificationService} from './banner-notification.service';
import {TodoService} from './todo.service';

@Injectable()
export class NotificationService implements OnDestroy {

  notificationsRaw: Notification[];
  private _connection: signalR.HubConnection;
  private _unsubscribe$ = new Subject<void>();
  private _disconnect$ = new Subject<void>();
  private _userRoleId: UserRole;
  private _isInitialized = false;

  constructor(
    private store: Store<IAppState>,
    private authService: AuthService,
    private notificationHttpService: NotificationHttpService,
    private _bannerNotificationService: BannerNotificationService,
    private matDialog: MatDialog,
    private _todoService: TodoService,
  ) {
  }

  private _notifications$ = new BehaviorSubject<Notification[]>([]);

  get notifications$(): Observable<Notification[]> {
    return this._notifications$.asObservable();
  }

  private _signalRNotification$ = new BehaviorSubject<SignalRNotification>(null);

  get signalRNotification$(): Observable<SignalRNotification> {
    return this._signalRNotification$.asObservable();
  }

  private _unreadNotificationCount$ = new BehaviorSubject<number>(0);

  get unreadNotificationCount$(): Observable<number> {
    return this._unreadNotificationCount$.asObservable();
  }

  private _formUploadReviewCount$ = new BehaviorSubject<number>(0);

  get formUploadReviewCount$(): Observable<number> {
    return this._formUploadReviewCount$.asObservable();
  }

  private _notificationOwnerId$ = new BehaviorSubject<number>(0);

  get notificationOwnerId$(): Observable<number> {
    return this._notificationOwnerId$.asObservable();
  }

  set unreadNotificationCount(count: number) {
    this._unreadNotificationCount$.next(count);
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this._disconnect$.next();
    this._disconnect$.complete();
  }

  init() {
    if (this._isInitialized) {
      return;
    }

    this.store
      .pipe(
        select(selectUserRoleId),
        distinctUntilChanged(),
        takeUntil(this._disconnect$),
      )
      .subscribe(userRoleId => {
        this._userRoleId = userRoleId;
        if (userRoleId && userRoleId !== UserRole.Anonymous) {
          this.store
            .pipe(
              select(selectUnreadNotificationCount),
              filter(count => count !== undefined && count !== null),
              takeUntil(this._disconnect$),
            )
            .subscribe(count => {
              this._unreadNotificationCount$.next(count);
            });

          this.store
            .pipe(
              select(selectMyReviewCount),
              filter(count => count !== undefined && count !== null),
              takeUntil(this._disconnect$),
            )
            .subscribe(count => {
              this._formUploadReviewCount$.next(count);
            });

          this.notificationHttpService.getNotifications()
            .pipe(
              tap(notification => {
                this.notificationsRaw = notification;
              }),
              first(),
            )
            .subscribe(notification => {
              this._notifications$.next(notification);
            });

          // this.connect();
          // this.startListeners();
        }
      });
  }

  close(): Promise<any> {
    this._isInitialized = false;
    this._disconnect$.next();

    if (this._connection) {
      return this._connection.stop();
    } else {
      return Promise.resolve();
    }
  }

  startListeners() {
    if (this._userRoleId === UserRole.Anonymous) {
      return;
    }

    this._connection.on('Notification', (message: SignalRNotification) => {
      this.notificationsRaw.unshift(message.notification);
      this.notificationsRaw.pop();

      this._notifications$.next(this.notificationsRaw);
      this._unreadNotificationCount$.next(message.unreadCount);
      this._signalRNotification$.next(message);
    });

    this._connection.on('ChangeApiToken', (token: IUserAuth) => {
      if (token.changeApiTokenType === ChangeApiTokenType.ChangeRole) {
        this.matDialog.open(ReloginNotificationDialogComponent, {
          panelClass: 'base-dialog',
          disableClose: true,
          closeOnNavigation: false,
          data: {
            token,
            hideClose: true,
            title: 'role_changed_title',
            message: 'role_changed_by_head_teacher',
          },
        });
      }

      if (token.changeApiTokenType === ChangeApiTokenType.Cancellation) {
        this.matDialog.open(ReloginNotificationDialogComponent, {
          panelClass: 'base-dialog',
          disableClose: true,
          closeOnNavigation: false,
          data: {
            token,
            hideClose: true,
            title: 'membership_has_been_cancelled',
            message: 'membership_cancelled_by_ht',
          },
        });
      }
    });

    this._connection.on('FormUploadReviewCount', reviewCount => {
      this._formUploadReviewCount$.next(reviewCount);
    });

    this._connection.on('BannerMessage', message => {
      this._bannerNotificationService.signalRBannerNotification(message);
    });

    this._connection.on('AssessmentRequestCount', count => {
      this._todoService.setAssessmentRequestCount(count.count);
    });

    this._connection.on('EPARequestCount', count => {
      this._todoService.setEpaRequestCount(count.count);
    });

    this._connection.on('ReviewNewlyCreateEpaCount', count => {
      this._todoService.setReviewNewlyCreatedEpaCount(count.count);
    });

    this._connection.on('SystemActionCount', count => {
      this._todoService.setSystemActionCount(count.count);
    });

    this._connection.on('TodoTotalCount', count => {
      this._todoService.setTodoTotalCount(count.count);
    });
  }

  readNotificationBell(notification: Notification) {
    if (this._userRoleId === UserRole.Anonymous) {
      return;
    }

    return this.notificationHttpService.readNotification(notification.id)
      .pipe(
        concatMap(n => this.notificationHttpService.getNotifications()
          .pipe(map(notifications => ({notifications, unread: n.unreadCount}))),
        ),
        tap(n => {
          this.notificationsRaw = n.notifications;

          this._notifications$.next(n.notifications);
          this._unreadNotificationCount$.next(n.unread);
        }),
      );
  }

  setNotificationOwnerId(ownerId: number) {
    this._notificationOwnerId$.next(ownerId);
  }

  private connect() {
    if (!this._userRoleId || this._userRoleId === UserRole.Anonymous) {
      return;
    }

    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return this.authService.userTokenLS().token;
      },
    };

    this._connection = new HubConnectionBuilder()
      .withUrl(environment.signalRUrl, options)
      .configureLogging(environment.production ? LogLevel.Error : LogLevel.Information)
      .build();

    this._connection
      .start()
      .then(() => this._isInitialized = true)
      .catch(error => throwError('SignalR Connecting Error: ' + error));
  }

}
