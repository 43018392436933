import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '@core/services/localization.service';

@Pipe({
  name: 'localize',
})
export class LocalizationPipe implements PipeTransform {

  constructor(private localizationService: LocalizationService) {}

  transform(key: string, fallback?: string): string {
    if (!fallback) {
      fallback = key;
    }

    if (!key || key === '') {
      return '';
    }

    if (this.localizationService.isLocalizationByKeysEmpty()) {
      return fallback;
    }

    return this.localizationService.getTranslationByKey(key);
  }

}
