import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

class CacheEntry {
  data: any;
  expiry: number;
}

@Injectable()
export class CacheService {

  private _cacheData: Map<string, CacheEntry> = new Map();

  set(key: string, data: any, replace: boolean = true) {
    if (!environment.cache.enabled) {
      return;
    }
    if (!key) {
      throw new Error('Cache key cannot be null or empty');
    }
    if (!replace && this._cacheData.has(key)) {
      throw new Error('Cache already exists and replace set to false');
    }

    if (data === null || data === undefined) {
      this.remove(key);
    } else {
      this._cacheData.set(key, {
        data,
        expiry: Date.now() + environment.cache.expiry,
      });
    }
  }

  get(key: string): CacheEntry {
    if (!environment.cache.enabled) {
      return null;
    }
    if (!key) {
      throw new Error('Cache key cannot be null or empty');
    }
    if (!this.hasKey(key)) {
      return undefined;
    }

    return this._cacheData.get(key);
  }

  getAll(keyPrefix: string = null): CacheEntry[] {
    if (!environment.cache.enabled) {
      return [];
    }
    const result: any[] = [];
    for (const key of this._cacheData.keys()) {
      if (!!keyPrefix && !key.toLocaleLowerCase().startsWith(keyPrefix.toLocaleLowerCase())) {
        continue;
      }

      result.push(this._cacheData.get(key));
    }
    return result;
  }

  hasKey(key: string): boolean {
    if (!key) {
      throw new Error('Cache key cannot be null or empty');
    }

    return this._cacheData.has(key);
  }

  remove(key: string): boolean {
    if (!environment.cache.enabled) {
      return;
    }
    if (!key) {
      throw new Error('Cache key cannot be null or empty');
    }
    return this._cacheData.delete(key);
  }

  removeByPrefix(keyPrefix: string) {
    if (!environment.cache.enabled) {
      return;
    }
    if (!keyPrefix) {
      throw new Error('Cache keyPrefix cannot be null or empty');
    }
    for (const key of this._cacheData.keys()) {
      if (key.toLocaleLowerCase().startsWith(keyPrefix.toLocaleLowerCase())) {
        this.remove(key);
      }
    }
  }

  getKeys() {
    return this._cacheData.keys();
  }

  purge() {
    this._cacheData = new Map();
  }

}
