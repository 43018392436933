import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, SafeUrl, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService, USER_TOKEN_LS_KEY} from '@core/services/auth.service';
import {CacheMonitorService} from '@core/services/cache-monitor.service';
import {FormService} from '@core/services/form.service';
import {IdleService} from '@core/services/idle.service';
import {IndexedDbService} from '@core/services/indexed-db.service';
import {LocalizationService} from '@core/services/localization.service';
import {PaginatorTranslationService} from '@core/services/paginator-translation.service';
import {RouterService} from '@core/services/router.service';
import {TokenExpiryService} from '@core/services/token-expiry.service';
import {IAppState} from '@core/store/app.reducers';
import {getLanguages} from '@core/store/langauge/language.actions';
import {environment} from '@env/environment';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AppBlockerService} from '@shared-modules/app-blocker/services/app-blocker.service';
import {DownloadAppDialogComponent} from '@shared/components/download-app-dialog/download-app-dialog.component';
import {qHasUnsavedChanges} from '@shared/query-param-ids';
import {fromEvent, Observable, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Translation} from '../assets/i18n/language';

@Component({
  selector: 'xf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  baseUrl: SafeUrl;
  showAppBlocker$: Observable<boolean>;

  private _mainInterval: any;
  private _unsubscribe$ = new Subject<void>();
  private _currentUsername: string;

  constructor(
    private _store: Store<IAppState>,
    private _translate: TranslateService,
    private _router: Router,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _browserTitleService: Title,
    private _localizationService: LocalizationService,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _matDialog: MatDialog,
    private _idleService: IdleService,
    private _tokenExpiryService: TokenExpiryService,
    private _routerHistoryService: RouterService,
    private _cacheMonitorService: CacheMonitorService,
    private _paginatorTranslationService: PaginatorTranslationService,
    private _appBlockerService: AppBlockerService,
    private _formService: FormService,
    private _indexedDbService: IndexedDbService,
  ) {
    this._authService.sessionInspector();

    this.setBrowserTitle();
    this._translate.setDefaultLang(Translation.English);
    this._store.dispatch(getLanguages());
    this._idleService.init(
      ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'],
      environment.idle.maxInSeconds,
    );
    this._localizationService.setLocalization();
    this._localizationService.fetchAll();
    this._routerHistoryService.start();
    this._indexedDbService.init();
    this.registerSvgIcons();

    this.baseUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(
      environment.baseUrl,
    );

    this._mainInterval = setInterval(() => {
      this._cacheMonitorService.tick();
      this._idleService.tick();
    }, 1000);
  }

  ngOnInit() {
    this.showAppBlocker$ = this._appBlockerService.isShown$;
    this._currentUsername = this._authService.usernameLS();
  }

  ngAfterViewInit() {
    this._paginatorTranslationService.init();

    // If token is refreshed from another tab, we need to update our token copy and token expiry service, too.
    fromEvent<StorageEvent>(window, 'storage')
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((e) => {
        switch (e.key) {
          case USER_TOKEN_LS_KEY:
            const userAuth = this._authService.userTokenLS();
            if (userAuth) {
              const {expiration} = userAuth;
              if (expiration) {
                this._matDialog.closeAll();
                this._appBlockerService.show(false);
                this._tokenExpiryService.setExpiration(
                  expiration,
                  environment.idle.forcedReloginAfterNoResponseInSeconds * 1000,
                );

                if (
                  this._currentUsername &&
                  this._authService.usernameLS() !== this._currentUsername
                ) {
                  if (this._formService.currentFormHasChanges()) {
                    window.location.href = `${environment.baseUrl}?${qHasUnsavedChanges}=true`;
                  } else {
                    window.location.href = environment.baseUrl;
                  }
                }
              }
            }
            this._authService.evaluateSession();
            break;
        }
      });
  }

  ngOnDestroy() {
    clearInterval(this._mainInterval);
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private setBrowserTitle() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this._activatedRoute.firstChild;
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return '';
        }),
      )
      .subscribe((routeTitle) => {
        this._browserTitleService.setTitle(
          this._localizationService.getTranslationByKey(routeTitle),
        );
      });
  }

  private registerSvgIcons() {
    this._matIconRegistry.addSvgIcon(
      'student-overview',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/svg/student-overview-icon.svg',
      ),
    );
    this._matIconRegistry.addSvgIcon(
      'internship-planner',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/svg/internship-planner-icon.svg',
      ),
    );
    this._matIconRegistry.addSvgIcon(
      'group-settings',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/svg/group-settings-icon.svg',
      ),
    );
  }

  private _openDownloadAppDialog(link: string) {
    this._matDialog.open(DownloadAppDialogComponent, {
      disableClose: true,
      panelClass: 'base-dialog',
      data: link,
    });
  }
}
