import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GroupActivityService} from '@shared-modules/group-activity/services/group-activity.service';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {Subject} from 'rxjs/internal/Subject';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'xf-search-input-for-filter',
  templateUrl: './epa-search-input-for-filter.component.html',
  styleUrls: ['./epa-search-input-for-filter.component.scss'],
})
export class EpaSearchInputForFilterComponent implements OnInit, OnDestroy {
  @Input()
  placeholder: string;

  @Input()
  formControl: FormControl;

  @Output()
  searchChanged = new EventEmitter<boolean>();

  @ViewChild('searchInput')
  searchInput: ElementRef;

  isExpanded: boolean;
  isFocused: boolean;

  private _unsubscribe$ = new Subject();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _groupActivityService: GroupActivityService
  ) {
  }

  onChange: any = () => {
  }

  onTouched: any = () => {
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((query) => {
        this.onChange(query);
        this.onTouched(query);
      });

    this._groupActivityService.getQueryString$
      .pipe(
        filter((query) => !!query),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((query) => {
        if (query) {
          this.formControl.patchValue(query);
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onClickSearchButton() {
    this._cdr.detectChanges();
    this.searchInput.nativeElement.focus();

    this.searchChanged.emit(true);
  }
}
