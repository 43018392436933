export const longMonthsName = {
  0: {
    en: 'January',
    nl: 'januari',
  },
  1: {
    en: 'February',
    nl: 'februari',
  },
  2: {
    en: 'March',
    nl: 'maart',
  },
  3: {
    en: 'April',
    nl: 'april',
  },
  4: {
    en: 'May',
    nl: 'mei',
  },
  5: {
    en: 'June',
    nl: 'juni',
  },
  6: {
    en: 'July',
    nl: 'juli',
  },
  7: {
    en: 'August',
    nl: 'augustus',
  },
  8: {
    en: 'September',
    nl: 'september',
  },
  9: {
    en: 'October',
    nl: 'oktober',
  },
  10: {
    en: 'November',
    nl: 'november',
  },
  11: {
    en: 'December',
    nl: 'december',
  },
};


export const shortMonthsName = {
  0: {
    en: 'Jan',
    nl: 'jan',
  },
  1: {
    en: 'Feb',
    nl: 'febr',
  },
  2: {
    en: 'Mar',
    nl: 'mrt',
  },
  3: {
    en: 'Apr',
    nl: 'apr',
  },
  4: {
    en: 'May',
    nl: 'mei',
  },
  5: {
    en: 'Jun',
    nl: 'juni',
  },
  6: {
    en: 'Jul',
    nl: 'juli',
  },
  7: {
    en: 'Aug',
    nl: 'aug',
  },
  8: {
    en: 'Sep',
    nl: 'sep',
  },
  9: {
    en: 'Oct',
    nl: 'okt',
  },
  10: {
    en: 'Nov',
    nl: 'nov',
  },
  11: {
    en: 'Dec',
    nl: 'dec',
  },
};

