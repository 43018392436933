import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { TokenExpiryService } from '@core/services/token-expiry.service';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  static readonly blackList: string[] = [
    'GenerateToken',
    'RefreshToken',
  ];

  constructor(
    private tokenExpiryService: TokenExpiryService,
    private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('mock.json') >= 0) {
      return next.handle(request);
    }

    if (this.isRequestUrlBlacklisted(request.url)) {
      return next.handle(request);
    }

    if (this.authService.refreshTokenInProgress) {
      return this.authService.refreshTokenSubject$.pipe(
        filter(token => !!token),
        first(),
        switchMap(() => next.handle(request)),
      );
    } else {
      if (this.authService.userTokenLS() && !this.authService.isUserTokenLSValid()) {

        // When token is expired, we need to check if we are allowed to refresh token base on idleness
        if (this.tokenExpiryService.canRefreshToken) {
          const { refreshToken } = this.authService.userTokenLS();
          if (refreshToken) {
            return this.authService.refreshToken()
              .pipe(
                switchMap(() => {
                  return next.handle(request);
                }),
              );
          }
        }

        // Token expired so we delete tokens to force re-login
        this.authService.clearEPALocalStorageKeys();
      }
    }

    return next.handle(request);
  }

  private isRequestUrlBlacklisted(url: string) {
    return RefreshTokenInterceptor.blackList.some(endpoint => url.toLocaleLowerCase().endsWith(endpoint.toLocaleLowerCase()));
  }

}
