import { Location, PopStateEvent } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, SubscriptionLike } from 'rxjs';

export class LocationHistory {
  url: string;
  state: any;
  isPop: boolean;
}

@Injectable()
export class LocationService implements OnDestroy {

  private _locationSubscriptions: SubscriptionLike;
  private _historyChange$ = new BehaviorSubject<LocationHistory>(null);

  get historyChange$(): Observable<LocationHistory> {
    return this._historyChange$.asObservable();
  }

  get currentHistory(): LocationHistory {
    return this._historyChange$.value;
  }

  constructor(private location: Location) {
    this._locationSubscriptions = this.location
      .subscribe(
        (value: PopStateEvent) => {
          const { url, state, pop: isPop } = value;
          this._historyChange$.next({ url, state, isPop });
        },
      );
  }

  ngOnDestroy() {
    if (!this._locationSubscriptions.closed) {
      this._locationSubscriptions.unsubscribe();
    }
  }

}
