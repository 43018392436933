import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopDepartmentsService {
  private _unsubscribe$ = new Subject<void>();
  isDashboard$: Observable<boolean>;
  isTopPerformers: Observable<boolean>;

  private isTopDepartmentsSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isBottomDepartmentsSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isTopPerformersSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isBottomPerformersSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isTopDepartments$: Observable<boolean> =
    this.isTopDepartmentsSubject.asObservable();
  isBottomDepartments$: Observable<boolean> =
    this.isBottomDepartmentsSubject.asObservable();
  isTopPerformers$: Observable<boolean> =
    this.isTopPerformersSubject.asObservable();
  isBottomPerformers$: Observable<boolean> =
    this.isBottomPerformersSubject.asObservable();

  updateIsDashboard(isTopDepartments: boolean, isTopPerformers: boolean): void {
    this.isTopDepartmentsSubject.next(isTopDepartments);
    this.isTopPerformersSubject.next(isTopPerformers);

    this.isBottomDepartmentsSubject.next(false);
    this.isBottomPerformersSubject.next(false);
  }

  updateIsEmployeeView(
    isTopDepartments: boolean,
    isBottomDepartments: boolean,
    isTopPerformers: boolean,
    isBottomPerformers: boolean
  ): void {
    this.isTopDepartmentsSubject.next(isTopDepartments);
    this.isBottomDepartmentsSubject.next(isBottomDepartments);
    this.isTopPerformersSubject.next(isTopPerformers);
    this.isBottomPerformersSubject.next(isBottomPerformers);
  }
}
