<mat-form-field fxFlex="100" [appearance]="appearance">
  <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
  <mat-select
    panelClass="dropdown-multi-select-panel"
    [formControl]="dropdownSelectForm"
    [multiple]="multiple"
    [required]="required"
    [matTooltip]="placeholder?.length >= 15 ? placeholder : ''"
    (openedChange)="onSelectToggle($event)"
    (selectionChange)="onSelectionChange($event)"
    #multiSelect>
    <mat-select-trigger
    *ngIf="(selectedItemsLength && multiple && !hasChildOptions && !isEpaOnly); else singleSelection"
      fxLayoutAlign="start center">
      <span>{{ firstSelectedItem }}</span>
      <span *ngIf="selectedItemsLength > 1">
        &nbsp;(+{{ selectedItemsLength - 1 }}&nbsp;{{ selectedItemsLength === 2 ? ('other' | localize) : ('others' | localize) }})
      </span>
    </mat-select-trigger>
    <ng-template #singleSelection>
      <mat-select-trigger>
        <span>{{ selectedItemLabel }}</span>
        <!--        <ng-container *ngIf="hasResetOption">-->
        <!--          <button mat-icon-button matSuffix (click)="$event.stopPropagation(); onResetSelection()">-->
        <!--            <mat-icon>close</mat-icon>-->
        <!--          </button>-->
        <!--        </ng-container>-->
      </mat-select-trigger>
    </ng-template>
    <mat-option *ngIf="!hideFilterInternal" disabled class="dropdown-input-filter">
      <xf-input-filter
        [inputFilterPlaceholder]="inputFilterPlaceholder | localize"
        [formControl]="inputFilterForm">
      </xf-input-filter>
    </mat-option>
    <mat-option *ngIf="loading$ | async" disabled class="dropdown-input-filter-loader">
      <div fxLayoutAlign="center center" fxLayoutGap="1em">
        <mat-spinner diameter="20"></mat-spinner>
        <span>{{ 'loading_data' | localize }}</span>
      </div>
    </mat-option>
    <ng-container *ngIf="hasChildOptions; else regularOptions">
      <mat-option
        *ngFor="let item of filteredItems$ | async"
        [ngClass]="item.className"
        [value]="item.value"
        [disabled]="(loading$ | async) || (item.isParent && !groupSelection)">
        <div fxLayoutAlign="space-between" [matTooltip]="item.required ? item.label + ' - ' + item.completed + '/' + item.required : item.label">
          <div class="mat-option-text-inner">{{ item.label }}</div>
          <div>
            <ng-container *ngIf="item.required !== undefined && item.completed !== undefined">
              <ng-container *ngIf="item.isParent else countWithStyle">
              <span>
                {{ item.completed }}/{{ item.required }}
              </span>
              </ng-container>
              <ng-template #countWithStyle>
              <span [ngClass]="item.completed >= item.required ? 'complete' : 'incomplete'">
                {{ item.completed }}/{{ item.required }}
              </span>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </mat-option>
    </ng-container>
    <ng-template #regularOptions>
      <mat-option *ngFor="let item of filteredItems$ | async" [value]="item.value" [disabled]="loading$ | async">
        <div fxLayoutAlign="start center" fxLayoutGap="0.5em">
          <ng-container *ngIf="hasProfilePhoto">
            <xf-profile-photo
              size="25px"
              [fileUrl]="item.profilePhotoUrl"
              [userName]="item.label">
            </xf-profile-photo>
          </ng-container>
          <span>{{ item.label }}</span>
          <ng-container *ngIf="item.required !== undefined && item.completed !== undefined">
            <span [ngClass]="item.completed >= item.required ? 'complete' : 'incomplete'">
              {{ item.completed }}/{{ item.required }}
            </span>
          </ng-container>
        </div>
      </mat-option>
    </ng-template>
    <mat-option
      *ngIf="((loading$ | async) === false || (loading$ | async) === null) && (filteredItems$ | async).length === 0"
      disabled
      class="dropdown-input-filter-no-results">
      {{ 'no_data' | localize }}
    </mat-option>
  </mat-select>
  <ng-container *ngIf="hasResetOption && !!dropdownSelectForm.value">
    <button
      mat-icon-button
      matSuffix
      [matTooltip]="'reset' | localize"
      (click)="$event.stopPropagation(); onResetSelection()">
      <mat-icon>close</mat-icon>
    </button>
  </ng-container>
  <mat-error *ngIf="dropdownSelectForm.hasError('customError')">
    {{ dropdownSelectForm.errors.customError }}
  </mat-error>
  <mat-error *ngIf="dropdownSelectForm.hasError('required')">{{ 'the_field_is_required' | localize }}</mat-error>
</mat-form-field>
