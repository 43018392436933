import { Injectable, OnDestroy } from '@angular/core';
import { IAppState } from '@core/store/app.reducers';
import { selectTodoCounts } from '@core/store/user-info/user-info.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class TodoService implements OnDestroy {

  get todoTotalCount$(): Observable<number> {
    return this._todoTotalCount$.asObservable();
  }

  get assessmentRequestCount$(): Observable<number> {
    return this._assessmentRequestCount$.asObservable();
  }

  get epaRequestCount$(): Observable<number> {
    return this._epaRequestCount$.asObservable();
  }

  get reviewNewlyCreatedCount$(): Observable<number> {
    return this._reviewNewlyCreatedCount$.asObservable();
  }

  get systemActionCount$(): Observable<number> {
    return this._systemActionCount$.asObservable();
  }

  private _todoTotalCount$ = new BehaviorSubject<number>(0);
  private _epaRequestCount$ = new BehaviorSubject<number>(0);
  private _assessmentRequestCount$ = new BehaviorSubject<number>(0);
  private _reviewNewlyCreatedCount$ = new BehaviorSubject<number>(0);
  private _systemActionCount$ = new BehaviorSubject<number>(0);

  private _unsubscribe$ = new Subject<void>();

  constructor(private _store: Store<IAppState>) { }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  init() {
    this._store.select(selectTodoCounts)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(data => {
        this._todoTotalCount$.next(data.todoTotalCount);
        this._assessmentRequestCount$.next(data.assessmentRequestCount);
        this._epaRequestCount$.next(data.epaRequestCount);
        this._reviewNewlyCreatedCount$.next(data.reviewNewlyCreateEpaCount);
        this._systemActionCount$.next(data.systemActionCount);
      });
  }

  setTodoTotalCount(count: number) {
    this._todoTotalCount$.next(count);
  }

  setAssessmentRequestCount(count: number) {
    this._assessmentRequestCount$.next(count);
  }

  setEpaRequestCount(count: number) {
    this._epaRequestCount$.next(count);
  }

  setReviewNewlyCreatedEpaCount(count: number) {
    this._reviewNewlyCreatedCount$.next(count);
  }

  setSystemActionCount(count: number) {
    this._systemActionCount$.next(count);
  }
}
