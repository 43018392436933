<div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="space-between stretch">
  <mat-card fxLayout="column" class="time-card total">
    <ng-container *ngIf="data$ | async as data; else loading">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span class="title">
          {{ "since_implementation_total" | localize }}
        </span>
        <div class="icon-round">
          <mat-icon class="icon">query_builder</mat-icon>
        </div>
      </div>
      <p>{{ valueParser(data.totalHoursSaved) }}</p>
      <span class="date">
        {{ "start" | localize }} {{ data.companyPortfolioTypeCreatedDate }}
      </span>
    </ng-container>
  </mat-card>
  <mat-card fxLayout="column" class="time-card average">
    <ng-container *ngIf="data$ | async as data; else loading">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span class="title">
          {{ textFilterPeriodView | uppercase }} ({{ "average" | localize }})
        </span>
        <div class="icon-round icon-average">
          <mat-icon class="icon">show_chart</mat-icon>
        </div>
      </div>
      <p>{{ valueParser(data.quarterHours) }}</p>
      <div fxLayout="row">
        <mat-icon
          class="percentage icon-percentage"
          [class]="classCheck(data.quarterPercentage)"
        >
          keyboard_backspace
        </mat-icon>
        <span class="percentage" [class]="classCheck(data.quarterPercentage)">
          ({{ data.quarterPercentage }})
        </span>
      </div>
    </ng-container>
  </mat-card>
  <mat-card fxLayout="column" class="time-card prognosis">
    <ng-container *ngIf="data$ | async as data; else loading">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span class="title">{{ "expectation_this_year" | localize }}</span>
        <div class="icon-round icon-prognosis">
          <mat-icon class="icon">signal_cellular_alt</mat-icon>
        </div>
      </div>
      <p>{{ data.prognosis }} {{ "hours" | localize }}</p>
    </ng-container>
  </mat-card>
</div>

<ng-template #loading>
  <div class="loader">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
</ng-template>
