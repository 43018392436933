import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from '@core/services/localization.service';
import { appRoutes } from '@shared/enums/app-routes.enum';
import { Translation } from '../../enums/language.enum';
import { Language } from '../../models/language.model';

@Component({
  selector: 'xf-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss'],
})
export class FooterMenuComponent implements OnInit {
  @Input()
  languages: Language[];

  @Input()
  setLanguage: string;

  appRoutes = appRoutes;

  translations = Translation;
  selectedTranslationImgPath = 'assets/images/flags/gb.png';

  constructor(
    private router: Router,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.setTranslationPath(this.localizationService.getLanguageCodeLS());
  }

  onSelectLanguage(languageCode: string) {
    this.setTranslationPath(languageCode);
    this.router.navigate([appRoutes.changeLanguage.fullPath], {
      queryParams: { lang: languageCode, redirectUrl: window.location.href },
    });
  }

  private setTranslationPath(languageCode: string) {
    switch (languageCode) {
      case Translation.English:
        this.selectedTranslationImgPath = 'assets/images/flags/gb.png';
        break;
      case Translation.Dutch:
        this.selectedTranslationImgPath = 'assets/images/flags/nl.png';
        break;
    }
  }
}
