import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
// Material Data Table Dependencies
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AdvanceDialogModule } from '@shared-modules/advance-dialog/advance-dialog.module';
import { CustomTooltipModule } from '@shared-modules/custom-tooltip/custom-tooltip.module';
import { ProfilePhotoModule } from '@shared-modules/profile-photo/profile-photo.module';
import { ArrayJoinPipe } from '@shared/pipes/array-join.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AiImplementationCardsComponent } from './components/ai-implementation-cards/ai-implementation-cards.component';
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component';
import { CheckBoxFilterComponent } from './components/check-box-filter/check-box-filter.component';
import { DashboardFiltersComponent } from './components/dashboard-filters/dashboard-filters.component';
import { DatepickerFilterTableComponent } from './components/datepicker-filter/datepicker-filter.component';
import { DifferentVersionDialogComponent } from './components/different-version-dialog/different-version-dialog.component';
import { DownloadAppDialogComponent } from './components/download-app-dialog/download-app-dialog.component';
import { DropdownInputFilterComponent } from './components/dropdown-input-filter/dropdown-input-filter.component';
import { InputFilterComponent } from './components/dropdown-input-filter/input-filter/input-filter.component';
import {
  AdvanceDialogActionsDirective,
  AdvanceDialogContentDirective,
  AdvanceDialogHeaderDirective,
  EpaDialogContainerComponent,
} from './components/epa-dialog-container/epa-dialog-container.component';
import { EpaSearchInputForFilterComponent } from './components/epa-search-input-for-filter/epa-search-input-for-filter.component';
import { EpaSubepaDropdownComponent } from './components/epa-subepa-dropdown/epa-subepa-dropdown.component';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { FormConfirmationDialogComponent } from './components/form-confirmation-dialog/form-confirmation-dialog.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LostDataDialogComponent } from './components/lost-data-dialog/lost-data-dialog.component';
import { MatTableTemplateComponent } from './components/mat-table-template/mat-table-template.component';
import { MyReviewsTableTemplateComponent } from './components/my-reviews-table-template/my-reviews-table-template.component';
import { ReloginNotificationDialogComponent } from './components/relogin-notification-dialog/relogin-notification-dialog.component';
import { SortFilterComponent } from './components/sort-filter/sort-filter.component';
import { SubformDialogComponent } from './components/subform-dialog/subform-dialog.component';
import { TopDepartmentsComponent } from './components/top-departments/top-departments.component';
import { UserActivityCardComponent } from './components/user-activity-card/user-activity-card.component';
import { FormatGroupMembership } from './pipes/format-group-membership.pipe';
import { FormatPortfolioType } from './pipes/format-portfolio-type.pipe';
import { FormatStringPipe } from './pipes/format-string.pipe';
import { LocalizationReplacePipe } from './pipes/localization-replace.pipe';
import { LocalizationPipe } from './pipes/localization.pipe';
import { PhraseTitleCase } from './pipes/phrase-title-case.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { TextTruncatePipe } from './pipes/text-truncate.pipe';

const materials = [
  MatTableModule,
  MatFormFieldModule,
  MatIconModule,
  MatSortModule,
  MatPaginatorModule,
  MatToolbarModule,
  MatListModule,
  MatOptionModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatButtonModule,
  MatDialogModule,
  MatProgressBarModule,
  MatMenuModule,
  MatTabsModule,
  MatCardModule,
  MatCheckboxModule,
  MatRadioModule,
  MatButtonToggleModule,

  MatInputModule,
];

const components = [
  BaseDialogComponent,
  FormConfirmationDialogComponent,
  MatTableTemplateComponent,
  InputFilterComponent,
  DropdownInputFilterComponent,
  MyReviewsTableTemplateComponent,
  SubformDialogComponent,
  LoadingSpinnerComponent,
  FooterMenuComponent,
  EpaSubepaDropdownComponent,
  DownloadAppDialogComponent,

  DashboardFiltersComponent,
  UserActivityCardComponent,
  AiImplementationCardsComponent,
  TopDepartmentsComponent,
  EpaDialogContainerComponent,

  EpaSearchInputForFilterComponent,
  SortFilterComponent,
  CheckBoxFilterComponent,
  DatepickerFilterTableComponent,
];

const directives = [
  AdvanceDialogHeaderDirective,
  AdvanceDialogContentDirective,
  AdvanceDialogActionsDirective,
];

const dialogs = [
  ReloginNotificationDialogComponent,
  LostDataDialogComponent,
  DifferentVersionDialogComponent,
];

const pipes = [
  TextTruncatePipe,
  SafeHtmlPipe,
  SplitStringPipe,
  LocalizationPipe,
  LocalizationReplacePipe,
  FormatGroupMembership,
  FormatPortfolioType,
  ArrayJoinPipe,
  FormatStringPipe,
  PhraseTitleCase,
];


@NgModule({
  declarations: [...components, ...pipes, ...dialogs, ...directives],
  imports: [
    CommonModule,
    CustomTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    ProfilePhotoModule,
    ...materials,
    RouterModule,
    AdvanceDialogModule,
  ],
  entryComponents: [...dialogs],
  exports: [...components, ...pipes, ...dialogs, ...directives],
})
export class SharedModule {}
