export const appRoutes = {
  auth: {
    pathName: 'auth',
    fullPath: '/auth',
    title: 'Auth',
    key: 'auth',
  },
  login: {
    pathName: 'login',
    fullPath: '/auth/login',
    title: 'Login',
    key: 'login',
  },
  logout: {
    pathName: 'logout',
    fullPath: '/logout',
    title: 'Logout',
    key: 'logout',
  },
  changeLanguage: {
    pathName: 'change-language',
    fullPath: '/change-language',
    title: 'Change Language',
    key: 'change-language',
  },
  switchGroup: {
    pathName: 'switch-group',
    fullPath: '/switch-group',
    title: 'Switch Group',
    key: 'switch-group',
  },
  forgotPassword: {
    pathName: 'forgot-password',
    fullPath: '/auth/forgot-password',
    title: 'Forgot Password',
    key: 'forgot_password',
  },
  profile: {
    pathName: 'profile',
    fullPath: '/profile',
    title: 'Profile',
    key: 'profile',
  },
  formUpload: {
    pathName: 'form-upload',
    fullPath: '/form-upload',
    title: 'Form Upload',
    key: 'form_upload',
  },
  anonymous: {
    pathName: 'anonymous',
    fullPath: '/anonymous',
    title: 'Anonymous',
    key: 'anonymous',
  },
  token: {
    pathName: 'token',
    fullPath: '/token',
    title: 'Token',
    key: 'token',
  },
  thankYou: {
    pathName: 'thank-you',
    fullPath: '/thank-you',
    title: 'Thank you',
    key: 'thank_you',
  },
  invitation: {
    pathName: 'invitation',
    fullPath: '/invitation',
    title: 'Invitation',
    key: 'invitation',
  },
  confirmation: {
    pathName: 'confirmation',
    fullPath: '/invitation/confirmation',
    title: 'Confirmation',
    key: 'confirmation',
  },
  declined: {
    pathName: 'declined',
    fullPath: '/invitation/declined',
    title: 'Declined',
    key: 'declined',
  },
  emailConfirmed: {
    pathName: 'email-confirmed',
    fullPath: '/invitation/email-confirmed',
    title: 'Confirmation',
    key: 'confirmation',
  },
  formCompleted: {
    pathName: 'form-completed',
    fullPath: '/form-completed',
    title: 'Form completed',
    key: 'form_completed',
  },
  formDeleted: {
    pathName: 'form-deleted',
    fullPath: '/form-deleted',
    title: 'Form deleted',
    key: 'form_deleted',
  },
  formVoid: {
    pathName: 'form-void',
    fullPath: '/form-void',
    title: 'Form void',
    key: 'form_void',
  },
  backToOwnPortfolio: {
    pathName: 'back-to-own-portfolio',
    fullPath: '/back-to-own-portfolio',
    title: 'Back to own portfolio',
    key: 'back-to-own-portfolio',
  },
  userAlreadyBeenLinked: {
    pathName: 'user-already-been-linked',
    fullPath: '/user-already-been-linked',
    title: 'User has already been successfully linked',
    key: 'user_has_already_been_linked',
  },
  assessorRemoved: {
    pathName: 'assessor-removed',
    fullPath: '/assessor-removed',
    title: 'Assessor removed',
    key: 'assessor_removed',
  },

  // ALL CLIENT ROUTES
  dashboard: {
    pathName: 'dashboard',
    fullPath: '/dashboard',
    title: 'Dashboard',
    key: 'dashboard',
  },
  userActivity: {
    pathName: 'user-activity',
    fullPath: '/user-activity',
    title: 'User Activity',
    key: 'user-activity',
  },

  aiImplementationEmployees: {
    pathName: 'ai-implementation-employees',
    fullPath: '/ai-implementation-employees',
    title: 'Ai-implementation-employees',
    key: 'ai-implementation-employees',
  },
  employeeCombinations: {
    pathName: 'employee-combinations',
    fullPath: '/employee-combinations',
    title: 'Employee combinations',
    key: 'employee-combinations',
  },
  epaLibrary: {
    pathName: 'epa-library',
    fullPath: '/epa-library',
    title: 'Epa library',
    key: 'epa_library',
  },
  notification: {
    pathName: 'notification',
    fullPath: '/notification',
    title: 'Notification',
    key: 'notifications',
  },
  contact: {
    pathName: 'contact',
    publicPathName: 'public/contact',
    fullPath: '/contact',
    publicFullPath: '/public/contact',
    title: 'Contact',
    key: 'contact',
  },
  about: {
    pathName: 'about',
    publicPathName: 'public/about',
    fullPath: '/about',
    publicFullPath: '/public/about',
    title: 'About',
    key: 'about',
  },
  privacy: {
    pathName: 'privacy',
    publicPathName: 'public/privacy',
    fullPath: '/privacy',
    publicFullPath: '/public/privacy',
    title: 'Privacy',
    key: 'privacy',
  },
  conditionTerms: {
    pathName: 'terms-conditions',
    publicPathName: 'public/terms-conditions',
    fullPath: '/terms-conditions',
    publicFullPath: '/public/terms-conditions',
    title: 'Conditions & Terms',
    key: 'conditions_terms',
  },
  feedback: {
    pathName: 'feedback',
    publicPathName: 'public/feedback',
    fullPath: '/feedback',
    publicFullPath: '/public/feedback',
    title: 'Feedback',
    key: 'feedback',
  },
  releaseNotes: {
    pathName: 'release-notes',
    publicPathName: 'public/release-notes',
    fullPath: '/release-notes',
    publicFullPath: '/public/release-notes',
    title: 'Release Notes',
    key: 'release-notes',
  },
  analytics: {
    pathName: 'analytics',
    fullPath: '/analytics',
    title: 'Analytics',
    key: 'analytics',
  },
  internshipIntroductoryMeeting: {
    pathName: 'internship-introductory-meeting',
    fullPath: '/internship-introductory-meeting',
    title: 'Internship Introductory Meeting',
    key: 'internship_introductory',
  },
  internshipEvaluation: {
    pathName: 'internship-evaluation',
    fullPath: '/internship-evaluation',
    title: 'Internship Evaluation',
    key: 'internship_evaluation',
  },
  periodicEvaluation: {
    pathName: 'periodic-evaluation',
    fullPath: '/periodic-evaluation',
    title: 'Periodic Evaluation',
    key: 'periodic_evaluation',
  },
  caseBasedDiscussion: {
    pathName: 'case-based-discussion',
    fullPath: '/case-based-discussion',
    title: 'Case Based Discussion',
    key: 'cbd_form',
  },
  theoreticalExam: {
    pathName: 'theoretical-exam',
    fullPath: '/theoretical-exam',
    title: 'Theoretical Exam',
    key: 'theoretical_exam',
  },
  kpb: {
    pathName: 'kpb',
    fullPath: '/kpb',
    title: 'KPB',
    key: 'kbp_form',
  },
  kpe: {
    pathName: 'kpe',
    fullPath: '/kpe',
    title: 'KPE',
    key: 'moo_kpe',
  },
  cat: {
    pathName: 'cat',
    fullPath: '/cat',
    title: 'CAT',
    key: 'cat_form',
  },
  osats: {
    pathName: 'osats',
    fullPath: '/osats',
    title: 'OSATS',
    key: 'osat_form',
  },
  viva: {
    pathName: 'viva',
    fullPath: '/viva',
    title: 'VIVA',
    key: 'viva_form',
  },
  scientificActivity: {
    pathName: 'scientific-activity',
    fullPath: '/scientific-activity',
    title: 'Scientific Activity',
    key: 'scientific_activity',
  },
  otherAssessment: {
    pathName: 'other-assessment',
    fullPath: '/other-assessment',
    fullPathBmh: '/other-assessment',
    title: 'Other assessment',
    key: 'other_assessment_form',
    keyBmh: 'other_assessment_form',
  },
  certificate: {
    pathName: 'certificate',
    fullPath: '/certificate',
    fullPathBmh: '/other-assessment',
    title: 'Certificate',
    key: 'sertificate',
  },
  threeSixtyFeedback: {
    pathName: '360-feedback',
    fullPath: '/360-feedback',
    title: '360 Feedback',
    key: 'three_sixty_feedback',
  },
  oer: {
    pathName: 'oer',
    fullPath: '/oer',
    title: 'OER',
    key: 'oer',
  },
  viewPdf: {
    pathName: 'view-pdf',
    fullPath: '/view-pdf',
    title: 'View Pdf',
    key: 'view_pdf',
  },
  starrReflection: {
    pathName: 'starr-reflection',
    fullPath: '/starr-reflection',
    title: 'STARR reflection',
    key: 'starr_reflection',
  },
  shiftEvaluation: {
    pathName: 'shift-evaluation',
    fullPath: '/shift-evaluation',
    title: 'Shift Evaluation',
    key: 'shift_evaluation',
  },
  portfolioSettings: {
    pathName: 'portfolio-settings',
    fullPath: '/portfolio-settings',
    title: 'Portfolio Settings',
    key: 'portfolio_settings',
  },
  todo: {
    pathName: 'todo',
    fullPath: '/todo',
    title: 'To do',
    key: 'todo',
  },
  assessments: {
    pathName: 'assessments',
    fullPath: '/assessments',
    title: 'Assessments',
    key: 'assessments',
  },
  generalAssessments: {
    pathName: 'general-assessment',
    fullPath: '/general-assessment',
    title: 'General Assessments',
    key: 'general_assessments',
  },

  // HT/T ROUTES
  myReviews: {
    pathName: 'my-reviews',
    fullPath: '/my-reviews',
    title: 'My Reviews',
    key: 'my_reviews',
  },
  viewStudentPortfolio: {
    pathName: 'view-student-portfolio',
    fullPath: '/view-student-portfolio',
    title: 'View Student Portfolio',
    key: 'view_other_user',
    keyEni: 'eni_view_students_portfolio',
    keyMoo: 'eni_view_students_portfolio',
    keyBmh: 'eni_view_students_portfolio',
  },
  courseGroup: {
    pathName: 'course-group',
    fullPath: '/course-group',
    title: 'Course Group',
    key: 'course_group',
  },
  internshipPlanner: {
    pathName: 'internship-planner',
    fullPath: '/internship-planner',
    title: 'Internship Planner',
    key: 'internship_planner',
  },

  // STUDENT ROUTES
  myUploads: {
    pathName: 'my-uploads',
    fullPath: '/my-uploads',
    title: 'My Uploads',
    key: 'my_uploads',
  },
  selfReflection: {
    pathName: 'self-reflection',
    fullPath: '/self-reflection',
    title: 'Self Reflection',
    key: 'eni_yearmatrix_self_reflection',
    keyMoo: 'moo_self_evaluation',
  },
  oog: {
    pathName: 'oog',
    fullPath: '/oog',
    title: 'OOG',
    key: 'oog',
  },
  iop: {
    pathName: 'iop',
    fullPath: '/iop',
    title: 'IOP',
    key: 'mi_iop',
  },
  epa: {
    pathName: 'epa',
    fullPath: '/epa',
    title: 'EPA',
    key: 'epa',
  },
  procedureRegistration: {
    pathName: 'procedure-registration',
    fullPath: '/procedure-registration',
    title: 'Procedure Registration',
    key: 'mi_procedure_registration',
    keyEni: 'eni_procedure_nursing_situation_registration',
    keyMoo: 'moo_form_operation',
  },
  subEpa: {
    pathName: 'sub-epa',
    fullPath: '/sub-epa',
    title: 'Sub-EPA',
    key: 'sub-epa',
  },

  // admin
  userDatabase: {
    pathName: 'user-database',
    fullPath: '/user-database',
    title: 'User Database',
    key: 'user_database',
  },
  translationManager: {
    pathName: 'translation-manager',
    fullPath: '/translation-manager',
    title: 'Translation Manager',
    key: 'translation_manager',
  },
  uploadManager: {
    pathName: 'upload-manager',
    fullPath: '/upload-manager',
    title: 'Upload Manager',
    key: 'upload_manager',
  },
  emailManager: {
    pathName: 'email-manager',
    fullPath: '/email-manager',
    title: 'Email Manager',
    key: 'email_manager',
  },
  feedbackManager: {
    pathName: 'feedback-manager',
    fullPath: '/feedback-manager',
    title: 'Feedback Manager',
    key: 'feedback_manager',
  },
  payments: {
    pathName: 'payments',
    fullPath: '/payments',
    title: 'Payments',
    key: 'payments',
  },
  epaManager: {
    pathName: 'epa-manager',
    fullPath: '/epa-manager',
    title: 'EPA Manager',
    key: 'epa_manager',
  },
  epaSubEpaDatabase: {
    pathName: 'epa-subepa-database',
    fullPath: '/epa-subepa-database',
    title: 'EPA/Sub-EPA Database',
    key: 'epa_sub_epa_database',
  },
  forms: {
    pathName: 'forms',
    fullPath: '/forms',
    title: 'Forms',
    key: 'forms',
  },
  epaDatabase: {
    pathName: 'epa-database',
    fullPath: '/epa-database',
    title: 'EPA database',
    key: 'epa_database',
  },
  bannerNotification: {
    pathName: 'banner-notification',
    fullPath: '/banner-notification',
    title: 'Banner Notification',
    key: 'banner_notification',
  },
  epaCreationManager: {
    pathName: 'epa-creation-manager',
    fullPath: '/epa-creation-manager',
    title: 'EPA Creation Manager',
    key: 'epa-creation-manager',
  },
  settings: {
    pathName: 'settings',
    fullPath: '/settings',
    title: 'Settings',
    key: 'settings',
  },
};
