import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class DevOnlyGuard implements CanActivate {
  canActivate(): boolean {
    return !environment.production;
  }
}
