import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppState } from '@core/store/app.reducers';
import {
  setBasicInfoLoaded,
  setDataLoaded,
  setUpdateLoaded,
} from '@core/store/epa-library/epa-library.actions';
import { QueryParams } from '@ngrx/data';
import { Store } from '@ngrx/store';
import { EpaDetailUpdate } from '@regular-page-modules/epa-library/models/edit-details.model';
import { IHttpGenerateEpa } from '@regular-page-modules/epa-library/models/http-generate-epa.model';
import { UpdateDataForm } from '@regular-page-modules/epa-library/models/required-submissions.model';
import { DataUpdateBackgroundInfo } from '@shared/models/background-info.model';
import {
  EmployeeAssignedEPA,
  EmployeeAssignedInput,
  EmployeeItemUpdate,
} from '@shared/models/employee-assigned-epa.model';
import { EPAInformation, FileData } from '@shared/models/epa-information.model';
import {
  BasicEPAInformation,
  ChartOverview,
  EpaLibraryData,
  libraryDataInput,
} from '@shared/models/epa-library-data.model';
import { LibraryFilters } from '@shared/models/filters-list.model';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class EpaLibraryHttpService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {}

  getEPAUsageOverview(epaId: number): Observable<any> {
    return this.http.get<any>(`client/EPALibrary/UsageOverviewInfo/${epaId}`);
  }

  getOverviewEmployeeProgress(epaId: number): Observable<any> {
    return this.http.get<any>(
      `client/EPALibrary/OverviewEmployeeProgress/${epaId}`
    );
  }

  getEmployeeListByEPAId(
    epaId: number,
    payload?: QueryParams
  ): Observable<any> {
    const { employeeIds, departmentIds, levelIds, dateStarted, dateCompleted } =
      payload || {};
    let params = new HttpParams();

    if (employeeIds) {
      params = params.append('EmployeeIds', employeeIds.toString());
    }

    if (departmentIds) {
      params = params.append('DepartmentIds', departmentIds.toString());
    }

    if (levelIds) {
      params = params.append('LevelIds', levelIds.toString());
    }

    if (dateStarted) {
      params = params.append('DateStarted', dateStarted.toString());
    }

    if (dateCompleted) {
      params = params.append('DateCompleted', dateCompleted.toString());
    }

    return this.http.get<any>(`client/EPALibrary/SearchEmployee/${epaId}`, {
      params,
    });
  }

  getDepartmentFiltersParameters(userId: number): Observable<LibraryFilters> {
    let params = new HttpParams();
    params = params.append('UserId', userId.toString());
    return this.http.get<LibraryFilters>('client/Department', { params });
  }

  getChartOverview(departmentId: number): Observable<ChartOverview> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    let params = new HttpParams();
    params = params.append('departmentId', departmentId.toString());
    return this.http
      .get<ChartOverview>('client/EPALibrary/ChartOverview', {
        params,
      })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  getLibraryData(input: libraryDataInput): Observable<EpaLibraryData> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    let params = new HttpParams();
    for (const key of Object.keys(input)) {
      if (input[key] || input[key] === 0) {
        if (input[key] instanceof Array) {
          input[key].forEach((id) => {
            params = params.append(`${key.toString()}`, id);
          });
        } else if (input[key] instanceof Date) {
          params = params.append(key, input[key].toISOString());
        } else {
          params = params.append(key, input[key].toString());
        }
      }
    }
    return this.http
      .get<EpaLibraryData>('client/EPALibrary', {
        params,
      })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  getBasicEPAInformation(
    epaId: number,
    preventCashing: number,
    dataType: string
  ): Observable<BasicEPAInformation> {
    this.store.dispatch(setBasicInfoLoaded({ loadedBasicInfo: false }));
    const param = preventCashing ? `${epaId}?${preventCashing}` : `${epaId}`;
    return this.http
      .get<BasicEPAInformation>(
        `client/EPALibrary/BasicEPAInformation/${param}`,
        {
          params: {
            dataType,
          },
        }
      )
      .pipe(
        finalize(() =>
          this.store.dispatch(setBasicInfoLoaded({ loadedBasicInfo: true }))
        )
      );
  }

  getEPAInformation(
    epaId: number,
    preventCashing: number,
    dataType: string
  ): Observable<EPAInformation> {
    const params = preventCashing ? `${epaId}?${preventCashing}` : `${epaId}`;
    return this.http.get<EPAInformation>(`client/EPALibrary/${params}`, {
      params: {
        dataType,
      },
    });
  }

  getEmployeeAssignedEPA(
    input: EmployeeAssignedInput
  ): Observable<EmployeeAssignedEPA> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    let params = new HttpParams();
    for (const key of Object.keys(input)) {
      if (input[key] || input[key] === 0) {
        params = params.append(key, input[key].toString());
      }
    }
    return this.http
      .get<EmployeeAssignedEPA>('client/EPALibrary/SearchEmployee', {
        params,
      })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  updateEpaDetails(model: EpaDetailUpdate, epaId: number, dataType: string) {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    return this.http
      .put(`client/EPALibrary/UpdateEPADetails/${epaId}`, model, {
        observe: 'response',
        params: {
          dataType,
        },
      })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  uploadBackgroundImg(imageData: FormData): Observable<FileData> {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    return this.http
      .post<FileData>(`client/Common//UploadFile`, imageData)
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  updateBackground(
    model: DataUpdateBackgroundInfo,
    epaId: number,
    dataType: string
  ) {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    return this.http
      .put(`client/EPALibrary/Background/${epaId}`, model, {
        observe: 'response',
        params: {
          dataType,
        },
      })
      .pipe(
        finalize(() => this.store.dispatch(setDataLoaded({ loaded: true })))
      );
  }

  updateForms(model: UpdateDataForm, epaId: number, dataType: string) {
    return this.http.put(
      `client/EPALibrary/FormRequiredSubmission/${epaId}`,
      model,
      {
        observe: 'response',
        params: {
          dataType,
        },
      }
    );
  }

  updateEmployeesAssignedEpa(
    epaId: number,
    employeesUpdate: EmployeeItemUpdate[]
  ) {
    this.store.dispatch(setUpdateLoaded({ updateLoaded: false }));
    const data = { epaId: epaId, data: employeesUpdate };

    return this.http
      .post('client/EPALibrary/AssignEmployee', data, {
        observe: 'response',
      })
      .pipe(
        finalize(() => {
          this.store.dispatch(setUpdateLoaded({ updateLoaded: true }));
        })
      );
  }

  deleteEpaLibrary(epaId: number, dataType: string) {
    this.store.dispatch(setDataLoaded({ loaded: false }));
    return this.http
      .delete(`client/EPALibrary/${epaId}`, {
        observe: 'response',
        params: {
          dataType,
        },
      })
      .pipe(
        finalize(() => {
          this.store.dispatch(setDataLoaded({ loaded: true }));
        })
      );
  }

  updateRequiredCount(epaId: number, payload: any): Observable<any> {
    return this.http.put(`client/EPALibrary/FormRequiredSubmission/${epaId}`, {
      ...payload,
    });
  }

  generateEpa(data: IHttpGenerateEpa): Observable<any> {
    const payload = data instanceof Array ? data : [data];
    return this.http.post<any>(`client/EPAGeneration/Create`, {
      epas: payload,
    });
  }
}
