import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'xf-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFilterComponent),
      multi: true,
    },
  ],
})
export class InputFilterComponent implements ControlValueAccessor {
  @Input()
  inputFilterPlaceholder: string;

  @ViewChild('inputFilter')
  inputFilter: ElementRef;

  inputValue = '';

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: string) {
    if (value !== undefined && value !== this.inputValue) {
      this.inputValue = value;
      this.onChange(value);
    }
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  onInputChange(value: string) {
    this.writeValue(value);
  }

  onInputBlur(value: string) {
    this.writeValue(value);
    this.onTouched();
  }

  clear() {
    this.inputFilter.nativeElement.value = '';
    this.onInputChange('');
  }
}
