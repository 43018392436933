import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AppVersionHttpService {

  constructor(private _httpClient: HttpClient) {}

  getReleaseVersion(): Observable<string> {
    return this._httpClient.get('client/ReleaseVersion', { responseType: 'text' });
  }

}
