import { IPortfolioTypeConfigState } from '@core/store/portfolio-type-config/portfolio-type-config.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '../app.reducers';

export const selectPortfolioTypeConfigState = createFeatureSelector<IAppState, IPortfolioTypeConfigState>('portfolioTypeConfig');

export const selectPortfolioTypeConfig = createSelector(
  selectPortfolioTypeConfigState,
  state => state,
);

export const selectPortfolioTypeConfigLoading = createSelector(
  selectPortfolioTypeConfigState,
  state => state.loading,
);

export const selectPortfolioTypeConfigLoaded = createSelector(
  selectPortfolioTypeConfigState,
  state => state.loaded,
);

export const selectPortfolioTypeId = createSelector(
  selectPortfolioTypeConfigState,
  state => state.portfolioTypeId,
);

export const selectPortfolioTypeConfigForms = createSelector(
  selectPortfolioTypeConfigState,
  state => state.forms,
);

export const selectPortfolioTypeConfigPages = createSelector(
  selectPortfolioTypeConfigState,
  state => state.pages,
);

export const selectShowReferenceCompetencyLevel = createSelector(
  selectPortfolioTypeConfigState,
  state => state.showReferenceCompetencyLevel,
);

export const selectShowReferenceCurrentLevel = createSelector(
  selectPortfolioTypeConfigState,
  state => state.showReferenceCurrentLevel,
);

export const selectPortfolioTypeConfigRequiredLevels = createSelector(
  selectPortfolioTypeConfigState,
  state => state.requiredLevels,
);
