export enum PortfolioTypeEnum {
  ENI = 1,
  EPA = 2,
  MOO = 3,
  BMH = 4,
  ALBEDA = 5,

  // TODO: remove other portfolio types
  ExpertCollege = 6,
}
