<input matInput [style.visibility]="'hidden'">
<div class="filter-inner">
  <input 
    matInput
    autocomplete="off"
    [placeholder]="inputFilterPlaceholder"
    (keydown)="$event.stopPropagation()"
    (input)="onInputChange($event.target?.value)"
    (blur)="onInputBlur($event.target?.value)"
    [class.align]="inputFilterPlaceholder === ('search' | localize)"
    #inputFilter
    >
  <button *ngIf="inputValue" mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</div>
