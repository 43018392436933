import { Injectable } from '@angular/core';
import { PortfolioTypeHttpService } from '../../../modules/apis/client/https/portfolio-type-http.service';
import { getPortfolioTypeConfig, getPortfolioTypeConfigSuccess } from '@core/store/portfolio-type-config/portfolio-type-config.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';

@Injectable()
export class PortfolioTypeConfigEffects {

  getPortfolioTypeConfig$ = createEffect(() => this.actions$.pipe(
    ofType(getPortfolioTypeConfig),
    concatMap(() => this.portfolioTypeHttpService.getConfig()
      .pipe(
        map(portfolioTypeConfig => getPortfolioTypeConfigSuccess({ portfolioTypeConfig })),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private portfolioTypeHttpService: PortfolioTypeHttpService) { }

}
