<ng-container *ngIf="(isLocalizationLoaded$ | async); else loading">
  <xf-base-dialog [dialogTitle]="'download_app_title' | localize" [hideClose]="true">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutAlign="center center">
        <p>{{ 'download_app_description' | localize }}</p>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="2em">
          <button mat-raised-button color="primary" (click)="onDownloadApp()">{{ 'download' | localize }}</button>
        </div>
      </div>
    </mat-dialog-content>
  </xf-base-dialog>
</ng-container>
<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
