import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomTooltipComponent } from './container/custom-tooltip.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';

const components = [
  CustomTooltipComponent,
];

const directives = [
  CustomTooltipDirective,
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ...directives,
  ],
})
export class CustomTooltipModule {}
