import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { DevOnlyGuard } from '@core/guards/dev-only.guard';
import { LocalizationGuard } from '@core/guards/localization.guard';
import { PreventBrowserBackGuard } from '@core/guards/prevent-browser-back.guard';
import { UserStateGuard } from '@core/guards/user-state.guard';
import { ViewModeGuard } from '@core/guards/view-mode.guard';
import { PortfolioTypeConfigResolver } from '@core/resolvers/portfolio-type-config.resolver';
import { UserInfoResolver } from '@core/resolvers/user-info.resolver';
import { appRoutes } from '@shared/enums/app-routes.enum';

const testerRoutes: Routes = [
  {
    path: 'tester',
    loadChildren: () =>
      import('./modules/regular-pages/tester/tester.module')
      .then( (m) => m.TesterModule),
    canActivate: [DevOnlyGuard, LocalizationGuard],
  },
  {
    path: 'pie-test',
    loadChildren: () =>
      import('./modules/regular-pages/tester/pie-test/pie-test.module')
      .then((m) => m.PieTestModule),
    canActivate: [DevOnlyGuard],
  },
  {
    path: 'internship-planner-calendar-test',
    loadChildren: () =>
      import('./modules/regular-pages/tester/internship-planner-calendar-test/internship-planner-calendar-test.module')
      .then((m) => m.InternshipPlannerCalendarTestModule),
    canActivate: [DevOnlyGuard],
  },
  {
    path: 'student-epa-progress-overview-test',
    loadChildren: () =>
      import('./modules/regular-pages/tester/student-epa-progress-overview-test/student-epa-progress-overview-test.module')
      .then((m) => m.StudentEpaProgressOverviewTestModule),
    canActivate: [DevOnlyGuard],
  },
  {
    path: 'epa-subepa-dropdown-test',
    loadChildren: () =>
      import('./modules/regular-pages/tester/epa-subepa-dropdown-test/epa-subepa-dropdown-test.module')
      .then((m) => m.EpaSubepaDropdownTestModule),
    canActivate: [DevOnlyGuard],
  },
  {
    path: 'student-dialog-test',
    loadChildren: () =>
      import('./modules/regular-pages/tester/student-dialog-test/student-dialog-test.module')
      .then((m) => m.StudentDialogTestModule),
    canActivate: [DevOnlyGuard],
  },
  {
    path: 'student-add-delete-epa-dialog-test',
    loadChildren: () =>
      import('@regular-page-modules/tester/student-add-delete-epa-dialog-test/student-add-delete-epa-dialog-test.module')
      .then((m) => m.StudentAddDeleteEpaDialogTestModule),
    canActivate: [DevOnlyGuard],
  },
  {
    path: 'spider-web-test',
    loadChildren: () =>
      import( '@regular-page-modules/tester/spider-web-test/spider-web-test.module')
      .then((m) => m.SpiderWebTestModule),
    canActivate: [DevOnlyGuard],
  },
];

const publicRoutes: Routes = [
  {
    path: appRoutes.auth.pathName,
    loadChildren: () =>
      import('@regular-page-modules/auth/auth.module')
      .then( (m) => m.AuthModule),
    data: {
      title: appRoutes.auth.key,
    },
  },
  {
    path: appRoutes.formUpload.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/form-upload/form-upload.module')
      .then((m) => m.FormUploadModule),
    data: {
      title: appRoutes.formUpload.key,
    },
  },
  {
    path: appRoutes.invitation.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/invitation/invitation.module')
      .then( (m) => m.InvitationModule),
    data: {
      title: appRoutes.invitation.key,
    },
  },
  {
    path: appRoutes.token.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/token/token.module')
      .then((m) => m.TokenModule ),
    canActivate: [PreventBrowserBackGuard],
    data: {
      title: appRoutes.token.key,
    },
  },
  {
    path: appRoutes.thankYou.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/thank-you/thank-you.module')
      .then((m) => m.ThankYouModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.thankYou.key,
    },
  },
  {
    path: appRoutes.formCompleted.pathName,
    loadChildren: () =>
      import( './modules/regular-pages/form-completed/form-completed.module')
      .then((m) => m.FormCompletedModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.formCompleted.key,
    },
  },
  {
    path: appRoutes.formDeleted.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/form-deleted/form-deleted.module')
      .then((m) => m.FormDeletedModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.formDeleted.key,
    },
  },
  {
    path: appRoutes.formVoid.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/form-void/form-void.module')
      .then((m) => m.FormVoidModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.formVoid.key,
    },
  },
  {
    path: appRoutes.assessorRemoved.pathName,
    loadChildren: () =>
      import( './modules/regular-pages/assessor-removed/assessor-removed.module')
      .then((m) => m.AssessorRemovedModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.assessorRemoved.key,
    },
  },
  {
    path: appRoutes.userAlreadyBeenLinked.pathName,
    loadChildren: () =>
      import( './modules/regular-pages/user-already-linked/user-already-linked.module')
      .then((m) => m.UserAlreadyLinkedModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.userAlreadyBeenLinked.key,
    },
  },
  {
    path: appRoutes.about.publicPathName,
    loadChildren: () =>
      import('@regular-page-modules/about/about.module')
      .then((m) => m.AboutModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.about.key,
    },
  },
  {
    path: appRoutes.privacy.publicPathName,
    loadChildren: () =>
      import('@regular-page-modules/privacy/privacy.module')
      .then((m) => m.PrivacyModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.privacy.key,
    },
  },
  {
    path: appRoutes.contact.publicPathName,
    loadChildren: () =>
      import('@regular-page-modules/contact/contact.module')
      .then((m) => m.ContactModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.contact.key,
    },
  },
  {
    path: appRoutes.feedback.publicPathName,
    loadChildren: () =>
      import('@regular-page-modules/feedback/feedback.module')
      .then((m) => m.FeedbackModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.feedback.key,
    },
  },
  {
    path: appRoutes.conditionTerms.publicPathName,
    loadChildren: () =>
      import('@regular-page-modules/conditions-terms/conditions-terms.module')
      .then((m) => m.ConditionsTermsModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.conditionTerms.key,
    },
  },
  {
    path: appRoutes.logout.pathName,
    loadChildren: () =>
      import('@regular-page-modules/logout/logout.module')
      .then((m) => m.LogoutModule),
    canActivate: [PreventBrowserBackGuard],
    data: {
      title: appRoutes.logout.key,
    },
  },
  {
    path: appRoutes.releaseNotes.publicPathName,
    loadChildren: () =>
      import('@regular-page-modules/release-notes/release-notes.module')
      .then((m) => m.ReleaseNotesModule),
    canActivate: [LocalizationGuard],
    data: {
      title: appRoutes.releaseNotes.key,
    },
  },
  {
    path: appRoutes.changeLanguage.pathName,
    loadChildren: () =>
      import('@regular-page-modules/change-language/change-language.module')
      .then((m) => m.ChangeLanguageModule),
    canActivate: [PreventBrowserBackGuard],
    data: {
      title: appRoutes.changeLanguage.key,
    },
  },
  {
    path: appRoutes.switchGroup.pathName,
    loadChildren: () =>
      import('@regular-page-modules/switch-group/switch-group.module')
      .then((m) => m.SwitchGroupModule),
    canActivate: [PreventBrowserBackGuard],
    data: {
      title: appRoutes.switchGroup.key,
    },
  },
];

const clientRoutes: Routes = [
  {
    path: appRoutes.dashboard.pathName,
    loadChildren: () =>
      import('@regular-page-modules/dashboard/dashboard.module')
      .then((m) => m.DashboardModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.dashboard.key,
    },
  },
  {
    path: appRoutes.userActivity.pathName,
    loadChildren: () =>
      import('@regular-page-modules/user-activity/user-activity.module').then(
        (m) => m.UserActivityModule
      ),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.userActivity.key,
    },
  },
  {
    path: appRoutes.aiImplementationEmployees.pathName,
    loadChildren: () =>
      import(
        '@regular-page-modules/ai-implementation-employees/ai-implementation-employees.module'
      ).then((m) => m.AiImplementationEmployeesModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.aiImplementationEmployees.key,
    },
  },
  {
    path: appRoutes.employeeCombinations.pathName,
    loadChildren: () =>
      import(
        '@regular-page-modules/epa-employee-combinations/epa-employee-combinations.module'
      ).then((m) => m.EpaEmployeeCombinationsModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.employeeCombinations.key,
    },
  },
  {
    path: appRoutes.epaLibrary.pathName,
    loadChildren: () =>
      import('@regular-page-modules/epa-library/epa-library.module').then(
        (m) => m.EpaLibraryModule
      ),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.epaLibrary.key,
    },
  },
  {
    path: appRoutes.aiImplementationEmployees.pathName,
    loadChildren: () =>
      import(
        '@regular-page-modules/ai-implementation-employees/ai-implementation-employees.module'
      ).then((m) => m.AiImplementationEmployeesModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.aiImplementationEmployees.key,
    },
  },
  {
    path: appRoutes.notification.pathName,
    loadChildren: () =>
      import('@regular-page-modules/notification/notification.module')
      .then( (m) => m.NotificationModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.notification.key,
    },
  },
  {
    path: appRoutes.about.pathName,
    loadChildren: () =>
      import('@regular-page-modules/about/about.module')
      .then((m) => m.AboutModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.about.key,
    },
  },
  {
    path: appRoutes.releaseNotes.pathName,
    loadChildren: () =>
      import('@regular-page-modules/release-notes/release-notes.module')
      .then((m) => m.ReleaseNotesModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.releaseNotes.key,
    },
  },
  {
    path: appRoutes.privacy.pathName,
    loadChildren: () =>
      import('@regular-page-modules/privacy/privacy.module')
      .then((m) => m.PrivacyModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.privacy.key,
    },
  },
  {
    path: appRoutes.contact.pathName,
    loadChildren: () =>
      import('@regular-page-modules/contact/contact.module')
      .then((m) => m.ContactModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.contact.key,
    },
  },
  {
    path: appRoutes.feedback.pathName,
    loadChildren: () =>
      import('@regular-page-modules/feedback/feedback.module')
      .then((m) => m.FeedbackModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.feedback.key,
    },
  },
  {
    path: appRoutes.conditionTerms.pathName,
    loadChildren: () =>
      import('@regular-page-modules/conditions-terms/conditions-terms.module')
      .then((m) => m.ConditionsTermsModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.conditionTerms.key,
    },
  },
  {
    path: appRoutes.analytics.pathName,
    loadChildren: () =>
      import('@regular-page-modules/analytics/analytics.module')
      .then((m) => m.AnalyticsModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.analytics.key,
    },
  },
  {
    path: appRoutes.backToOwnPortfolio.pathName,
    loadChildren: () =>
      import('@regular-page-modules/back-to-own-portfolio/back-to-own-portfolio.module')
      .then(m => m.BackToOwnPortfolioModule),
    canActivate: [PreventBrowserBackGuard],
    data: {
      title: appRoutes.backToOwnPortfolio.key,
    },
  },
  {
    path: appRoutes.internshipIntroductoryMeeting.pathName,
    loadChildren: () =>
      import('@form-modules/internship-introductory-meeting/internship-introductory-meeting.module')
      .then((m) => m.InternshipIntroductoryMeetingModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.internshipIntroductoryMeeting.key,
    },
  },
  {
    path: appRoutes.internshipEvaluation.pathName,
    loadChildren: () =>
      import('@form-modules/internship-evaluation/internship-evaluation.module')
      .then((m) => m.InternshipEvaluationModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.internshipEvaluation.key,
    },
  },
  {
    path: appRoutes.periodicEvaluation.pathName,
    loadChildren: () =>
      import('@form-modules/periodic-evaluation/periodic-evaluation.module')
      .then((m) => m.PeriodicEvaluationModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.periodicEvaluation.key,
    },
  },
  {
    path: appRoutes.caseBasedDiscussion.pathName,
    loadChildren: () =>
      import('@form-modules/case-based-discussion/case-based-discussion.module')
      .then((m) => m.CaseBasedDiscussionModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.caseBasedDiscussion.key,
    },
  },
  {
    path: appRoutes.theoreticalExam.pathName,
    loadChildren: () =>
      import('@form-modules/theoretical-exam/theoretical-exam.module')
      .then((m) => m.TheoreticalExamModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.theoreticalExam.key,
    },
  },
  {
    path: appRoutes.kpb.pathName,
    loadChildren: () =>
      import('@form-modules/kpb/kpb.module')
      .then((m) => m.KpbModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.kpb.key,
    },
  },
  {
    path: appRoutes.kpe.pathName,
    loadChildren: () =>
      import('@form-modules/kpb/kpb.module')
      .then((m) => m.KpbModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.kpe.key,
    },
  },
  {
    path: appRoutes.cat.pathName,
    loadChildren: () =>
      import('@form-modules/cat/cat.module')
      .then((m) => m.CatModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.cat.key,
    },
  },
  {
    path: appRoutes.osats.pathName,
    loadChildren: () =>
      import('@form-modules/osats/osats.module')
      .then((m) => m.OsatsModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.osats.key,
    },
  },
  {
    path: appRoutes.viva.pathName,
    loadChildren: () =>
      import('@form-modules/viva/viva.module')
      .then((m) => m.VivaModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.viva.key,
    },
  },
  {
    path: appRoutes.scientificActivity.pathName,
    loadChildren: () =>
      import('@form-modules/scientific-activity/scientific-activity.module')
      .then((m) => m.ScientificActivityModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.scientificActivity.key,
    },
  },
  {
    path: appRoutes.certificate.pathName,
    loadChildren: () =>
      import('@form-modules/certificate/certificate.module')
      .then((m) => m.CertificateModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.certificate.key,
    },
  },
  {
    path: appRoutes.otherAssessment.pathName,
    loadChildren: () =>
      import('@form-modules/other-assessment/other-assessment.module')
      .then((m) => m.OtherAssessmentModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.otherAssessment.key,
      titleBmh: appRoutes.otherAssessment.keyBmh,
    },
  },
  {
    path: appRoutes.threeSixtyFeedback.pathName,
    loadChildren: () =>
      import('@form-modules/360-feedback/360-feedback.module')
      .then((m) => m.ThreeSixtyFeedbackModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.threeSixtyFeedback.key,
    },
  },
  {
    path: appRoutes.viewPdf.pathName,
    loadChildren: () =>
      import('@regular-page-modules/view-pdf/view-pdf.module')
      .then((m) => m.ViewPdfModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.viewPdf.key,
    },
  },
  {
    path: appRoutes.starrReflection.pathName,
    loadChildren: () =>
      import('@form-modules/starr-reflection/starr-reflection.module')
      .then((m) => m.StarrReflectionModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.starrReflection.key,
    },
  },
  {
    path: appRoutes.shiftEvaluation.pathName,
    loadChildren: () =>
      import('@form-modules/shift-evaluation/shift-evaluation.module')
      .then((m) => m.ShiftEvaluationModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.shiftEvaluation.key,
    },
  },
  {
    path: appRoutes.viewStudentPortfolio.pathName,
    loadChildren: () =>
      import('@regular-page-modules/view-student-portfolio/view-student-portfolio.module')
      .then((m) => m.ViewStudentPortfolioModule),
    canActivate: [
      AuthGuard,
      LocalizationGuard,
      ViewModeGuard,
      UserStateGuard,
      PreventBrowserBackGuard,
    ],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.viewStudentPortfolio.key,
      titleEni: appRoutes.viewStudentPortfolio.keyEni,
      titleMoo: appRoutes.viewStudentPortfolio.keyMoo,
      titleBmh: appRoutes.viewStudentPortfolio.keyBmh,
    },
  },
  {
    path: appRoutes.portfolioSettings.pathName,
    loadChildren: () =>
      import('@regular-page-modules/portfolio-settings/portfolio-settings.module')
      .then((m) => m.PortfolioSettingsModule),
    canActivate: [AuthGuard, LocalizationGuard, ViewModeGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.portfolioSettings.key,
    },
  },
  {
    path: appRoutes.myReviews.pathName,
    loadChildren: () =>
      import('@regular-page-modules/my-reviews/my-reviews.module')
      .then((m) => m.MyReviewsModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.myReviews.key,
    },
  },
  {
    path: appRoutes.todo.pathName,
    loadChildren: () =>
      import('@regular-page-modules/todo/todo.module').then(
        (m) => m.TodoModule
      ),
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    canActivate: [AuthGuard, LocalizationGuard],
    data: {
      title: appRoutes.todo.key,
    },
  },
  {
    path: appRoutes.assessments.pathName,
    loadChildren: () =>
      import('@regular-page-modules/assessments/assessments.module').then(
        (m) => m.AssessmentsModule
      ),
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    canActivate: [AuthGuard, LocalizationGuard],
    data: {
      title: appRoutes.assessments.key,
    },
  },
  {
    path: appRoutes.generalAssessments.pathName,
    loadChildren: () =>
      import('@form-modules/general-assessments/general-assessments.module').then(
        (m) => m.GeneralAssessmentsModule
      ),
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    canActivate: [AuthGuard, LocalizationGuard],
    data: {
      title: appRoutes.generalAssessments.key,
    },
  },

  // HT ROUTES
  {
    path: appRoutes.internshipPlanner.pathName,
    loadChildren: () =>
      import('@form-modules/internship-planner/internship-planner.module')
      .then((m) => m.InternshipPlannerModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.internshipPlanner.key,
    },
  },
  {
    path: appRoutes.courseGroup.pathName,
    loadChildren: () =>
      import('@regular-page-modules/course-group/course-group.module')
      .then((m) => m.CourseGroupModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.courseGroup.key,
    },
  },

  // STUDENT ROUTES
  {
    path: appRoutes.myUploads.pathName,
    loadChildren: () =>
      import('@regular-page-modules/my-uploads/my-uploads.module')
      .then((m) => m.MyUploadsModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.myUploads.key,
    },
  },
  {
    path: appRoutes.selfReflection.pathName,
    loadChildren: () =>
      import('@form-modules/self-reflection/self-reflection.module')
      .then((m) => m.SelfReflectionModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.selfReflection.key,
      titleMoo: appRoutes.selfReflection.keyMoo,
    },
  },
  {
    path: appRoutes.oog.pathName,
    loadChildren: () =>
      import('@form-modules/oog/oog.module')
      .then((m) => m.OogModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.oog.key,
    },
  },
  {
    path: appRoutes.iop.pathName,
    loadChildren: () =>
      import('@regular-page-modules/iop/iop.module')
      .then((m) => m.IopModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.iop.key,
    },
  },
  {
    path: appRoutes.epa.pathName,
    loadChildren: () =>
      import('@regular-page-modules/epa/epa.module')
      .then((m) => m.EpaModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.epa.key,
    },
  },
  {
    path: appRoutes.procedureRegistration.pathName,
    loadChildren: () =>
      import('@form-modules/procedure-registration/procedure-registration.module')
      .then((m) => m.ProcedureRegistrationModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.procedureRegistration.key,
      titleEni: appRoutes.procedureRegistration.keyEni,
      titleMoo: appRoutes.procedureRegistration.keyMoo,
    },
  },
  {
    path: appRoutes.subEpa.pathName,
    loadChildren: () =>
      import('@regular-page-modules/sub-epa/sub-epa.module')
      .then((m) => m.SubEpaModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
  },
  {
    path: appRoutes.forms.pathName,
    loadChildren: () =>
      import('@regular-page-modules/forms/forms.module')
      .then((m) => m.FormsModule),
    canActivate: [AuthGuard, LocalizationGuard, UserStateGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.forms.key,
    },
  },
];

const adminRoutes: Routes = [
  {
    path: appRoutes.userDatabase.pathName,
    loadChildren: () =>
      import('@management/user-database/user-database.module')
      .then((m) => m.UserDatabaseModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.userDatabase.key,
    },
  },
  {
    path: appRoutes.translationManager.pathName,
    loadChildren: () =>
      import('@management/translation-manager/translation-manager.module')
      .then((m) => m.TranslationManagerModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.translationManager.key,
    },
  },
  {
    path: appRoutes.uploadManager.pathName,
    loadChildren: () =>
      import('@management/upload-manager/upload-manager.module')
      .then((m) => m.UploadManagerModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.uploadManager.key,
    },
  },
  {
    path: appRoutes.emailManager.pathName,
    loadChildren: () =>
      import('@management/email-manager/email-manager.module')
      .then((m) => m.EmailManagerModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.emailManager.key,
    },
  },
  {
    path: appRoutes.feedbackManager.pathName,
    loadChildren: () =>
      import('@management/feedback-manager/feedback-manager.module')
      .then((m) => m.FeedbackManagerModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.feedbackManager.key,
    },
  },
  {
    path: appRoutes.payments.pathName,
    loadChildren: () =>
      import('@management/payments/payments.module')
      .then((m) => m.PaymentsModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.payments.key,
    },
  },
  {
    path: appRoutes.epaManager.pathName,
    loadChildren: () =>
      import('@management/epa-manager/epa-manager.module')
      .then((m) => m.EpaManagerModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.epaManager.key,
    },
  },
  {
    path: appRoutes.epaDatabase.pathName,
    loadChildren: () =>
      import('@management/epa-database/epa-database.module')
      .then((m) => m.EpaDatabaseModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.epaDatabase.key,
    },
  },
  {
    path: appRoutes.bannerNotification.pathName,
    loadChildren: () =>
      import('@management/banner-notification/banner-notification.module')
      .then((m) => m.BannerNotificationModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.bannerNotification.key,
    },
  },
  {
    path: appRoutes.epaCreationManager.pathName,
    loadChildren: () =>
      import('@management/epa-creation-manager/epa-creation-manager.module')
      .then((m) => m.EpaCreationManagerModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.epaCreationManager.key,
    },
  },
];

const routes: Routes = [
  {
    path: appRoutes.profile.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/profile/profile.module')
      .then((m) => m.ProfileModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.profile.key,
    },
  },
  {
    path: appRoutes.settings.pathName,
    loadChildren: () =>
      import('./modules/regular-pages/settings/settings.module')
      .then((m) => m.SettingsModule),
    canActivate: [AuthGuard, LocalizationGuard],
    resolve: {
      userInfo: UserInfoResolver,
      portfolioTypeConfig: PortfolioTypeConfigResolver,
    },
    data: {
      title: appRoutes.settings.key,
    },
  },
  ...clientRoutes,
  ...adminRoutes,
  ...testerRoutes,
  ...publicRoutes,
  {
    path: '**',
    redirectTo: appRoutes.auth.pathName,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
