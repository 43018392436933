import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getName } from '../../utils/user-profile.util';
import { IAppState } from '../app.reducers';
import { IUserInfoState } from './user-info.reducer';

export const selectUserInfoState = createFeatureSelector<IAppState, IUserInfoState>('userInfo');

export const selectUserInfo = createSelector(
  selectUserInfoState,
  state => state,
);

export const selectUserInfoId = createSelector(
  selectUserInfoState,
  state => state.id,
);

export const selectIsUserInfoLoading = createSelector(
  selectUserInfoState,
  state => state.loading,
);

export const selectIsUserInfoLoaded = createSelector(
  selectUserInfoState,
  state => state.loaded,
);

export const selectUnreadNotificationCount = createSelector(
  selectUserInfoState,
  state => state.unreadNotificationCount,
);

export const selectUserRoleId = createSelector(
  selectUserInfoState,
  state => state.roleId,
);

export const selectUserName = createSelector(
  selectUserInfoState,
  state => getName(state),
);

export const selectUserNameNoTitle = createSelector(
  selectUserInfoState,
  state => getName(state, false),
);

export const selectLastActivityDate = createSelector(
  selectUserInfoState,
  state => state.lastActivityDate,
);

export const selectMyReviewCount = createSelector(
  selectUserInfoState,
  state => state.myReviewCount,
);

export const selectCurrentYear = createSelector(
  selectUserInfoState,
  state => state.currentYear,
);

export const selectCurrentSemester = createSelector(
  selectUserInfoState,
  state => state.currentSemester,
);

export const selectCurrentUserProfile = createSelector(
  selectUserInfoState,
  state => state.userProfile,
);

export const selectPortfolioGroupMemberId = createSelector(
  selectUserInfoState,
  state => state.portfolioGroupMemberId,
);

export const selectGroupMemberships = createSelector(
  selectUserInfoState,
  state => state.groupMemberships,
);


export const selectAvailableSwitchProfile = createSelector(
  selectUserInfoState,
  state => state.availableSwitchProfile,
);

export const selectGroupMembershipsCount = createSelector(
  selectUserInfoState,
  state => state.groupMemberships?.length,
);

export const selectUserRoleName = createSelector(
  selectUserInfoState,
  state => state.roleName,
);

export const selectCurrentUserId = createSelector(
  selectUserInfoState,
  state => state.id,
);

export const selectIsViewModeByHT = createSelector(
  selectUserInfoState,
  state => state.viewMode,
);

export const selectHasInternshipSchedule = createSelector(
  selectUserInfoState,
  state => state.hasInternshipSchedule,
);

export const selectGroupInfoSpeciality = createSelector(
  selectUserInfoState,
  state => state.groupInfo?.specialityName,
);

export const selectProfilePhotoUrl = createSelector(
  selectUserInfoState,
  state => state.profilePhotoUrl,
);

export const selectPortfolioTypeId = createSelector(
  selectUserInfoState,
  state => state.portfolioTypeId,
);

export const selectIsMentor = createSelector(
  selectUserInfoState,
  state => state.isMentor,
);

export const selectMemberStatus = createSelector(
  selectUserInfoState,
  state => state.groupInfo?.memberStatus,
);

export const selectUserEmail = createSelector(
  selectUserInfoState,
  state => state.email,
);

export const selectViewModeByName = createSelector(
  selectUserInfoState,
  state => state.viewModeByName,
);

export const selectViewModeByProfilePhotoUrl = createSelector(
  selectUserInfoState,
  state => state.viewModeByProfilePhotoUrl,
);

export const selectTodoCounts = createSelector(
  selectUserInfoState,
  state => state.todoCounts,
);