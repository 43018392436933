<div class="mat-elevation-z2">

  <!-- Table Upper Actions -->
  <mat-toolbar>
    <button mat-icon-button>
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon>block</mat-icon>
    </button>
  </mat-toolbar>

  <!-- Table -->
  <mat-table
    matSort
    [dataSource]="dataSource">

      <!-- Table Column Def -->
      <ng-container [matColumnDef]="column" *ngFor="let column of columns">
        <mat-header-cell
          sticky
          *matHeaderCellDef
          fxLayout="column"
          fxLayoutAlign="center start">

            <!-- Table Column Title, Sort, and Filter Option -->
            <div class="column-title" fxLayoutAlign="space-between center">
              <span [mat-sort-header]="column" fxFlex>{{ column | titlecase }}</span>

              <!-- Temporary -->
              <button mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>

            </div>

            <!--
              Table Column Filter
              It will be soon replaced by the right shared component
              But you can use this temporarily by adding formControl/formControlName in the input field
            -->
            <div class="column-filter" fxLayoutAlign="start center">
              <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput />
              </mat-form-field>
              <button mat-icon-button>
                <mat-icon>filter_alt</mat-icon>
              </button>
            </div>

        </mat-header-cell>

        <!-- Table Column Data -->
        <mat-cell *matCellDef="let element"> {{ element[column] }} </mat-cell>

      </ng-container>

    <!-- Table Header Row Def -->
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

    <!-- Table Row Def -->
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>

  <!-- Table Paginator and Refresh -->
  <mat-toolbar fxLayoutAlign="space-between center">
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <button mat-icon-button>
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-toolbar>

</div>
