<ul fxLayoutAlign="center center" fxLayoutGap="3em">
  <li>
    <a [routerLink]="appRoutes.about.publicFullPath">{{ 'about' | localize }}</a>
  </li>
  <li>
    <a [routerLink]="appRoutes.contact.publicFullPath">{{ 'contact' | localize }}</a>
  </li>
  <li>
    <a [routerLink]="appRoutes.privacy.publicFullPath">{{ 'privacy' | localize }}</a>
  </li>
  <li>
    <a [routerLink]="appRoutes.conditionTerms.publicFullPath">{{ 'conditions_terms' | localize }}</a>
  </li>
  <li>
    <button id="btnChangeLanguage" mat-icon-button disableRipple class="language" [matMenuTriggerFor]="languageMenu">
      <img [src]="selectedTranslationImgPath" alt="Language">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu class="admin-language-menu" xPosition="before" #languageMenu="matMenu">
      <span>{{ 'choose_language' | localize }}:</span>
      <button id="btnDutch" mat-menu-item fxLayoutAlign="start center" (click)="onSelectLanguage(translations.Dutch)">
        <img src="assets/images/flags/nl.png" alt="Dutch">
        <span fxFlexOffset="0.7em">Nederlands</span>
      </button>
      <button id="btnEnglish" mat-menu-item fxLayoutAlign="start center" (click)="onSelectLanguage(translations.English)">
        <img src="assets/images/flags/gb.png" alt="English">
        <span fxFlexOffset="0.7em">English</span>
      </button>
    </mat-menu>
  </li>
</ul>
