import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProfilePhotoModule } from '@shared-modules/profile-photo/profile-photo.module';
import { SharedModule } from '@shared/shared.module';
import { AppBlockerDialogComponent } from './app-blocker-dialog/app-blocker-dialog.component';
import { AppBlockerOverlayComponent } from './app-blocker-overlay/app-blocker-overlay.component';
import { AppBlockerService } from './services/app-blocker.service';

const materials = [
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatIconModule,
];

@NgModule({
  declarations: [
    AppBlockerDialogComponent,
    AppBlockerOverlayComponent,
  ],
  imports: [
    ...materials,
    ProfilePhotoModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    FlexLayoutModule,
  ],
  providers: [
    AppBlockerService,
  ],
  exports: [
    AppBlockerDialogComponent,
    AppBlockerOverlayComponent,
  ],
})
export class AppBlockerModule {}
