import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@core/utils/string.util';

@Pipe({
  name: 'phraseTitleCase',
})
export class PhraseTitleCase implements PipeTransform {

  transform(value: string): string {
    return StringUtil.phraseTitleCase(value);
  }

}
