import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface IEpaCreatePayload {
  taskTitle: string;
  taskDescripion: string;
  portfolioTypeId: number;
  company: string;
  department: string;
  aiTool: string;
  email: string;
  forms: {
    formKey: string;
    requiredCount: number;
    groupAssessments: {
      groupAssessment: string;
      assessments: string[];
    }[];
  }[];
  backgrounds: {
    tabName: string;
    paragraphs: string[];
  }[];
}

@Injectable({ providedIn: 'root' })
export class EpaCreationHttpService {
  constructor(private http: HttpClient) {}

  saveEpas(epas: { epas: IEpaCreatePayload[] }): Observable<{ id: number[] }> {
    return this.http.post<{ id: number[] }>('admin/EPAGeneration', epas);
  }
}
