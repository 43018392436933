export const apiClientPortfolioGroupMembers = 'client/PortfolioGroupMember';
export const apiClientPortfolioGroupGetCurrentLinkedMembers = 'client/PortfolioGroup/GetCurrentLinkedMembers';
export const apiClientGetAllChangeScheduleRequests = 'client/IOP/ScheduleTraining/RequestChange';
export const apiClientIopIopRemark = 'client/IOP/IOPRemark';
export const apiClientElearning = 'client/Elearning';
export const apiClientPortfolioGroupEPAFormSubTypeCount = 'client/PortfolioGroupEPAFormSubTypeCount';
export const apiClientAnalyticsFeedback = 'client/Analytics/Feedback';
export const apiClientIopGetFullInformation = 'client/IOP/GetFullInformation';
export const apiClientUserProfile = 'client/UserProfile';
export const apiClientPortfolioGroup = 'client/PortfolioGroup';
export const apiClientIopIopStudentSpecialitySchedule = 'client/IOP/GetStudentSpecialitySchedule';
export const apiGetGroupInformation = 'client/PortfolioGroup/GetGroupInformationQuery';
export const apiGetBannerNotification = 'client/BannerMessage';
export const apiGetReviewNewlyCreatedEpas = 'client/Todo/GetReviewNewlyCreatedEpas';
