import { Injectable } from '@angular/core';
import { DefaultHttpUrlGenerator, HttpResourceUrls, HttpUrlGenerator } from '@ngrx/data';

@Injectable()
export class EpaHttpUrlGenerator extends DefaultHttpUrlGenerator {

  private resourceUrls: { [id: string]: HttpResourceUrls } = {
    User: {
      entityResourceUrl: 'admin/User/',
      collectionResourceUrl: 'admin/User/Search',
    },
    AdminEpa: {
      entityResourceUrl: 'admin/Epa/',
      collectionResourceUrl: 'admin/Epa',
    },
    AdminSubEpa: {
      entityResourceUrl: 'admin/SubEpa/',
      collectionResourceUrl: 'admin/SubEpa',
    },
    AdminEpaBackground: {
      entityResourceUrl: 'admin/EpaBackground/',
      collectionResourceUrl: 'admin/EpaBackground',
    },
    AdminSubEpaBackground: {
      entityResourceUrl: 'admin/SubEpaBackground/',
      collectionResourceUrl: 'admin/SubEpaBackground',
    },
    AdminEpaBackgroundContent: {
      entityResourceUrl: 'admin/EpaBackgroundContent/',
      collectionResourceUrl: 'admin/EpaBackgroundContent',
    },
    AdminSubEpaBackgroundContent: {
      entityResourceUrl: 'admin/SubEpaBackgroundContent/',
      collectionResourceUrl: 'admin/SubEpaBackgroundContent',
    },
    AdminEpaFormCount: {
      entityResourceUrl: 'admin/EpaFormCount/',
      collectionResourceUrl: 'admin/EpaFormCount',
    },
    AdminSubEpaFormCount: {
      entityResourceUrl: 'admin/SubEpaFormCount/',
      collectionResourceUrl: 'admin/SubEpaFormCount',
    },
    AdminEpaFormSubTypeCount: {
      entityResourceUrl: 'admin/EpaFormSubTypeCount/',
      collectionResourceUrl: 'admin/EpaFormSubTypeCount',
    },
    AdminSubEpaFormSubTypeCount: {
      entityResourceUrl: 'admin/SubEpaFormSubTypeCount/',
      collectionResourceUrl: 'admin/SubEpaFormSubTypeCount',
    },
    AdminPortfolioType: {
      entityResourceUrl: 'admin/PortfolioType/',
      collectionResourceUrl: 'admin/PortfolioType',
    },
    AdminSpeciality: {
      entityResourceUrl: 'admin/Speciality/',
      collectionResourceUrl: 'admin/Speciality',
    },
    AdminFormSubType: {
      entityResourceUrl: 'admin/FormSubType/',
      collectionResourceUrl: 'admin/FormSubType',
    },
    ClientPortfolioGroupMember: {
      entityResourceUrl: 'client/PortfolioGroupMember/',
      collectionResourceUrl: 'client/PortfolioGroupMember',
    },
    PortfolioGroup: {
      entityResourceUrl: 'admin/PortfolioGroup/',
      collectionResourceUrl: 'admin/PortfolioGroups',
    },
    ClientPortfolioGroup: {
      entityResourceUrl: 'client/PortfolioGroup/',
      collectionResourceUrl: 'client/PortfolioGroups',
    },
    PortfolioType: {
      entityResourceUrl: 'admin/PortfolioType',
      collectionResourceUrl: 'admin/PortfolioTypes',
    },
    Speciality: {
      entityResourceUrl: 'admin/Speciality',
      collectionResourceUrl: 'admin/Specialities',
    },
    ClientPortfolioType: {
      entityResourceUrl: 'client/PortfolioType/',
      collectionResourceUrl: 'client/PortfolioType',
    },
    ClientSpeciality: {
      entityResourceUrl: 'client/Speciality/',
      collectionResourceUrl: 'client/Speciality',
    },
    ClientEpa: {
      entityResourceUrl: 'client/Epa/',
      collectionResourceUrl: 'client/Epa',
    },
    ClientEpaLevelOfCompetence: {
      entityResourceUrl: 'client/EPA/CompetenceLevel/',
      collectionResourceUrl: 'client/EPA/CompetenceLevel',
    },
    ClientStudentEpa: {
      entityResourceUrl: 'client/StudentEpa/',
      collectionResourceUrl: 'client/StudentEpa',
    },
    AnalyticsFeedback: {
      entityResourceUrl: 'client/Analytics/Feedback/',
      collectionResourceUrl: 'client/Analytics/Feedback/Search',
    },
    NotificationTemplate: {
      entityResourceUrl: 'admin/NotificationTemplate/',
      collectionResourceUrl: 'admin/NotificationTemplate/Search',
    },
    NotificationEmailSender: {
      entityResourceUrl: 'admin/NotificationEmailSender/',
      collectionResourceUrl: 'admin/NotificationEmailSenders',
    },
    NotificationTemplateType: {
      entityResourceUrl: 'admin/NotificationTemplateType/',
      collectionResourceUrl: 'admin/NotificationTemplateTypes',
    },
    Internship: {
      entityResourceUrl: 'client/Internship/',
      collectionResourceUrl: 'client/Internships',
    },
    Translation: {
      entityResourceUrl: 'admin/Localization/',
      collectionResourceUrl: 'admin/Localization/Search',
    },
    ClientFormUpload: {
      entityResourceUrl: 'client/FormUpload/',
      collectionResourceUrl: 'client/FormUpload',
    },
    ClientTrainingProgram: {
      entityResourceUrl: 'client/StudentEpa/GetCurrentTrainingPrograms/',
      collectionResourceUrl: 'client/StudentEpa/GetCurrentTrainingPrograms',
    },
    ClientEpaProgress: {
      entityResourceUrl: 'client/Epa/GetEpaProgress/',
      collectionResourceUrl: 'client/Epa/GetEpaProgress',
    },
    ClientSubmissionReview: {
      entityResourceUrl: 'client/FormUpload/GetSubmissionReview/',
      collectionResourceUrl: 'client/FormUpload/GetSubmissionReview',
    },
    ClientFormSubmission: {
      entityResourceUrl: 'client/FormUpload/GetFormSubmissions/',
      collectionResourceUrl: 'client/FormUpload/GetFormSubmissions',
    },
    ClientEvaluationAndReflection: {
      entityResourceUrl: 'client/FormUpload/GetEvaluationsAndReflection/',
      collectionResourceUrl: 'client/FormUpload/GetEvaluationsAndReflection',
    },
    ClientEpaLinkedSubEpa: {
      entityResourceUrl: 'client/EPA/SubEPA/LindkedSubEPA/',
      collectionResourceUrl: 'client/EPA/SubEPA/LindkedSubEPA',
    },
    ClientFormUploadPendingAssessments: {
      entityResourceUrl: 'client/FormUpload/QuickList/PendingAssessments/',
      collectionResourceUrl: 'client/FormUpload/QuickList/PendingAssessments/',
    },
    ClientInternshipLinkedAssessment: {
      entityResourceUrl: 'client/FormUpload/GetInternshipAssessmentLinked/',
      collectionResourceUrl: 'client/FormUpload/GetInternshipAssessmentLinked',
    },
    ClientInternshipSubmission: {
      entityResourceUrl: 'client/FormUpload/GetInternshipSubmissions/',
      collectionResourceUrl: 'client/FormUpload/GetInternshipSubmissions',
    },
    ClientCategory: {
      entityResourceUrl: 'client/Category/',
      collectionResourceUrl: 'client/Category',
    },
    ClientGetAssessmentRequests: {
      entityResourceUrl: 'client/Todo/GetAssessmentRequests/',
      collectionResourceUrl: 'client/Todo/GetAssessmentRequests',
    },
    ClientGetEpaRequests: {
      entityResourceUrl: 'client/Todo/GetEpaRequests/',
      collectionResourceUrl: 'client/Todo/GetEpaRequests',
    },
    ClientGetReviewNewlyCreatedEpas: {
      entityResourceUrl: 'client/Todo/GetReviewNewlyCreatedEpas/',
      collectionResourceUrl: 'client/Todo/GetReviewNewlyCreatedEpas',
    },
    ClientGetSystemActions: {
      entityResourceUrl: 'client/Todo/GetSystemActions/',
      collectionResourceUrl: 'client/Todo/GetSystemActions',
    },
    ClientGetDepartmentTimeSavedWithAiImplementation: {
      entityResourceUrl: 'Dashboard/GetDepartmentTimeSavedWithAiImplementation/',
      collectionResourceUrl: 'Dashboard/GetDepartmentTimeSavedWithAiImplementation',
    },
    ClientGetEmployeeTimeSavedWithAiImplementation: {
      entityResourceUrl: 'Dashboard/GetEmployeeTimeSavedWithAiImplementation/',
      collectionResourceUrl: 'Dashboard/GetEmployeeTimeSavedWithAiImplementation',
    },
    ClientGetEPAImplementation: {
      entityResourceUrl: 'client/EPA/GetEPAImplementation/',
      collectionResourceUrl: 'client/EPA/GetEPAImplementation',
    },
    ClientGetCompletedAssessments: {
      entityResourceUrl: 'client/FormUpload/GetCompletedAssessments/',
      collectionResourceUrl: 'client/FormUpload/GetCompletedAssessments',
    },
  };

  getResourceUrls(entityName: string, root: string): HttpResourceUrls {
    return this.resourceUrls[entityName];
  }
}

export const httpUrlGeneratorProvider = {
  provide: HttpUrlGenerator,
  useClass: EpaHttpUrlGenerator,
};
