import { Injectable } from '@angular/core';

@Injectable()
export class UrlService {

  getCurrentRouteWithQueryAndFragments(): string {
    return window.location.href.substring(window.location.origin.length);
  }

  getCurrentRouteParams(): any {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as any),
    });
  }

}
