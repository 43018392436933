import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {

  download(file: HttpResponse<any>) {
    const contentDisposition = file.headers.get('Content-Disposition');
    const contentType = file.headers.get('Content-Type');
    const filenameRegex = /filename="?(.*?)"?$/;
    const filename = filenameRegex.exec(contentDisposition);
    const blob = new Blob([file.body], {
      type: contentType,
    });

    const url = window.URL.createObjectURL(blob);
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename && filename.length > 1 ? filename[1] : 'download';
    a.click();

    window.URL.revokeObjectURL(url);
  }
}