import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'xf-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
})
export class ProfilePhotoComponent implements OnChanges, OnInit {
  @Input()
  fileUrl: string;

  @Input()
  get size(): string {
    return this._size;
  }
  set size(value: string) {
    if (value) {
      value = value.replace(/[a-zA-Z]+/g, '');
    }
    this._size = value;
  }

  @Input()
  userName = 'User';

  @Input()
  isLazy = true;

  isLoading = true;

  get alternateImageSrc(): string {
    return this._alternateImageSrc;
  }

  private _size = '30';
  private _alternateImageSrc = '';

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.userName?.currentValue &&
      changes.userName.currentValue !== changes.userName.previousValue
    ) {
      this._alternateImageSrc = this.getAlternateImage();
    }
  }

  ngOnInit() {
    this._alternateImageSrc = this.getAlternateImage();
  }

  onImageError(event) {
    event.target.src = this.alternateImageSrc;
  }

  private getAlternateImage(): string {
    const avatarName = this.userName.replace(/\s/g, '+');
    return `https://ui-avatars.com/api/?name=${avatarName}&size=${this.size}px&background=1667a2&color=ffffff`;
  }
}
