export class DashboardTopPerformerDisplayed {
  departmentName: string;
  hours: string;
  fullName: string;
  imageUrl: string;
}

export class DashboardPerformersAndDepartments {
  departmentName: string;
  hours: string;
  numberOfHours: number;
  fullName?: string;
}
