import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortfolioTypeConfig } from '@client-shared-modules/models/portfolio-type-config.model';
import { PortfolioType } from '@client-shared-modules/models/portfolio-type.model';
import { PortfolioRequiredLevelInformation } from '@core/store/portfolio-type-config/portfolio-type-config.reducer';
import { Observable } from 'rxjs';

@Injectable()
export class PortfolioTypeHttpService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<PortfolioType[]> {
    return this.http.get<PortfolioType[]>('client/PortfolioTypes');
  }

  getConfig(): Observable<PortfolioTypeConfig> {
    return this.http.get<PortfolioTypeConfig>('client/PortfolioType/Config');
  }

  getRequiredLevelInformations(orderBy = false): Observable<PortfolioRequiredLevelInformation[]> {
    let params = new HttpParams();
    params = params.append('OrderByDescending', orderBy.toString());
    return this.http.get<PortfolioRequiredLevelInformation[]>('client/PortfolioType/getRequiredLevelInformations', { params });
  }
}
