import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '../app.reducers';
import { IDashboardState } from './dashboard.reducer';

export const selectDashboardState = createFeatureSelector<IAppState, IDashboardState>('dashboard');

export const selectDashboardData = createSelector(
  selectDashboardState,
  state => state.dashboardData,
);

export const selectFiltersList = createSelector(
  selectDashboardState,
  state => state.filtersList,
);

export const selectDepartmentUserActives = createSelector(
  selectDashboardState,
  state => state.userActivityData,
);

export const selectEmployeeUserActives = createSelector(
  selectDashboardState,
  state => state.employeeUserActivesData,
);

export const selectIsDashboardLoaded = createSelector(
  selectDashboardState,
  state => state.dashboardLoaded,
);
