export enum StringFormat {
  UpperCamelCase,
}

export class StringUtil {

  static formatCase(str: string, format: StringFormat): string {
    if (!str)
      return str;

    switch (format) {
      case StringFormat.UpperCamelCase:
        return str.replace(/\b(\S)/gi, (match) => match.toLocaleUpperCase())
                  .replace(/\s/gi, '');
      default:
        return str;
    }
  }

  static insertSpaceBeforeCapitalLetter(str: string): string {
    if (!str)
      return str;

    return str.replace(/([A-Z])/g, ' $1').trim();
  }

  static formatString(text: string, values?: (string|number)[]): string {
    if (!text) {
      return '';
    }

    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        text = text.replace(`\{${ i }\}`, values[i].toString());
      }
    }

    return text;
  }

  static phraseTitleCase(value: string): string {
    return value.charAt(0).toLocaleUpperCase() + value.substr(1).toLocaleLowerCase();
  }

}
