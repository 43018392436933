import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Localization } from '@shared/models/localization.model';
import { Observable } from 'rxjs';

@Injectable()
export class LocalizationHttpService {

  constructor(private http: HttpClient) { }

  getLocalizations(resourceKeys?: string[]): Observable<Localization[]> {
    let params = new HttpParams();

    if (resourceKeys) {
      resourceKeys.forEach(key => {
        params = params.append('ResourceKeys', key);
      });
    }

    return this.http.get<Localization[]>('client/Localizations', { params });
  }

}
