import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { FormAccessService } from '@core/services/form-access.service';
import { IAppState } from '@core/store/app.reducers';
import { selectPortfolioTypeId } from '@core/store/user-info/user-info.selectors';
import { select, Store } from '@ngrx/store';
import { appRoutes } from '@shared/enums/app-routes.enum';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { PortfolioTypeEnum } from '@shared/enums/portfolio-type.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from '@core/services/localization.service';

@Injectable()
export class PreventAlbedaGuard implements CanActivate {

  private readonly _cantViewForm = 'you_cant_view_this_form';

  constructor(
    private matSnackbar: MatSnackBar,
    private localizationService: LocalizationService,
    private router: Router,
    private store: Store<IAppState>) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(select(selectPortfolioTypeId))
      .pipe(
        filter(portfolioTypeId => !!portfolioTypeId),
        first(),
        map(portfolioTypeId => {
          if (portfolioTypeId === PortfolioTypeEnum.ENI) {
            const errorMsg = this.localizationService.getTranslationByKey(this._cantViewForm);
            this.matSnackbar.open(errorMsg, '', {
              panelClass: 'snackbar-error',
              duration: 5000,
            });

            if (this.router.url === '/') {
              return this.router.parseUrl(appRoutes.dashboard.fullPath);
            }

            return false;
          }

          return true;
        }),
      );
  }
}
