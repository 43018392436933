import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppVersionService } from '@core/services/app-version.service';
import { LocalizationService } from '@core/services/localization.service';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {

  constructor(
    private _appVersionService: AppVersionService,
    private _localizations: LocalizationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse && event.headers.has('ReleaseVersion')) {
            const serverVersion: string = event.headers.get('ReleaseVersion');
            this._appVersionService.checkAppVersionIfMatched(serverVersion);
          }
        }),
      );
  }

}
