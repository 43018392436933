<mat-card
  class="departments-content"
  [ngClass]="{
    'ai-implementation-block':
      (isTopDepartments$ | async) &&
      (isBottomDepartments$ | async) &&
      (isTopPerformers$ | async) &&
      (isBottomPerformers$ | async)
  }"
>
  <ng-container *ngIf="isTopDepartments$ | async">
    <p class="header">
      {{ "top_3_departments_this" | localize | uppercase }}
      {{ textFilterPeriodView | uppercase }}
    </p>
    <ng-container
      *ngIf="topDepartments$ | async as topDepartments; else loader"
    >
      <div *ngFor="let department of topDepartments; let i = index">
        <div fxLayout="row" fxLayoutAlign="start" class="block-department">
          <p fxLayoutAlign="center center">{{ i + 1 }}</p>
          <div fxLayoutAlign="center" class="info-department" fxLayout="column">
            <span class="title-block">{{
              department.departmentName || "-"
            }}</span>
            <span *ngIf="department.hours" class="info-block">
              {{ department.hours }}</span
            >
            <span *ngIf="!department.hours" class="info-block">{{
              "n_a" | localize
            }}</span>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isBottomDepartments$ | async">
    <div class="departments-block">
      <p class="header">
        {{ "bottom_3_departments_this" | localize | uppercase }}
        {{ textFilterPeriodView | uppercase }}
      </p>
      <ng-container *ngIf="bottomDepartments; else loader">
        <div *ngFor="let department of bottomDepartments; let i = index">
          <div fxLayout="row" fxLayoutAlign="start" class="block-department">
            <p class="bottom-index" fxLayoutAlign="center center">
              {{ i + 1 }}
            </p>
            <div class="info-department" fxLayout="column">
              <span class="title-block">{{
                department.departmentName || "-"
              }}</span>
              <span *ngIf="department.hours" class="info-block">{{
                department.hours
              }}</span>
              <span *ngIf="!department.hours" class="info-block">{{
                "n_a" | localize
              }}</span>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isTopPerformers$ | async">
    <div class="performers-block">
      <p class="header">
        {{ "top_3_employees_this" | localize | uppercase }}
        {{ textFilterPeriodView | uppercase }}
      </p>
      <ng-container *ngIf="topPerformersDisplayed; else loader">
        <div *ngFor="let performer of topPerformersDisplayed; let i = index">
          <div
            *ngIf="performer.fullName"
            fxLayout="row"
            fxLayoutAlign="start"
            class="block-department"
          >
            <p fxLayoutAlign="center center">{{ i + 1 }}</p>
            <div fxLayoutAlign="center center">
              <img
                *ngIf="!performer.imageUrl"
                src="assets/images/default-profile.png"
              />
              <img *ngIf="performer.imageUrl" [src]="performer.imageUrl" />
            </div>
            <div class="info-department" fxLayout="column">
              <span class="title-block"
                >{{
                  performer.fullName +
                    " " +
                    (performer.departmentName
                      ? "(" + performer.departmentName + ")"
                      : "")
                }}
              </span>
              <span *ngIf="performer.hours" class="info-block">{{
                performer.hours
              }}</span>
              <span *ngIf="!performer.hours" class="info-block">{{
                "n_a" | localize
              }}</span>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="isBottomPerformers$ | async">
    <div class="performers-block">
      <p class="header">
        {{ "bottom_3_performers_this" | localize | uppercase }}
        {{ textFilterPeriodView | uppercase }}
      </p>
      <ng-container *ngIf="bottomPerformers; else loader">
        <div *ngFor="let performer of bottomPerformers; let i = index">
          <div fxLayout="row" fxLayoutAlign="start" class="block-department">
            <p fxLayoutAlign="center center" class="bottom-index">
              {{ i + 1 }}
            </p>
            <div fxLayoutAlign="center center">
              <img
                *ngIf="!performer.imageUrl"
                src="assets/images/default-profile.png"
              />
              <img *ngIf="performer.imageUrl" [src]="performer.imageUrl" />
            </div>
            <div class="info-department" fxLayout="column">
              <span class="title-block">{{ performer.fullName }}</span>
              <span class="info-block">{{ performer.hours }}</span>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</mat-card>

<ng-template #loader>
  <div class="loader">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</ng-template>
