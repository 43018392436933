export const fIOP = 'i';
export const fPortfolioSettings = 'ps';
export const fDashboardFirstLevel = 'fl';
export const fDashboardSecondLevel = 'sl';
export const fDashboardThirdLevel = 'tl';
export const fDashboardFourthLevel = 'ftl';
export const fProfile = 'pf';
export const fAnalytics = 'a';
export const fStudentOverview = 'so';
export const fIndividualEpas = 'edie';
export const fTrainingProgram = 'edtp';
