import { MatDialogRef } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'xf-subform-dialog',
  templateUrl: './subform-dialog.component.html',
  styleUrls: ['./subform-dialog.component.scss'],
})
export class SubformDialogComponent {
  @Input()
  dialogTitle: string;

  constructor(private dialogRef: MatDialogRef<SubformDialogComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
