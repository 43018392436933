import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAppState } from '@core/store/app.reducers';
import { selectIsLocalizationLoaded } from '@core/store/langauge/language.selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'xf-download-app-dialog',
  templateUrl: './download-app-dialog.component.html',
  styleUrls: ['./download-app-dialog.component.scss'],
})
export class DownloadAppDialogComponent implements OnInit {
  isLocalizationLoaded$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private link: string,
    private _store: Store<IAppState>
  ) {}

  ngOnInit(): void {
    this.isLocalizationLoaded$ = this._store.pipe(
      select(selectIsLocalizationLoaded)
    );
  }

  onDownloadApp() {
    if (!!this.link) {
      window.location.href = this.link;
    }
  }
}
