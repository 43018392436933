import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'texttruncate',
})
export class TextTruncatePipe implements PipeTransform {

  transform(text: string, limit: number): string {

    if (!text) {
      return '';
    }

    return (text.length > limit) ? text.substr(0, limit - 3) + '...' : text;
  }

}
