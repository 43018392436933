<div class="container">
  <div class="main-content" fxLayout="column">
    <div class="header" fxLayoutAlign="start center">
      <ng-content select="epa-advance-dialog-header, [epa-advance-dialog-header], [advanceDialogHeader]"></ng-content>
    </div>
    <div class="content">
      <ng-content select="epa-advance-dialog-content, [epa-advance-dialog-content], [advanceDialogContent]"></ng-content>
    </div>
  </div>
  <div class="actions" fxLayout="column" fxLayoutAlign="start center">
    <ng-content select="epa-advance-dialog-actions, [epa-advance-dialog-actions], [advanceDialogActions]"></ng-content>
  </div>
</div>
