import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LocalizationService } from './localization.service';

@Injectable()
export class PaginatorTranslationService {

  private readonly itemsPerPageKey = 'items_per_page';
  private readonly nextPageKey = 'next_page';
  private readonly previousPageKey = 'prev_page';
  private readonly firstPageKey = 'first_page';
  private readonly lastPageKey = 'last_page';
  private readonly rangeLabel = 'of';

  constructor(
    private matPaginatorIntl: MatPaginatorIntl,
    private localizationService: LocalizationService,
  ) { }

  init() {
    this.matPaginatorIntl.itemsPerPageLabel = this.localizationService.getTranslationByKey(this.itemsPerPageKey);
    this.matPaginatorIntl.nextPageLabel = this.localizationService.getTranslationByKey(this.nextPageKey);
    this.matPaginatorIntl.previousPageLabel = this.localizationService.getTranslationByKey(this.previousPageKey);
    this.matPaginatorIntl.firstPageLabel = this.localizationService.getTranslationByKey(this.firstPageKey);
    this.matPaginatorIntl.lastPageLabel = this.localizationService.getTranslationByKey(this.lastPageKey);
    this.matPaginatorIntl.getRangeLabel = this.getRangeLabel;
    this.matPaginatorIntl.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const rangeLabel = this.localizationService.getTranslationByKey(this.rangeLabel);
    if (length === 0 || pageSize === 0) {
      return `0 ${rangeLabel} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} – ${endIndex} ${rangeLabel} ${length}`;
  }
}
