import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPortfolioType',
})
export class FormatPortfolioType implements PipeTransform {

  transform(data: any): string {
    if (data.length) {
      const result = data.map(e => {
        return e.name;
      }).join(' / ');

      return result;
    } else {
      return '-';
    }
  }
}
