<div class="container" fxLayoutAlign="center center">
  <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center">
    <xf-profile-photo
      size="100px"
      [fileUrl]="((viewModeByHT$ | async) === false ? profilePhotoUrl$ : viewModeByProfilePhotoUrl$) | async"
      [userName]="((viewModeByHT$ | async) === false ? userFullName$ : viewModeByName$) | async">
    </xf-profile-photo>
    <span fxFlexOffset="1em" class="fullname">{{ ((viewModeByHT$ | async) === false ? userFullName$ : viewModeByName$) | async }}</span>
    <span fxFlexOffset="3em">{{ 'user_idle_message' | localize }}</span>
<!--    <ng-container *ngIf="loginType === loginTypeEnum.SURFConext; else loginFormTemplate">-->
<!--      <div fxFlexOffset="1em">-->
<!--        <span><span class="surfconext-link" (click)="onSignInViaSurfConext()">{{ 'click_here' | localize }}</span> {{ 'for_verification_via_surfconext' | localize }}</span>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--    <ng-template #loginFormTemplate>-->
      <span fxFlexOffset="1em">{{ 'user_idle_confirmation' | localize }}</span>
      <form fxFlexOffset="1em" fxLayout="column" fxLayoutGap="2em" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <mat-card class="password mat-elevation-z2" fxLayout="column" fxLayoutAlign="center center">
          <mat-form-field>
            <mat-label>{{ 'password' | localize }}</mat-label>
            <input id="password" name="password" autocomplete="off" matInput formControlName="password" [type]="hide ? 'password' : 'text'" #passwordInput>
            <button mat-icon-button matSuffix type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" (click)="hide = !hide">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="password.errors?.required">{{ 'canmeds_are_required' | localize }}</mat-error>
          </mat-form-field>
        </mat-card>
        <button mat-raised-button color="white" type="submit" fxFlexAlign="center" [disabled]="isAuthLoading$ | async">
          <ng-container *ngIf="isAuthLoading$ | async; else loginText">
            <mat-spinner diameter="18"></mat-spinner>
          </ng-container>
          <ng-template #loginText>{{ 'ok' | localize }}</ng-template>
        </button>
      </form>
<!--    </ng-template>-->
    <div fxFlexOffset="2em" class="footnote">
      <span [innerHtml]="'user_idle_close_app_blocker_warning' | localize | safeHtml"></span>
    </div>
  </div>
  <ng-container *ngIf="authError$ | async as authError">
    <div fxFlexOffset="2em" class="auth-error mat-elevation-z3">
      <span>{{ authError }}</span>
    </div>
  </ng-container>
</div>
