import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable()
export class ApplicationInsightsService {
  private _appInsights: ApplicationInsights;

  constructor() {
    const { instrumentationKey, enableAutoRouteTracking } = environment.appInsights || {};
    if (instrumentationKey) {
      this._appInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          enableAutoRouteTracking,
        },
      });
      this._appInsights.loadAppInsights();
    }
  }

  setUserId(userId: string) {
    if (!this._appInsights) {
      return;
    }

    this._appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.clearAuthenticatedUserContext();
  }

  startNavigationEvent(url: string) {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.startTrackEvent(url);
  }

  endNavigationEvent(url: string) {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.stopTrackEvent(url, { type: 'PAGE LOAD TIME' });
  }

  logPageView(name?: string, uri?: string) {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.trackPageView({ name, uri });
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.trackMetric({ name, average }, properties);
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.trackEvent({ name }, properties);
  }

  logException(exception: Error, severityLevel?: SeverityLevel | number, properties?: { [key: string]: any }) {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.trackException({ exception, severityLevel, properties });
  }

  logTrace(message: string, severityLevel?: SeverityLevel, properties?: { [key: string]: any }) {
    if (!this._appInsights) {
      return;
    }
    this._appInsights.trackTrace({ message, severityLevel }, properties);
  }
}