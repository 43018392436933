import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayJoin',
})
export class ArrayJoinPipe implements PipeTransform {

  transform(value: string[], separator?: string): string {
    if (!Array.isArray(value)) {
      return value;
    }

    if (!separator) {
      separator = ', ';
    }

    return value.join(separator) || '-';
  }

}
