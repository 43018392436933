import { Injectable } from '@angular/core';
import { IUserInfoState } from '@core/store/user-info/user-info.reducer';
import { MemberStatusEnum } from '@shared/enums/member-status.enum';
import { UserRole } from '@shared/enums/user-role.enum';
import { UserInfo } from '@shared/models/user-info.model';

@Injectable()
export class IopAccessService {
  canEditIop(userInfo: IUserInfoState | UserInfo): boolean {
    const isUserRoleStudent = userInfo.roleId === UserRole.Student;
    const isMemberStatusCancelled = userInfo.groupInfo.memberStatus === MemberStatusEnum.Cancelled;
    const hasTrainingProgram = userInfo.groupInfo.hasTrainingProgram;

    return !(isMemberStatusCancelled
      || (isUserRoleStudent && !userInfo.viewMode && !hasTrainingProgram)
      || (isUserRoleStudent && userInfo.viewMode && (userInfo.viewModeBy === 'Teacher' || userInfo.viewModeBy === 'HeadTeacher')));
  }

  canEditTrainingProgram(userInfo: IUserInfoState | UserInfo): boolean {
    return userInfo.roleId === UserRole.Student && userInfo.viewMode && userInfo.viewModeBy === 'HeadTeacher';
  }

  canEditInternshipSchedule(userInfo: IUserInfoState | UserInfo): boolean {
    return userInfo.roleId === UserRole.Student && userInfo.viewMode && userInfo.viewModeBy === 'HeadTeacher'
      || userInfo.roleId === UserRole.Student && !userInfo.viewMode;
  }
}
