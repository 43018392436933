import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { LanguageHttpService } from '../../https/language-http.service';
import { getLanguages, getLanguagesSuccess } from './language.actions';

@Injectable()
export class LanguageEffects {

  getLanguages$ = createEffect(() => this.actions$.pipe(
    ofType(getLanguages),
    concatMap(() => this.languageHttpService.getAllLanguages()
      .pipe(
        map(languages => getLanguagesSuccess({ languages })),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private languageHttpService: LanguageHttpService) { }

}
