export const environment = {
  production: true,
  rethrowErrors: false,
  appInsights: {
    instrumentationKey: 'fbdd7863-7e73-485f-8f74-a2bceb5c1f4c', // Azure Portal: epa-ui
    enableAutoRouteTracking: true,
  },
  cache: {
    enabled: true,
    httpRequestCachingEnabled: true,
    manualCaching: false,
    keepAlive: false,
    expiry: 1000 * 60 * 1, // 1 minute
    expiryMonitorIntervalInSeconds: 30, // 30 sec
  },
  idle: {
    autoLogoutEnabled: true,
    maxInSeconds: 300,
    forcedReloginAfterNoResponseEnabled: true,
    forcedReloginAfterNoResponseInSeconds: 1,
  },
  browserSessionSettings: {
    logoutOnClose: true,
  },
  baseUrl: 'https://epa-ui-business.azurewebsites.net/',
  apiUrl: 'https://epa-server-business.azurewebsites.net/api/',
  signalRUrl: 'https://epa-server-business.azurewebsites.net/notifications/',
  surfConextUrl:
    'https://epa-server-business.azurewebsites.net/api/client/Accounts/SurfConextLogin',
  hubSpotScriptUrl: 'https://js.hs-scripts.com/25206376.js',
  froalaApiKey:
    'GPD2tA17A3A3D3D1C2sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4G1B1B8D7A6==',
  indexedDb: {
    name: 'EpaDb',
  },
  videoTutorials: {
    internshipPlannerScheduleURL:
      'https://epastorageprod.blob.core.windows.net/epa-form-videos/Stageplanner%20Eindversie%20HD.mp4?sv=2020-04-08&st=2021-08-02T10%3A59%3A52Z&se=2027-04-03T10%3A59%3A00Z&sr=b&sp=r&sig=VX3WD3mR%2Ftbb2Flk7gWCiuQcvFkBdTAm8qjyTF6QYIo%3D',
    groupManagement:
      'https://epastorageprod.blob.core.windows.net/epa-form-videos/Gebruikersrollen%20Eindversie%20HD.mp4?sv=2020-04-08&st=2021-08-02T10%3A50%3A34Z&se=2027-08-03T10%3A50%3A00Z&sr=b&sp=r&sig=lIBFdxnbCm5VVlM07fqkqD0ndcJlbEKijxErbngc9bs%3D',
  },
  versionCheckLogoutTimeInSeconds: 20,
  prodBaseUrl: 'https://www.xpertfolio.ai/',
};
