<xf-base-dialog
  dialogTitle="{{ 'system_notification' | localize }}">
  <mat-dialog-content>
    <div fxFlex="100" fxLayoutAlign="center center">
      <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2em">
        <span [innerHTML]="'signed_out_with_unsaved_data' | localize | safeHtml"></span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="center center">
    <button
      mat-dialog-close
      mat-raised-button
      color="primary"
      type="button">
      {{ 'close' | localize }}
    </button>
  </mat-dialog-actions>
</xf-base-dialog>
