import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IAppState } from '../store/app.reducers';
import { selectIsLocalizationLoaded } from '../store/langauge/language.selectors';

@Injectable()
export class LocalizationGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<IAppState>) { }

  canActivate(): Observable<boolean> {
    return this.store
      .pipe(
        select(selectIsLocalizationLoaded),
        filter(loaded => !!loaded),
      );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

}
