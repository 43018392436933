import { Injectable } from '@angular/core';
import { FilterOption } from '@shared/models/filters-list.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  private isDepartmentChangedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isEmployeeCombinationsSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isDashboardSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private departmentIdSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private periodOptionIdSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private filterPeriodViewSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  private isHiddenLoaderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private viewIdSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private quarterMonthSelectedSubject: BehaviorSubject<FilterOption> =
    new BehaviorSubject<FilterOption>(null);

  showDepartmentsOnly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showAllFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isHiddenLoaderSubject$: Observable<boolean> =
    this.isHiddenLoaderSubject.asObservable();
  isEmployeeCombinations$: Observable<boolean> =
    this.isEmployeeCombinationsSubject.asObservable();
  isDashboard$: Observable<boolean> = this.isDashboardSubject.asObservable();

  departmentId$: Observable<number> = this.departmentIdSubject.asObservable();
  periodOptionId$: Observable<number> =
    this.periodOptionIdSubject.asObservable();

  filterPeriodView$: Observable<string> =
    this.filterPeriodViewSubject.asObservable();

  isDepartmentChanged$: Observable<boolean> =
    this.isDepartmentChangedSubject.asObservable();

  viewId$: Observable<number> = this.viewIdSubject.asObservable();
  quarterMonthSelected$: Observable<FilterOption> = this.quarterMonthSelectedSubject.asObservable();

  updateIsEmployeeCombinations(isEmployeeCombinations: boolean): void {
    this.isEmployeeCombinationsSubject.next(isEmployeeCombinations);
    this.isDashboardSubject.next(false);
  }

  updateIsHiddenLoader(IsHiddenLoader: boolean): void {
    this.isHiddenLoaderSubject.next(IsHiddenLoader);
  }
  updateIsDashboard(isDashboard: boolean): void {
    this.isDashboardSubject.next(isDashboard);
    this.isEmployeeCombinationsSubject.next(false);
  }

  updateDepartmentId(
    departmentId: number,
    isNotUpdatePeriodOptionId?: boolean
  ): void {
    if (isNotUpdatePeriodOptionId) {
      return;
    }
    this.departmentIdSubject.next(departmentId);
  }

  updatePeriodOptionId(periodOptionId: number): void {
    this.periodOptionIdSubject.next(periodOptionId);
  }

  updateFilterPeriodView(filterPeriodView: string): void {
    this.filterPeriodViewSubject.next(filterPeriodView);
  }

  updateIsDepartmentChanged(isDepartmentChanged: boolean): void {
    this.isDepartmentChangedSubject.next(isDepartmentChanged);
  }

  showDepartmentsOnly(departmentsOnly: boolean) {
    this.showDepartmentsOnly$.next(departmentsOnly);
  }

  showAllFilters(showAll: boolean) {
    this.showAllFilters$.next(showAll);
  }

  updateViewId(viewId: number): void {
    this.viewIdSubject.next(viewId);
  }

  updateQuarterMonthSelected(quarterMonthSelected: FilterOption): void {
    this.quarterMonthSelectedSubject.next(quarterMonthSelected);
  }
}
