<div class="sort-filter">
  <p class="title">ORDER</p>
  <div class="sort-filter-btn">
    <div *ngFor="let filter of sortFilterDisplayed">
      <a fxLayout="row" (click)="sortFilterClick(filter.id)">
        <mat-icon *ngIf="sortFilterSelected?.id === filter.id">done</mat-icon>
        <mat-icon
          class="empty-check"
          *ngIf="sortFilterSelected?.id != filter.id"
        >
          check_box_outline_blank
        </mat-icon>
        <span class="label-name">{{ filter.label }}</span>
      </a>
    </div>
  </div>
</div>
