<a [routerLink]="'/user-activity'" [ngClass]="{ 'hover-non': isUserActivity }">
  <mat-card
    class="block-activity"
    [ngClass]="{ 'user-activity-details': isUserActivity }"
    fxLayout="column"
    fxLayoutAlign="space-between"
  >
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span
        class="title-block"
        [ngClass]="{ 'title-block-user-activity': isUserActivity }"
      >
        {{ "user_activity" | localize }}
      </span>
      <div fxLayoutAlign="center">
        <div class="icon-round">
          <mat-icon class="icon-person">person_outline</mat-icon>
        </div>
      </div>
    </div>
    <div
      fxLayout="row"
      class="main-block"
      [ngClass]="{ 'main-block-user-activity': isUserActivity }"
    >
      <ng-container
        *ngIf="
          totalPercentageUserActivity$ | async as totalPercentageUserActivity;
          else loader
        "
      >
        <ng-container
          *ngIf="
            containsPositiveOrNegative(totalPercentageUserActivity);
            else default
          "
        >
          <mat-icon
            class="percentage icon-percentage"
            [class]="classCheck(totalPercentageUserActivity)"
          >
            {{ iconCheck(totalPercentageUserActivity) }}
          </mat-icon>
          <span
            class="percentage"
            [class]="classCheck(totalPercentageUserActivity)"
          >
            {{ totalPercentageUserActivity }}
          </span>
        </ng-container>
        <ng-template #default>
          {{ totalPercentageUserActivity }}
        </ng-template>
      </ng-container>
    </div>

    <p [ngClass]="{ 'text-user-activity': isUserActivity }">
      {{ "compared_to_last" | localize }} {{ textFilterPeriodView }}
    </p>
    <div *ngIf="!isUserActivity">
      <div class="line"></div>
      <div class="box-details">
        <span class="details">{{
          "see_more_details" | localize | uppercase
        }}</span>
        <span class="next">></span>
      </div>
    </div>
  </mat-card>
</a>

<ng-template #loader>
  <div class="loader">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
</ng-template>
