import {
  getPortfolioTypeConfig,
  getPortfolioTypeConfigError,
  getPortfolioTypeConfigSuccess
} from '@core/store/portfolio-type-config/portfolio-type-config.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface IPortfolioTypeConfigState {
  loaded: boolean;
  loading: boolean;
  showAnalyticYearMatrix: boolean;
  showReferenceCompetencyLevel: boolean;
  showReferenceCurrentLevel: boolean;
  portfolioTypeId: number;
  forms: Forms[];
  pages: Pages[];
  requiredLevels: PortfolioRequiredLevelInformation[];
}

export class PortfolioRequiredLevelInformation {
  requiredLevel: number;
  key: string;
  description: string;
  color: string;
  tooltip: string;
}

export interface Forms {
  name: string;
  items: FormItems[];
}

export interface FormItems {
  formId: number;
  name: string;
  shortName: string;
  description: string;
  hasLinkedEpa: boolean;
}

export interface Pages {
  groupName: string;
  items: PageItems[];
}

export interface PageItems {
  name: string;
  url: string;
}

const initialPortfolioTypeConfigState: IPortfolioTypeConfigState = {
  loaded: false,
  loading: false,
  showAnalyticYearMatrix: undefined,
  showReferenceCompetencyLevel: undefined,
  showReferenceCurrentLevel: undefined,
  portfolioTypeId: undefined,
  forms: undefined,
  pages: undefined,
  requiredLevels: undefined,
};

const reducer = createReducer(
  initialPortfolioTypeConfigState,
  on(getPortfolioTypeConfig, state => ({ ...state, loading: true })),
  on(getPortfolioTypeConfigSuccess, (state, action) => ({
    ...state, ...action.portfolioTypeConfig,
    loaded: true,
    loading: false,
  })),
  on(getPortfolioTypeConfigError, state => ({ ...state, loaded: false, loading: false })),
);

export function portfolioTypeConfigReducer(state: IPortfolioTypeConfigState, action: Action) {
  return reducer(state, action);
}
