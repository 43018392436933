import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProfileHttpService } from '@core/https/profile-http.service';
import { UserProfile } from '@shared/models/user-profile.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserProfileResolver implements Resolve<UserProfile> {

  constructor(private profileHttpService: ProfileHttpService) { }

  resolve(): Observable<UserProfile> {
    return this.profileHttpService.getUserProfile();
  }

}
