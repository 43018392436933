<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

  <div fxLayout="column" fxLayoutAlign="center center">
    <h2>{{ title }}</h2>
    <h3>{{ subTitle }}</h3>
  </div>

  <div fxLayout="row" fxLayoutGap="20px">
    <button
      mat-raised-button
      class="cancel-button"
      aria-label="Form cancel confirmation"
      (click)="onCancel()">

      {{ cancelButtonLabel }}
    </button>

    <button
      mat-raised-button
      color="primary"
      class="confirm-button"
      aria-label="Form confirmation"
      (click)="onConfirm()">

      {{ confirmButtonLabel }}
    </button>
  </div>

</div>
