import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, OnDestroy, OnInit, Type } from '@angular/core';
import { ComponentRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { Input } from '@angular/core';
import { Directive } from '@angular/core';
import { CustomTooltipComponent } from '../container/custom-tooltip.component';

@Directive({
  selector: 'epaCustomTooltip, [epaCustomTooltip], ' +
            'epaTooltipTemplate, [epaTooltipTemplate], ' +
            'epaTooltipOffsetY, [epaTooltipOffsetY], ' +
            'epaTooltipOffsetX, [epaTooltipOffsetX], ' +
            'epaTooltipOriginX, [epaTooltipOriginX], ' +
            'epaTooltipOriginY, [epaTooltipOriginY], ' +
            'epaTooltipOverlayX, [epaTooltipOverlayX], ' +
            'epaTooltipOverlayY, [epaTooltipOverlayY]',
})
export class CustomTooltipDirective implements OnInit, OnDestroy {

  @Input()
  showToolTip = true;

  @Input(`epaCustomTooltip`)
  text: string;

  @Input()
  epaTooltipTemplate: TemplateRef<any>;

  @Input()
  epaTooltipOriginX: 'end' | 'center' | 'start' = 'end';

  @Input()
  epaTooltipOriginY: 'center' | 'top' | 'bottom' = 'center';

  @Input()
  epaTooltipOverlayX: 'end' | 'center' | 'start' = 'start';

  @Input()
  epaTooltipOverlayY: 'center' | 'top' | 'bottom' = 'top';

  @Input()
  epaTooltipOffsetY = 0;

  @Input()
  epaTooltipOffsetX = 0;

  private _overlayRef: OverlayRef;

  constructor(private _overlay: Overlay,
              private _overlayPositionBuilder: OverlayPositionBuilder,
              private _elementRef: ElementRef) { }

  ngOnInit() {
    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([{
        originX: this.epaTooltipOriginX,
        originY: this.epaTooltipOriginY,
        overlayX: this.epaTooltipOverlayX,
        overlayY: this.epaTooltipOverlayY,
        offsetY: this.epaTooltipOffsetY,
        offsetX: this.epaTooltipOffsetX,
      }]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter')
  show() {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomTooltipComponent> =
        this._overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.epaTooltipTemplate;
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}