import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as translateEN from '../i18n/en-US.json';
import * as translateNL from '../i18n/nl-NL.json';

export enum Translation {
  English = 'en-US',
  Dutch = 'nl-NL',
}

export const TRANSLATIONS = {
  'en-US': translateEN,
  'nl-NL': translateNL,
};

export class TranslationCustomLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang].default);
  }
}
