import { EpaSubEpaOption } from './epa-subepa-dropdown.component';

export function flattenEpaSubEpas(dataList: EpaSubEpaOption[], level?: number): EpaSubEpaOption[] {
  if (!dataList) {
    return null;
  }

  if (dataList.length <= 0) {
    return null;
  }

  if (!level) {
    level = 0;
  }

  const items = dataList.reduce((p, x) => {
    const epaSubEpaId = level === 0 ? level + '-' + x.value : x.value;
    p.push({
      value: { linkedEPASubEPAId: epaSubEpaId.toString(), isEPA: level === 1, departmentId: x.departmentId },
      label: x.label,
      level,
      completed: x.completed,
      required: x.required,
      className: `level-${ level }`,
      disabled: level === 0,
    });

    const children = flattenEpaSubEpas(x.items, level + 1);
    if (children && children.length > 0) {
      p = p.concat(children);
    }

    return p;
  }, []);

  return items;
}
