import { Component, Directive, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Directive({
  selector:
    'xf-advance-dialog-header, [epa-advance-dialog-header], [advanceDialogHeader]',
  host: { class: 'epa-advance-dialog-header' },
})
export class AdvanceDialogHeaderDirective {}

@Directive({
  selector:
    'xf-advance-dialog-content, [epa-advance-dialog-content], [advanceDialogContent]',
  host: { class: 'epa-advance-dialog-content' },
})
export class AdvanceDialogContentDirective {}

@Directive({
  selector:
    'xf-advance-dialog-actions, [epa-advance-dialog-actions], [advanceDialogActions]',
  host: { class: 'epa-advance-dialog-actions' },
})
export class AdvanceDialogActionsDirective {}

@Component({
  selector: 'xf-dialog-container',
  templateUrl: './epa-dialog-container.component.html',
  styleUrls: ['./epa-dialog-container.component.scss'],
})
export class EpaDialogContainerComponent {
  @Output()
  closeDialog = new EventEmitter<boolean>();

  constructor(public dialog: MatDialogRef<EpaDialogContainerComponent>) {}
}
