<div class="search-container">
  <div
    class="search-form"
    [class.search-expanded]="isExpanded || isFocused || formControl.value"
    (click)="onClickSearchButton()"
    (mouseenter)="isExpanded = true"
    (mouseleave)="isExpanded = false"
    [@searchExpand]="
      isExpanded || isFocused || formControl.value ? 'shown' : 'hidden'
    "
  >
    <mat-form-field
      class="form-field-search"
      appearance="standard"
      fxFlex="100"
      [class.hide]="!isExpanded && !isFocused && !formControl.value"
    >
      <input
        matInput
        autocomplete="off"
        type="search"
        [placeholder]="placeholder"
        [formControl]="formControl"
        (focusin)="isFocused = true"
        (focusout)="isFocused = false"
        #searchInput
      />
    </mat-form-field>
    <mat-icon>search</mat-icon>
  </div>
</div>
