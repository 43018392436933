<ng-container *ngIf="(isLocalizationLoaded$ | async); else loading">
  <xf-base-dialog [dialogTitle]="'version_not_matched_title' | localize" [hideClose]="true">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutAlign="center center">
        <p>{{ 'version_not_matched_text_1' | localize }}</p>
        <p>{{ 'version_not_matched_text_2' | localize }}</p>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="2em">
          <button mat-raised-button color="primary" (click)="onLogout()">
            {{ 'logout' | localize }}&nbsp;(<small>{{ 'version_not_matched_restarting_in' | localize }}: <strong>{{ time }}</strong>s</small>)
          </button>
        </div>
      </div>
    </mat-dialog-content>
  </xf-base-dialog>
</ng-container>
<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
