import { DefaultDataServiceConfig } from '@ngrx/data';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  timeout: 60000,
};

export const dataServiceConfigProvider = {
  provide: DefaultDataServiceConfig,
  useValue: defaultDataServiceConfig,
};
