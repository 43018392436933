import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IUserAuth, IUserCredential } from '@regular-page-modules/auth/auth.model';

export const login = createAction(
  '[Auth login] Login',
  props<{ userCredential?: IUserCredential; surfconext?: string; loadRoute?: string; }>(),
);

export const loginSuccess = createAction(
  '[Auth login] Login success',
  props<{ userToken: IUserAuth; loadRoute?: string; fallbackRouteEnabled?: boolean; loginType?: 'regular' | 'surfconext'; }>(),
);

export const loginError = createAction(
  '[Auth login] Login error',
  props<{ httpError: HttpErrorResponse }>(),
);

export const logout = createAction(
  '[Auth logout] Logout',
  props<{
    reLogin?: boolean;
    userToken?: IUserAuth;
    loadRoute?: string;
    hardReloadAfter?: boolean;
    waitNavigationEnd?: boolean;
    queryParamsHandling?: 'merge' | 'preserve';
  }>(),
);

export const refreshToken = createAction(
  '[Auth refreshToken] Refresh token',
);

export const blockAppUsage = createAction(
  '[Auth blockAppUsage] BlockAppUsage',
);

export const semiLogout = createAction(
  '[Auth semiLogout] Semi logout',
);

export const reloginAfterIdle = createAction(
  '[Auth reloginAfterIdle] Relogin after idle',
  props<{ userCredential: IUserCredential }>(),
);

export const reloginAfterIdleSuccess = createAction(
  '[Auth reloginAfterIdleSuccess] Relogin after idle success',
  props<{ userToken: IUserAuth }>(),
);
