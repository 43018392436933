import {
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { GroupActionOption } from '@management-shared/components/admin-group-action-drop-down/admin-group-action-drop-down.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DropdownOption } from '../../models/dropdown-input-filter.model';
import { InputFilterComponent } from './input-filter/input-filter.component';

@Component({
  selector: 'xf-dropdown-input-filter',
  templateUrl: './dropdown-input-filter.component.html',
  styleUrls: ['./dropdown-input-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownInputFilterComponent),
      multi: true,
    },
  ],
})
export class DropdownInputFilterComponent
  implements OnChanges, OnInit, OnDestroy, ControlValueAccessor
{
  @Input()
  placeholder: string;

  @Input()
  inputFilterPlaceholder = 'search';

  @Input()
  hideFilter: boolean;

  @Input()
  hideFilterWithin = 5;

  @Input()
  reset: Observable<void>;

  @Input()
  multiple: boolean;

  @Input()
  required: boolean;

  @Input()
  dataList: DropdownOption[] = [];

  @Input()
  loading$: Observable<boolean>;

  @Input()
  queryOnFilter: boolean;

  @Input()
  sort = false;

  @Input()
  formControlName: string;

  // If true, Child options should be set in the "items" property
  @Input()
  hasChildOptions: boolean;

  @Input()
  groupSelection: boolean;

  @Input()
  isEpaOnly: boolean;

  @Input()
  appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'legacy';

  @Input()
  disabled: boolean;

  @Input()
  hasProfilePhoto: boolean;

  @Input()
  initialValue: any;

  @Input()
  showError: string;

  @Input()
  hasResetOption: boolean;

  @Output()
  filter = new EventEmitter<string>();

  @Output()
  request = new EventEmitter<void>();

  @Output()
  selectEPASubEPA = new EventEmitter<void>();

  @Output()
  selectionClose = new EventEmitter<number | number[]>();

  @Output()
  inputModelChange = new EventEmitter<GroupActionOption>();

  @ViewChild(InputFilterComponent)
  private _inputFilterComponent!: InputFilterComponent;

  @ViewChild('multiSelect')
  private _multiSelect!: MatSelect;

  inputFilterForm = new FormControl();
  dropdownSelectForm = new FormControl();

  isDisabled = false;
  hideFilterInternal = false;
  filteredItems$ = new BehaviorSubject<DropdownOption[]>([]);
  selectedItems: number | number[];
  selectedItemLabel: string;

  private _control: AbstractControl;
  private _isToggled: boolean;
  private _filteredItems: DropdownOption[];
  private _unsubscribe$ = new Subject<void>();

  /**
   * We need to flatten the array if the Data has inner options
   * for us to have a better and easier control in the template
   * and in logics
   *
   * We also need to implement the flattening with a native for loop
   * as we insert new properties in each option (isParent and className)
   */
  private static flattenDataList(dataList: DropdownOption[]): DropdownOption[] {
    const flattenedDataList = [];

    let pIndex = 0;
    for (pIndex; pIndex < dataList.length; pIndex++) {
      const pData = { ...dataList[pIndex] };
      const pChildren = pData.items;
      delete pData.items;

      flattenedDataList.push({
        ...pData,
        isParent: true,
        className: 'group-parent',
      });

      if (!pChildren) {
        continue;
      }

      let cIndex = 0;
      for (cIndex; cIndex < pChildren.length; cIndex++) {
        flattenedDataList.push({
          ...pChildren[cIndex],
          isParent: false,
          className: 'group-child',
        });
      }
    }

    return flattenedDataList;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get firstSelectedItem() {
    return this.dataList?.find((d) => d.value === this.selectedItems[0])?.label;
  }

  get selectedItemsLength() {
    if (Array.isArray(this.selectedItems)) {
      return this.selectedItems.length;
    }
    return 0;
  }

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dataList?.currentValue) {
      this.deselectRemovedOptions(changes);

      this.dataList = this.removeDuplicates(this.dataList);

      if (this.sort) {
        this.dataList.sort((optionA, optionB) => {
          if (optionA.label.toUpperCase() < optionB.label.toUpperCase()) {
            return -1;
          }

          if (optionA.label.toUpperCase() > optionB.label.toUpperCase()) {
            return 1;
          }

          return 0;
        });
      }

      if (this.hasChildOptions) {
        this.dataList = DropdownInputFilterComponent.flattenDataList(
          this.dataList
        );
      }

      if (!this.hideFilter && !this.queryOnFilter) {
        this.hideFilterInternal = this.dataList.length <= this.hideFilterWithin;
      }

      this._filteredItems = this.dataList;
      this.filteredItems$.next(this.dataList);
    }

    if (changes?.initialValue?.currentValue) {
      this.writeValue(this.initialValue);
    }

    if (
      changes?.disabled &&
      changes.disabled.currentValue !== changes.disabled.previousValue
    ) {
      this.setDisabledState(changes.disabled.currentValue);
    }

    if (changes?.showError?.currentValue) {
      this.dropdownSelectForm.setErrors({ customError: this.showError });
    }

    if (
      changes?.required &&
      changes.required.currentValue !== changes.required.previousValue
    ) {
      if (!!changes.required.currentValue) {
        this.dropdownSelectForm.setValidators(Validators.required);
        this.dropdownSelectForm.updateValueAndValidity();
      } else {
        this.dropdownSelectForm.clearValidators();
        this.dropdownSelectForm.updateValueAndValidity();
      }
    }
  }

  ngOnInit() {
    this.setControlMarkStateOptions();

    if (this.required) {
      this.dropdownSelectForm.setValidators(Validators.required);
    }

    if (this.multiple && this.reset) {
      this.reset.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
        this._multiSelect.options.forEach((option: MatOption) => {
          option.deselect();
        });
      });
    }

    this.inputFilterForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this._unsubscribe$))
      .subscribe((inputFilter) => {
        if (this.queryOnFilter && this._isToggled) {
          this.filter.emit(inputFilter);
        } else {
          this.filterList();
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  writeValue(newSelection: number | number[] | null | undefined) {
    if (newSelection === undefined || newSelection === null) {
      this.selectedItems = newSelection as undefined | null;

      this.dropdownSelectForm.patchValue(newSelection);
      this.onChange(newSelection);

      return;
    }

    if (this.multiple) {
      if (!this.selectedItems) {
        this.selectedItems = [];
      }

      const currentDropdownOpts = this._filteredItems || this.dataList;
      const unselectedItems = currentDropdownOpts
        .filter((f) => (newSelection as number[]).indexOf(f.value) < 0)
        .map((f) => f.value);

      this.selectedItems = Array.from(
        new Set(
          (this.selectedItems as number[]).concat(newSelection as number[])
        )
      );

      this.selectedItems = this.selectedItems.filter(
        (s) => unselectedItems.indexOf(s) < 0
      );
    } else {
      let selectedData;
      selectedData = this.dataList?.find(
        (x) => x.value === newSelection || x.value === newSelection.toString()
      );

      this.selectEPASubEPA.emit(selectedData);

      this.selectedItems = selectedData?.value;
      this.selectedItemLabel = selectedData?.label;
    }

    this.dropdownSelectForm.patchValue(this.selectedItems);
    this.onChange(this.selectedItems);
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.dropdownSelectForm.disable();
    } else {
      this.dropdownSelectForm.enable();
    }
  }

  onSelectToggle(isToggled: boolean) {
    this._isToggled = isToggled;

    if (isToggled) {
      this.onTouched();
      this.request.emit();
    } else {
      if (!this.hideFilterInternal) {
        this._inputFilterComponent.clear();
      }

      this.selectionClose.emit(this.selectedItems);
    }
  }

  onSelectionChange(matSelectChange: MatSelectChange) {
    this.writeValue(matSelectChange.value);
    this.inputModelChange.emit(matSelectChange.value);
  }

  onResetSelection() {
    this.writeValue(undefined);
  }

  filterList() {
    const searchedValue = this.inputFilterForm.value.toLocaleLowerCase();

    this._filteredItems = this.dataList
      ? this.dataList.filter(
          (item) => item.label.toLocaleLowerCase().indexOf(searchedValue) > -1
        )
      : [];
    this.filteredItems$.next(this._filteredItems);
  }

  private deselectRemovedOptions(changes: SimpleChanges) {
    const previous = changes.dataList.previousValue as DropdownOption[];
    if (previous) {
      const current = changes.dataList.currentValue as DropdownOption[];
      const missing = previous.filter(
        (x) => current.findIndex((y) => y.value === x.value) === -1
      );
      this._multiSelect.options.forEach((option: MatOption) => {
        if (missing.findIndex((x) => x.value === option.value) !== -1) {
          option.deselect();
        }
      });
    }
  }

  private removeDuplicates(dataList: DropdownOption[]): DropdownOption[] {
    return dataList?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );
  }

  /**
   * Changing Control State by listening to the parent Form
   *
   * NOTE: Make this as last resort. Make sure you understand how Form Control works
   *       and the Forms in EPA as one update of this method will affect all Forms
   *       that uses this CVA with different scenarios
   *
   */
  private setControlMarkStateOptions() {
    if (this.controlContainer && this.formControlName) {
      this._control = this.controlContainer.control.get(this.formControlName);

      this._control.markAsTouched = () => {
        this.dropdownSelectForm.markAsTouched();
      };

      this._control.markAsUntouched = () => {
        this.dropdownSelectForm.markAsUntouched();
      };

      // this._control.markAsDirty = () => {
      //   this.dropdownSelectForm.markAsDirty();
      // };
      //
      // this._control.markAsPristine = () => {
      //   this.dropdownSelectForm.markAsPristine();
      // };
      //
      // this._control.markAsPending = () => {
      //   this.dropdownSelectForm.markAsPending();
      // };
    }
  }
}
