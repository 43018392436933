import { Injectable } from '@angular/core';
import { DashboardTabLevel } from '@regular-page-modules/dashboard/models/dashboard-tab-level.model';
import { appRoutes } from '@shared/enums/app-routes.enum';
import { fDashboardFirstLevel, fDashboardFourthLevel, fDashboardSecondLevel, fDashboardThirdLevel } from '@shared/fragment-keys';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DashboardTabService {

  private _dashboardTabLevels$ = new BehaviorSubject<DashboardTabLevel>({ fl: 0 });

  setDashboardTabLevels(levels: DashboardTabLevel) {
    this._dashboardTabLevels$.next(levels);
  }

  getDashboardTabLevels(): Observable<DashboardTabLevel> {
    return this._dashboardTabLevels$.asObservable();
  }

  reset() {
    return this._dashboardTabLevels$.next({ fl: 0 });
  }

  firstLevelTabFragment(levelOne: number) {
    return `${ appRoutes.dashboard.fullPath }#${ fDashboardFirstLevel }=${ levelOne }`;
  }

  secondLevelTabFragment(levelOne: number, levelTwo: number) {
    return `${ appRoutes.dashboard.fullPath }#${ fDashboardFirstLevel }=${ levelOne }&${ fDashboardSecondLevel }=${ levelTwo }`;
  }

  thirdLevelTabFragment(levelOne: number, levelTwo: number, levelThree: number) {
    return `${ appRoutes.dashboard.fullPath }#${ fDashboardFirstLevel }=${ levelOne }&${ fDashboardSecondLevel }=${ levelTwo }&${ fDashboardThirdLevel }=${ levelThree }`;
  }

  fourthLevelTabFragment(levelOne: number, levelTwo: number, levelThree: number, levelFour: number) {
    return `${ appRoutes.dashboard.fullPath }
      #${ fDashboardFirstLevel }=${ levelOne }
      &${ fDashboardSecondLevel }=${ levelTwo }
      &${ fDashboardThirdLevel }=${ levelThree }
      &${ fDashboardFourthLevel }=${ levelFour }`.replace(/\s+/gm, '');
  }
}
