import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '../app.reducers';
import { IViewStudentPortfolioState } from './view-student-portfolio.reducer';

const selectViewStudentPortfolioState = createFeatureSelector<IAppState, IViewStudentPortfolioState>('viewStudentPortfolio');

export const selectViewStudentPortfolio = createSelector(
  selectViewStudentPortfolioState,
  state => state,
);

export const selectViewStudentPortfolioStudentId = createSelector(
  selectViewStudentPortfolioState,
  state => state.studentId,
);

export const selectIsInViewMode = createSelector(
  selectViewStudentPortfolioState,
  state => state.isInViewMode,
);

