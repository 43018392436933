export const FORMS = {
  1: {
    id: 1,
    name: 'KPB',
    routeName: 'kpb',
  },
  2: {
    id: 2,
    name: 'OSATS',
    routeName: 'osats',
  },
  3: {
    id: 3,
    name: 'Theoretical exam',
    routeName: 'theoretical-exam',
  },
  4: {
    id: 4,
    name: 'Procedure-registration',
    routeName: 'procedure-registration',
  },
  5: {
    id: 5,
    name: 'Certificate',
    routeName: 'certificate',
  },
  6: {
    id: 6,
    name: 'Case based discussion',
    routeName: 'case-based-discussion',
  },
  7: {
    id: 7,
    name: '360 Feedback',
    routeName: '360-feedback',
  },
  8: {
    id: 8,
    name: 'Self-reflection',
    routeName: 'self-reflection',
  },
  9: {
    id: 9,
    name: 'Periodic evaluation',
    routeName: 'periodic-evaluation',
  },
  10: {
    id: 10,
    name: 'OOG',
    routeName: 'oog',
  },
  12: {
    id: 12,
    name: 'Other assessments',
    routeName: 'other-assessment',
  },
  13: {
    id: 13,
    name: 'General assessments',
    routeName: 'general-assessment',
  },
};
