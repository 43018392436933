<ng-container *ngIf="isLoaded">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h1 *ngIf="(isDashboard$ | async)" class="title">
      {{ 'dashboard' | uppercase }}
    </h1>
    <div *ngIf="(showDepartmentsOnly$ | async) || (showAllFilters$ | async)" class="select-wrap">
      <mat-icon>workspaces</mat-icon>
      <mat-select
        class="mat-select-department"
        [(value)]="departmentSelected"
        (selectionChange)="departmentChanged($event)">
        <mat-option
          *ngFor="let department of departments"
          [value]="department.id">
          {{ department.name }}
        </mat-option>
      </mat-select>
    </div>
    <ng-container *ngIf="(showDepartmentsOnly$ | async) === false">
      <div class="select-wrap">
        <mat-icon>calendar_month</mat-icon>
        <mat-select
          class="mat-select-calendar"
          (selectionChange)="viewChanged($event)"
          [(value)]="viewSelected">
          <mat-option *ngFor="let view of periodViews" [value]="view.id">
            {{ view.name }}
          </mat-option>
        </mat-select>
      </div>
      <div>
        <mat-card class="next-prev-filter">
          <div
            *ngIf="quarterMonthsSelected"
            fxFlex="100"
            fxLayoutAlign="space-between center">
            <button
              class="btn-before"
              mat-raised-button
              [disabled]="disabledCheck(true, false)"
              [matTooltip]="'previous' | localize"
              (click)="prevClick()">
              <mat-icon>navigate_before</mat-icon>
            </button>
            <span class="label">{{ quarterMonthsSelected.name }}</span>
            <button
              class="btn-next"
              mat-raised-button
              [disabled]="disabledCheck(false, true)"
              [matTooltip]="'next' | localize"
              (click)="nextClick()">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
</ng-container>
