export class ObjectUtil {

  static isEmpty(obj: {}) {
    if (!obj) {
      return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  static isIterable(value: any): boolean {
    return Symbol.iterator in Object(value);
  }
}
