import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@core/utils/string.util';

@Pipe({
  name: 'splitString',
})
export class SplitStringPipe implements PipeTransform {

  transform(value: string): string {
    return StringUtil.insertSpaceBeforeCapitalLetter(value);
  }

}
