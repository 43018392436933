import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { RouterService } from '@core/services/router.service';

@Injectable()
export class PreventBrowserBackGuard implements CanActivate {

  constructor(private routerService: RouterService) {}

  canActivate(): boolean {
    const currentNavigation = this.routerService.currentNavigation;
    return currentNavigation?.navigationTrigger !== 'popstate';
  }
}
