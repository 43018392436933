<div fxLayoutAlign="center center" class="logo-round" [ngStyle]="{ 'width': size + 'px', 'height': size + 'px' }">
  <img
    alt="profile photo"
    loading="lazy"
    [src]="fileUrl ? fileUrl + '&width=' + size + '&height=' + size : alternateImageSrc"
    (load)="isLoading = false"
    (error)="onImageError($event)">
  <ng-container *ngIf="isLoading">
    <mat-spinner [diameter]="size"></mat-spinner>
  </ng-container>
</div>
