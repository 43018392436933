export const feedback360Routes = {
  review: {
    pathName: 'review',
    fullPath: '/review',
    title: '360 Feedback: Review',
  },
  summary: {
    pathName: 'summary',
    fullPath: '/summary',
    title: '360 Feedback: Summary',
  },
};
