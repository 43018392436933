export enum UserRole {
  Admin = 1,
  Authenticated = 2,
  Guest = 3,
  HeadTeacher = 4,
  Teacher = 5,
  Student = 6,
  Anonymous = 7,
}

export const userRolesTypes = {
  1: {
    id: 1,
    name: 'Admin',
    pathName: 'admin',
    translation: 'admin',
  },
  2: {
    id: 2,
    name: 'Authenticated',
    pathName: 'authenticated',
    translation: 'authenticated',
  },
  3: {
    id: 3,
    name: 'Guest',
    pathName: 'guest',
    translation: 'guest',
  },
  4: {
    id: 4,
    name: 'Manager',
    pathName: 'head-teacher',
    translation: 'head_teacher',
  },
  5: {
    id: 5,
    name: 'Assessor',
    pathName: 'teacher',
    translation: 'teacher',
  },
  6: {
    id: 6,
    name: 'Employee',
    pathName: 'student',
    translation: 'student',
  },
  7: {
    id: 7,
    name: 'Anonymous',
    pathName: 'anonymous',
    translation: 'anonymous',
  },
};

export function isUserRoleNotStudent(userRoleId: UserRole): boolean {
  return userRoleId === UserRole.HeadTeacher ||
    userRoleId === UserRole.Teacher ||
    userRoleId === UserRole.Anonymous ||
    userRoleId === UserRole.Admin;
}
