import { Component, Input, OnInit } from '@angular/core';
import { FiltersService } from '@core/services/filters.service';
import { IAppState } from '@core/store/app.reducers';
import { selectDashboardData } from '@core/store/dashboard/dashboard.selectors';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'xf-user-activity-card',
  templateUrl: './user-activity-card.component.html',
  styleUrls: ['./user-activity-card.component.scss'],
})
export class UserActivityCardComponent implements OnInit {
  @Input() isUserActivity: boolean = false;

  totalPercentageUserActivity$: Observable<string>;
  textFilterPeriodView: string;

  constructor(
    private store: Store<IAppState>,
    private filtersService: FiltersService
  ) {}

  ngOnInit() {
    this.filtersService.filterPeriodView$.subscribe((filter) => {
      this.textFilterPeriodView = filter;
    });

    this.totalPercentageUserActivity$ = this.store
      .pipe(select(selectDashboardData))
      .pipe(map((data) => data?.totalPercentageUserActivity));
  }

  classCheck(value: string) {
    return value?.includes('+') ? 'increase' : 'decrease';
  }

  iconCheck(value: string) {
    return value?.includes('+') ? 'trending_up' : 'trending_down';
  }

  containsPositiveOrNegative(value: string) {
    const firstChar = value.charAt(0);
    return firstChar === '+' || firstChar === '-';
  }
}
