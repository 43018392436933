export const somethingWentWrongInTheServerKey = 'something_went_wrong_in_the_server';
export const reloadPageKey = 'reload_page';
export const requestSentSuccessfullyKey = 'request_sent_successfully';
export const somethingWentWrong = 'something_went_wrong_2';
export const unauthorized = 'unauthorized';
export const allowedJpgPngGifOnly = 'allowed_jpg_png_gif_only';
export const profileUpdateSuccessfully = 'profile_update_successful';
export const passwordChangedSuccessfully = 'password_changed_successfully';
export const requiredFormSubmissionAddedKey = 'required_submission_successfully_added';
export const requiredLevelUpdatedKey = 'required_level_updated';
export const minimumRequiredCountUpdatedKey = 'minimum_required_count_updated';
export const formSubtypeAddedKey = 'form_subtype_added';
export const formSubtypeRemovedKey = 'form_subtype_removed';
export const requiredFormSubmissionRemovedKey = 'required_form_submission_removed';
export const trainingDurationSavedKey = 'training_program_duration_has_been_saved';
export const epaProgressLevels = 'epa_progress_levels';
export const epaProgressAllLevels = 'epa_progress_all_levels';
export const addNewStudentAuthorizationKey = 'add_new_student_authorization';
export const addNewResidentAuthorizationKey = 'add_new_resident_authorization';
export const addNewPioAuthorizationKey = 'add_new_pio_authorization';
export const selectStudentsKey = 'select_students';
export const selectResidentKey = 'select_resident';
export const selectPioKey = 'select_pio';
export const addNewTeacherAuthorizationKey = 'add_new_teacher_authorization';
export const selectTeachersKey = 'select_teachers';
export const requestLinkToUsersKey = 'request_link_sent_to_users';
export const noLinkedStudentsKey = 'no_linked_students';
export const noLinkedResidentKey = 'no_linked_resident';
export const noLinkedPioKey = 'no_linked_pios';
export const studentsNameKey = 'students_name';
export const residentNameKey = 'resident_name';
export const pioNameKey = 'pio_name';
export const yourLinkedStudentsKey = 'your_linked_students';
export const yourLinkedResidentKey = 'your_linked_resident';
export const yourLinkedPioKey = 'your_linked_pio';
export const removeStudentsKey = 'remove_student';
export const removeResidentKey = 'remove_resident';
export const removePioKey = 'remove_pio';
export const removeStudentConfirmationKey = 'remove_student_confirmation';
export const removeResidentConfirmationKey = 'remove_resident_confirmation';
export const removePioConfirmationKey = 'remove_pio_confirmation';
export const removeStudentConfirmation2Key = 'remove_student_confirmation_2';
export const removeResidentConfirmation2Key = 'remove_resident_confirmation_2';
export const removePioConfirmation2Key = 'remove_pio_confirmation_2';
export const noLinkedTeachersKey = 'no_linked_teachers';
export const teachersNameKey = 'teachers_name';
export const yourLinkedTeachersKey = 'your_linked_teachers';
export const removeTeachersKey = 'remove_teacher';
export const removeTeacherConfirmationKey = 'remove_teacher_confirmation';
export const removeTeacherConfirmation2Key = 'remove_teacher_confirmation_2';
export const authorizationRemovedKey = 'authorization_removed';
export const groupKey = 'group';
export const groupAuthorizationInfoKey = 'group_authorization_info';
export const individualKey = 'individual';
export const individualAuthorizationInfoKey = 'individual_authorization_info';
export const resendRequestLinkToUserKey = 'resend_request_link_to_user';
export const specificationAddKey = 'specification_successfully_added';
export const specificationRemovedKey = 'specification_successfully_removed';
export const notAccessibleForThisUser = 'url_not_accessible_for_this_user';
export const dateRangeToKey = 'date_range_to';
export const membershipAcceptedKey = 'membership_successfully_accepted';
export const membershipRejectedKey = 'membership_successfully_rejected';
export const tooltipDisabledFeature1Key = 'tooltip_disabled_feature_1';
export const tooltipDisabledFeature2Key = 'tooltip_disabled_feature_2';
export const tooltipDisabledFeature3Key = 'tooltip_disabled_feature_3';
export const tooltipDisabledFeature4Key = 'tooltip_disabled_feature_4';
export const elapsedToday = 'elapsed_today';
export const daysAgo = 'days_ago';
export const dayAgo = 'day_ago';
export const tooltipNoInternshipsKey = 'moo_disabled_form_title_internship';
export const formDeletedKey = 'form_deleted';
export const formDeletedMessageKey = 'form_deleted_message';
export const submissionResent = 'resend_submission_successful';
export const submissionResentFailed = 'failed_to_re_send_form';
export const emailWithConfirmationToNewEmail = 'email_with_confirmation_to_new_email';
export const other = 'other';
export const submissionDeclinedSuccessfully = 'decline_successfully';
export const addAuthorizationsKey = 'add_authorizations';
export const addStudentEpaSuccess = 'add_student_epa_success';
export const deleteStudentEpaSuccess = 'delete_student_epa_success';
export const epaHasBeenAddedKey = 'epa_has_been_added';
export const subEpaHasBeenAddedKey = 'sub_epa_has_been_added';
export const requestAuthorizationsKey = 'request_authorizations';
export const assessorRemoved = 'assessor_removed_message';
export const hasBeenAddedKey = 'has_been_added';
export const hasBeenUpdatedKey = 'has_been_updated';
export const hasBeenDisabledKey = 'has_been_disabled';
export const hasBeenEnabledKey = 'has_been_enabled';
export const hasBeenRemovedKey = 'has_been_removed';
export const xHasBeenArchivedKey = 'x_has_been_archived';
export const paragraphHasBeenAddedKey = 'paragraph_has_been_added';
export const paragraphHasBeenUpdatedKey = 'paragraph_has_been_updated';
export const paragraphHasBeenEnabledKey = 'paragraph_has_been_enabled';
export const paragraphHasBeenDisabledKey = 'paragraph_has_been_disabled';
export const subjectHasBeenUpdatedKey = 'subject_has_been_updated';
export const countHasBeenUpdatedKey = 'count_has_been_updated';
export const confirmDisableXKey = 'confirm_disable_x';
export const confirmDisableMessageXKey = 'confirm_disable_message_x';
export const xHasBeenDisabledKey = 'x_has_been_disabled';
export const xHasBeenEnabledKey = 'x_has_been_enabled';
export const tabKey = 'tab';
export const disableKey = 'disable';
export const noBackKey = 'no_back';
export const linkedSubEpasKey = 'linked_sub_epas';
export const trainingProgramKey = 'training_program';
export const requiredSubmissionsKey = 'required_submissions';
export const levelOfCompetenceKey = 'level_of_competence';
export const nameKey = 'name';
export const idKey = 'id';
export const optionFilterIsEqualToKey = 'is_equal_to';
export const optionFilterIsNotEqualToKey = 'is_not_equal_to';
export const optionFilterStartsWithKey = 'starts_with';
export const optionFilterContainsKey = 'contains';
export const optionFilterDoesNotContainKey = 'does_not_contain';
export const optionFilterEndsWithKey = 'ends_with';
export const tabNameKey = 'tab_name';
export const paragraphKey = 'paragraph';
export const formKey = 'form';
export const countKey = 'count';
export const epaNameKey = 'epa_name';
export const subEpaNameKey = 'sub_epa_name';
export const sortIndexKey = 'sort_index';
export const epasKey = 'epas';
export const epaProgressUploadsKey = 'epa_progress_uploads';
export const levelsHaveBeenUpdatedKey = 'levels_has_been_updated';
export const orderHasBeenUpdatedKey = 'order_has_been_updated';
export const epaIdKey = 'epa_id';
export const xHasBeenDeleted = 'x_has_been_deleted';
export const deleteXKey = 'delete_x';
export const requestSentToDeleteEpaXKey = 'request_sent_to_delete_epa_x';
export const requestSentToAddEpaXKey = 'request_sent_to_add_epa_x';
export const epaHasBeenSuccessfullyArchived = 'successfully_archive';
export const epaHasBeenSuccessfullyUnArchived = 'successfully_unarchive';
export const dateKey = 'date';
export const newPortfolioGroupKey = 'new_portfolio_group';
export const portfolioGroupWithNameKey = 'portfolio_group_with_name';
export const addRequestedEpaSuccessMessage = 'add_requested_epa_success_message';
export const removeRequestedEpaSuccessMessage = 'remove_requested_epa_success_message';
export const requestAddMultipleEpa = 'request_add_multiple_epa';
export const requestAddEpa = 'request_add_epa';
export const portfolioTypeKey = 'portfolio_type';
export const specialityNameKey = 'speciality_name';
export const epaNameKeyKey = 'epa_name_key';
export const requiredSubmissionesSuccessMessageKey = 'required_submission_success_message';
export const requiredSubmissionesResetSuccessMessageKey = 'required_submission_reset_success_message';
export const requestChangesSuccessMessageKey = 'request_changes_success_message';
export const bannerNotificatioSuccessMessageKey = 'banner_notification_success_message_key';
export const epaCreationManagerEpaAddedSuccessfully = 'epa_creation_added_succesfully';
export const epaCreationEpaAddedSuccessfully = 'epa_creation_added_succesfully';
