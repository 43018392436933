import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProfilePhotoComponent } from './container/profile-photo.component';

const components = [
  ProfilePhotoComponent,
];

const materials = [
  MatProgressSpinnerModule,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    ...materials,
    CommonModule,
    FlexLayoutModule,
  ],
  exports: [
    ProfilePhotoComponent,
  ],
})
export class ProfilePhotoModule { }
