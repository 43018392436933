import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { AuthService } from '@core/services/auth.service';
import { TokenExpiryService } from '@core/services/token-expiry.service';
import { logout } from '@core/store/auth/auth.actions';
import { environment } from '@env/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppBlockerService } from '@shared-modules/app-blocker/services/app-blocker.service';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { IAppState } from '../app.reducers';
import {
  switchBackToOwnPortfolio,
  viewStudentPortfolio,
  viewStudentPortfolioAfterIdle,
  viewStudentPortfolioAfterIdleSuccess,
  viewStudentPortfolioError,
  viewStudentPortfolioSuccess
} from './view-student-portfolio.actions';
import { AccountHttpService } from '@core/https/account-http.service';

@Injectable()
export class ViewStudentPortfolioEffects {
  viewStudentPortfolio$ = createEffect(() => this.actions$.pipe(
    ofType(viewStudentPortfolio),
    concatMap(({ studentId, redirectPath }) => this.accountHttpService.generateTokenStudentMode(studentId)
      .pipe(
        tap(userToken => {
          this.applicationInsightsService.logEvent('View student portfolio', { StudentId: studentId });
          this.store.dispatch(logout({ reLogin: true, userToken, loadRoute: redirectPath }));
        }),
        map(() => viewStudentPortfolioSuccess()),
        catchError(httpError => of(viewStudentPortfolioError({ httpError }))),
      ),
    ),
  ));

  viewStudentPortfolioError$ = createEffect(() => this.actions$.pipe(
    ofType(viewStudentPortfolioError),
    tap(({ httpError }) => {
      const { error } = httpError;
      this.matSnackBar.open(error.title, '', { duration: 5000, panelClass: 'snackbar-error' });
    }),
  ), { dispatch: false });

  viewStudentPortfolioAfterIdle$ = createEffect(() => this.actions$.pipe(
    ofType(viewStudentPortfolioAfterIdle),
    concatMap(({ studentId }) => this.accountHttpService.generateTokenStudentMode(studentId)
      .pipe(
        tap(() => this.applicationInsightsService.logEvent('View student portfolio', { StudentId: studentId })),
        map(userToken => viewStudentPortfolioAfterIdleSuccess({ userToken })),
        catchError(httpError => of(viewStudentPortfolioError({ httpError }))),
      ),
    ),
  ));

  viewStudentPortfolioAfterIdleSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(viewStudentPortfolioAfterIdleSuccess),
    tap(({ userToken }) => {
      this.authService.setUserTokenAndSession(userToken);
      this.tokenExpiryService.setExpiration(
        userToken.expiration,
        environment.idle.forcedReloginAfterNoResponseInSeconds * 1000,
      );

      this.appBlockerService.show(false);
    }),
  ), { dispatch: false });

  switchBackToOwnPortfolio$ = createEffect(() => this.actions$.pipe(
    ofType(switchBackToOwnPortfolio),
    concatMap(({ redirectPath }) => this.accountHttpService.generateTokenSwitchBackToOwnProfile()
      .pipe(
        tap(() => this.applicationInsightsService.logEvent('Back to own portfolio')),
        map(userToken => logout({ reLogin: true, userToken, loadRoute: redirectPath })),
        catchError(httpError => of(viewStudentPortfolioError({ httpError }))),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private tokenExpiryService: TokenExpiryService,
    private accountHttpService: AccountHttpService,
    private appBlockerService: AppBlockerService,
    private applicationInsightsService: ApplicationInsightsService,
    private store: Store<IAppState>,
    private matSnackBar: MatSnackBar) { }
}
