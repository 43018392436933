import { PortfolioTypeConfig } from '@client-shared-modules/models/portfolio-type-config.model';
import { createAction, props } from '@ngrx/store';

export const getPortfolioTypeConfig = createAction(
  '[App] Get portfolio type config',
);

export const getPortfolioTypeConfigSuccess = createAction(
  '[App] Get portfolio type config (success)',
  props<{ portfolioTypeConfig: PortfolioTypeConfig }>(),
);

export const getPortfolioTypeConfigError = createAction(
  '[App] Get portfolio config type (error)',
);
