import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { AuthService } from '@core/services/auth.service';
import { LocalizationService } from '@core/services/localization.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { appRoutes } from '@shared/enums/app-routes.enum';
import { UserRole } from '@shared/enums/user-role.enum';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { getCurrentInfo, getCurrentInfoError, getCurrentInfoSuccess } from './user-info.actions';

@Injectable()
export class UserInfoEffects {

  getCurrentInfo$ = createEffect(() => this.actions$.pipe(
    ofType(getCurrentInfo),
    concatMap(({ loadRoute, fallbackRouteEnabled }) => this.authService.getCurrentInfo()
      .pipe(
        map(userInfo => getCurrentInfoSuccess({ userInfo, loadRoute, fallbackRouteEnabled })),
        catchError(() => of(getCurrentInfoError())),
      ),
    ),
  ));

  getCurrentInfoSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getCurrentInfoSuccess),
    tap(({ userInfo, loadRoute, fallbackRouteEnabled }) => {
      this.authService.userId = userInfo.id;
      this.authService.portfolioTypeId = userInfo.portfolioTypeId;
      this.authService.setUserIdLS(userInfo.id);
      this.authService.setUserRoleIdLS(userInfo.roleId);
      this.applicationInsightsService.setUserId(userInfo.id.toString());
      this._localizationService.fetchAll();

      const userProfile = userInfo.userProfile;

      // Having no profile overrides any load route
      if (userProfile &&  userInfo.roleId !== UserRole.Anonymous  && (
          userInfo.groupInfo.memberStatus === 2 ||
          userProfile.firstName === '' ||
          userProfile.surName === '' ||
          userProfile.categoryId === 0)) {
          this.router.navigateByUrl(appRoutes.settings.fullPath);
      } else if (loadRoute) {
        this.router.navigateByUrl(loadRoute);
      } else if (fallbackRouteEnabled) {
        switch (userInfo.roleId) {
          case UserRole.Admin:
            this.router.navigate([appRoutes.userDatabase.fullPath]);
            break;
          case UserRole.HeadTeacher:
          case UserRole.Teacher:
          case UserRole.Student:
            this.router.navigate([appRoutes.dashboard.fullPath]);
            break;
        }
      }
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private applicationInsightsService: ApplicationInsightsService,
    private _localizationService: LocalizationService) { }

}
