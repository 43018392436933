import { Component, OnInit } from '@angular/core';
import { DashboardHttpService } from '@core/https/dashboard-http.service';
import { FiltersService } from '@core/services/filters.service';
import { LocalizationService } from '@core/services/localization.service';
import { UserActivityService } from '@regular-page-modules/user-activity/services/user-activity.service';
import {
  FilterDepartment,
  FilterOption,
  FilterPeriodView,
} from '@shared/models/filters-list.model';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'xf-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.scss'],
})
export class DashboardFiltersComponent implements OnInit {
  isEmployeeCombinations$: Observable<boolean>;
  isDashboard$: Observable<boolean>;
  showDepartmentsOnly$: Observable<boolean>;
  showAllFilters$: Observable<boolean>;

  departments: FilterDepartment[];
  periodViews: FilterPeriodView[];

  departmentSelected: number;
  viewSelected: number;
  quarterMonthsSelected: FilterOption;

  isLoaded: boolean;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private dashboardHttpService: DashboardHttpService,
    private filtersService: FiltersService,
    private userActivityService: UserActivityService,
    private _localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.isLoaded = false;
    this.filtersService.departmentId$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((id) => (this.departmentSelected = id));

    this.filtersService.viewId$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((id) => (this.viewSelected = id));

    this.filtersService.quarterMonthSelected$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(
        (quarterMonthSelected) =>
          (this.quarterMonthsSelected = quarterMonthSelected)
      );

    this.getFiltersListParameters();
    this.isEmployeeCombinations$ = this.filtersService.isEmployeeCombinations$;
    this.isDashboard$ = this.filtersService.isDashboard$;
    this.showDepartmentsOnly$ = this.filtersService.showDepartmentsOnly$;
    this.showAllFilters$ = this.filtersService.showAllFilters$;
  }

  getFiltersListParameters() {
    this.dashboardHttpService
      .getFiltersListParameters()
      .pipe(
        finalize(() => (this.isLoaded = true)),
        takeUntil(this._unsubscribe$)
      )
      .subscribe((res) => {
        const { departments, periodViews } = res || {};
        this.departments = [...departments];
        this.departments.unshift({
          id: 0,
          key: null,
          name: this._localizationService.getTranslationByKey(
            'all_departments'
          ),
        });

        this.periodViews = periodViews;
        this.departmentSelected =
          this.departmentSelected || this.departments[0]?.id;
        this.viewSelected = this.viewSelected || this.periodViews[1]?.id;
        this.quarterMonthsSelected =
          this.quarterMonthsSelected || this.periodViews[1]?.options[0];
        this.filtersService.updateDepartmentId(this.departmentSelected);
        this.filtersService.updatePeriodOptionId(
          this.quarterMonthsSelected?.id
        );
        this.filtersService.updateQuarterMonthSelected(
          this.quarterMonthsSelected
        );

        let periodView = this.periodViews.find(
          (p) => p.id === this.viewSelected
        );
        this.filtersService.updateFilterPeriodView(
          this.getTextSelectedFilter(periodView)
        );
      });
  }

  getViewOptions() {
    return this.periodViews.find((view) => view.id === this.viewSelected)
      ?.options;
  }

  departmentChanged($event) {
    const { value } = $event;
    const selectedDepartment = this.departments?.find((d) => d.id === value);
    this.userActivityService.updateDepartmentName(selectedDepartment.name);
    this.filtersService.updateIsDepartmentChanged(true);

    if (value === 0) {
      this.viewSelected = this.periodViews[1]?.id;
      this.quarterMonthsSelected = this.periodViews[1]?.options[0];

      this.userActivityService.updateDepartmentIds(
        this.departments.filter((d) => d.id != 0).map((d) => d.id)
      );
    } else {
      this.viewSelected = this.periodViews[0]?.id;
      this.quarterMonthsSelected = this.periodViews[0]?.options[0];
      this.userActivityService.updateDepartmentIds([]);
    }
    const periodView = this.periodViews.find(
      (view) => view.id === this.viewSelected
    );
    this.filtersService.updateFilterPeriodView(
      this.getTextSelectedFilter(periodView)
    );
    if (this.quarterMonthsSelected?.id) {
      this.filtersService.updatePeriodOptionId(this.quarterMonthsSelected?.id);
      this.filtersService.updateDepartmentId(value);

      this.filtersService.updateIsDepartmentChanged(false);
    }

    this.filtersService.updateViewId(this.viewSelected);
    this.filtersService.updateQuarterMonthSelected(this.quarterMonthsSelected);
  }

  viewChanged($event) {
    const { value } = $event;
    let periodView = this.periodViews.find((view) => view.id === value);
    this.quarterMonthsSelected = periodView?.options[0];
    this.filtersService.updatePeriodOptionId(this.quarterMonthsSelected?.id);

    this.filtersService.updateFilterPeriodView(
      this.getTextSelectedFilter(periodView)
    );

    this.filtersService.updateViewId(value);
    this.filtersService.updateQuarterMonthSelected(this.quarterMonthsSelected);
  }

  getTextSelectedFilter(periodView: FilterPeriodView): string {
    let splitTextFilter = periodView.name.split(' ');
    let res = splitTextFilter[0].toLocaleLowerCase();
    return res;
  }

  nextClick() {
    for (let i = 0; i < this.getViewOptions().length - 1; i++) {
      if (
        this.quarterMonthsSelected.id === this.getViewOptions()[i].id &&
        this.quarterMonthsSelected.id !==
          this.getViewOptions()[this.getViewOptions().length - 1].id
      ) {
        this.quarterMonthsSelected = this.getViewOptions()[i + 1];
        this.filtersService.updatePeriodOptionId(
          this.quarterMonthsSelected?.id
        );
        this.filtersService.updateQuarterMonthSelected(
          this.quarterMonthsSelected
        );
        return;
      }
    }
  }

  prevClick() {
    for (let i = 0; this.getViewOptions().length - 1; i++) {
      if (
        this.quarterMonthsSelected.id === this.getViewOptions()[i].id &&
        this.quarterMonthsSelected.id != this.getViewOptions()[0].id
      ) {
        this.quarterMonthsSelected = this.getViewOptions()[i - 1];

        this.filtersService.updatePeriodOptionId(
          this.quarterMonthsSelected?.id
        );
        this.filtersService.updateQuarterMonthSelected(
          this.quarterMonthsSelected
        );
        return;
      }
    }
  }

  disabledCheck(isPrev: boolean, isNext: boolean) {
    if (isPrev && this.quarterMonthsSelected === this.getViewOptions()[0]) {
      return true;
    }
    if (
      isNext &&
      this.quarterMonthsSelected ===
        this.getViewOptions()[this.getViewOptions().length - 1]
    ) {
      return true;
    }
  }
}
