import { Action, createReducer, on } from '@ngrx/store';
import {
  switchBackToOwnPortfolio,
  viewStudentPortfolio,
  viewStudentPortfolioAfterBrowserReload,
  viewStudentPortfolioAfterIdle,
  viewStudentPortfolioAfterIdleSuccess,
  viewStudentPortfolioSuccess,
} from './view-student-portfolio.actions';

export interface IViewStudentPortfolioState {
  isInViewMode: boolean;
  studentId: number;
}

const initialViewStudentPortfolioState: IViewStudentPortfolioState = {
  isInViewMode: false,
  studentId: undefined,
};

const reducer = createReducer(
  initialViewStudentPortfolioState,
  on(viewStudentPortfolio, (state, action) => ({ ...state, studentId: action.studentId })),
  on(viewStudentPortfolioSuccess, state => ({ ...state, isInViewMode: true })),
  on(viewStudentPortfolioAfterIdle, (state, action) => ({ ...state, studentId: action.studentId })),
  on(viewStudentPortfolioAfterIdleSuccess, state => ({ ...state, isInViewMode: true })),
  on(switchBackToOwnPortfolio, state => ({ ...state, isInViewMode: false, studentId: undefined })),
  on(viewStudentPortfolioAfterBrowserReload, (state, action) => ({ ...state, isInViewMode: true, studentId: action.studentId })),
);

export function viewStudentPortfolioReducer(state: IViewStudentPortfolioState, action: Action) {
  return reducer(state, action);
}
