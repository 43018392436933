import { TemplateRef } from '@angular/core';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'xf-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent {
  @Input()
  text: string;

  @Input()
  contentTemplate: TemplateRef<any>;
}
