import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '@core/services/application-insights.service';
import { environment } from '@env/environment';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable()
export class ApplicationInsightsErrorHandler extends ErrorHandler {

  constructor(private applicationInsightsService: ApplicationInsightsService) {
    super();
  }

  handleError(error: Error): void {
    if (!environment.appInsights) { return; }

    this.applicationInsightsService.logException(error, SeverityLevel.Error);

    if (environment.rethrowErrors) {
      throw error;
    }
  }

}
