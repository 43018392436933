import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Localization } from '@shared/models/localization.model';
import { finalize, first, tap } from 'rxjs/operators';
import { LocalizationHttpService } from '../https/localization-http.service';
import { IAppState } from '../store/app.reducers';
import { setLocalizationsLoaded } from '../store/langauge/language.actions';
import { LoggingService } from './logging.service';

export const LOCALIZATION_LS_KEY = 'epa_localization';

@Injectable()
export class LocalizationService {
  private _localizations: Localization[] = [];
  private _localizationsByKey: { [key: string]: string } = {};
  private readonly _defaultLocalization = 'en';

  get localizations() {
    return this._localizations;
  }

  constructor(
    private store: Store<IAppState>,
    private localizationHttpService: LocalizationHttpService,
    private loggingService: LoggingService
  ) {}

  getTranslationByKey(key: string): string {
    const locKey = this._localizationsByKey[key.toLowerCase()];
    if (locKey) {
      return locKey;
    } else {
      this.loggingService.logError('missingTranslationLogs', key);
      return 'missing_' + key;
    }
  }

  getLanguageCodeLS(): string {
    return JSON.parse(localStorage.getItem(LOCALIZATION_LS_KEY)) || undefined;
  }

  setLanguageCodeLS(languageCode: string) {
    localStorage.setItem(LOCALIZATION_LS_KEY, JSON.stringify(languageCode));
  }

  setLocalization(languageCode?: string) {
    if (languageCode) {
      this.setLanguageCodeLS(languageCode);
    } else {
      if (!this.getLanguageCodeLS()) {
        this.setLanguageCodeLS(this._defaultLocalization);
      }
    }
  }

  isLocalizationByKeysEmpty(): boolean {
    return (
      Object.keys(this._localizationsByKey).length === 0 &&
      this._localizationsByKey.constructor === Object
    );
  }

  fetchAll() {
    this.store.dispatch(setLocalizationsLoaded({ loaded: false }));

    this.localizationHttpService
      .getLocalizations()
      .pipe(
        finalize(() =>
          this.store.dispatch(setLocalizationsLoaded({ loaded: true }))
        ),
        first()
      )
      .subscribe((localizations) => {
        this._localizations = localizations;

        localizations.forEach((localization) => {
          this._localizationsByKey[localization.value.toLowerCase()] =
            localization.name;
        });
      });
  }
}
