import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@core/store/app.reducers';
import { Observable } from 'rxjs';
import { selectIsLocalizationLoaded } from '@core/store/langauge/language.selectors';

@Component({
  selector: 'xf-different-version-dialog',
  templateUrl: './different-version-dialog.component.html',
  styleUrls: ['./different-version-dialog.component.scss'],
})
export class DifferentVersionDialogComponent implements OnInit, OnDestroy {
  time: number = environment.versionCheckLogoutTimeInSeconds;

  isLocalizationLoaded$: Observable<boolean>;

  private _timerInterval: any;

  constructor(
    private _store: Store<IAppState>,
    private _matDialogRef: MatDialogRef<DifferentVersionDialogComponent>
  ) {}

  ngOnInit() {
    // We need to check the localization if it is loaded so we're showing the translated text in the login page.
    // We check the localization here instead in the Interceptor because there's a conflict with the flow HttpRequest.
    this.isLocalizationLoaded$ = this._store.pipe(
      select(selectIsLocalizationLoaded)
    );

    this._timerInterval = setInterval(() => {
      this.time--;
      if (this.time === 0) {
        this._matDialogRef.close();
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this._timerInterval);
  }

  onLogout() {
    this._matDialogRef.close();
  }
}
