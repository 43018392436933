import { createAction, props } from '@ngrx/store';
import { DashboardData } from '@shared/models/dashboard-data';
import { DepartmentUserActives, DepartmentUserActivesInput, EmployeeUserActives, EmployeeUserActivesInput, FiltersList } from '@shared/models/user-activity.model';

export const getDashboardData = createAction(
  '[App] Get dashboard data',
  props<{ departmentId: number; periodOptionId: number }>(),
);

export const getDashboardDataSuccess = createAction(
  '[App] Get dashboard data (success)',
  props<{ dashboardData: DashboardData }>(),
);

export const getFiltersList = createAction(
  '[App] Get user activity filters',
);

export const getFiltersListSuccess = createAction(
  '[App] Get user activity filters (success)',
  props<{ filtersList: FiltersList }>(),
);

export const getDepartmentUserActives = createAction(
  '[App] Get user activity data',
  props<{ input: DepartmentUserActivesInput }>(),
);

export const getDepartmentUserActivesSuccess = createAction(
  '[App] Get user activity data (success)',
  props<{ userActivityData: DepartmentUserActives }>(),
);

export const getEmployeeUserActives = createAction(
  '[App] Get Employee activity data',
  props<{ input: EmployeeUserActivesInput }>(),
);

export const getEmployeeUserActivesSuccess = createAction(
  '[App] Get Employee activity data (success)',
  props<{ employeeUserActivesData: EmployeeUserActives }>(),
);

export const setDataLoaded = createAction(
  '[App] Data loaded',
  props<{ loaded: boolean }>(),
);