import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@core/utils/string.util';

/**
 * ### Usage example
 *
 * {{ 'The {0} brown {1} jumps {2}x over the lazy dog' | formatString: ['quick', 'fox', 5] }}
 *
 * // Output: The quick brown fox jumps 5x over the lazy dog
 *
 */
@Pipe({
  name: 'formatString',
})
export class FormatStringPipe implements PipeTransform {
  transform(text: string, values?: (string|number)[]) {
    return StringUtil.formatString(text, values);
  }
}
