import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

declare global {
  // tslint:disable-next-line: variable-name
  var HubSpotConversations: {
    widget: {
      remove: () => void;
      load: () => void;
    };
  };
}

@Injectable()
export class HubspotService {

  private _renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document,
    private _rendererFactory: RendererFactory2,
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
  }

  init(src: string) {
    const script = this._renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this._renderer.appendChild(this.document.body, script);

    this.chatWidgetSettings(false);
  }

  chatWidgetSettings(flag: boolean) {
    if (!window.HubSpotConversations) { return; }

    if (flag) {
      window.HubSpotConversations.widget.remove();
    } else {
      window.HubSpotConversations.widget.load();
    }
  }
}
