import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '@core/store/app.reducers';
import { IAuthState } from './auth.reducer';

const selectAuthState = createFeatureSelector<IAppState, IAuthState>('auth');

export const selectAuthIsLoading = createSelector(
  selectAuthState,
  state => state.loading,
);

export const selectIsUserLoggedIn = createSelector(
  selectAuthState,
  state => state.loggedIn,
);

export const selectUser = createSelector(
  selectAuthState,
  state => state.user,
);

export const selectAuthError = createSelector(
  selectAuthState,
  state => state.error,
);
