import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NgControl,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ObjectUtil } from '@core/utils/object.util';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { flattenEpaSubEpas } from './flatten-epa-subepas';

export class EpaSubEpa {
  linkedEPASubEPAId: string;
  isEPA: boolean;
  departmentId?: number;
}

export enum EpaSubEpaLevelEnum {
  Speciality = 0,
  Epa = 1,
  SubEpa = 2,
}

export function epaSubEpaArrayEqual(a: EpaSubEpa[], b: EpaSubEpa[]): boolean {
  if (!a || !b) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (const y of a) {
    if (!b.find((x) => x.linkedEPASubEPAId === y.linkedEPASubEPAId)) {
      return false;
    }
  }

  return true;
}

export function epaSubEpaEqual(a: EpaSubEpa, b: EpaSubEpa): boolean {
  if (!a || !b) {
    return false;
  }

  return a.linkedEPASubEPAId === b.linkedEPASubEPAId && a.isEPA === b.isEPA;
}

export class EpaSubEpaOption {
  label: string;
  value: any;
  level: number;
  completed?: number;
  required?: number;
  items?: EpaSubEpaOption[];
  className?: string;
  disabled?: boolean;
  departmentId?: number;
}

@Component({
  selector: 'xf-epa-subepa-dropdown',
  templateUrl: './epa-subepa-dropdown.component.html',
  styleUrls: ['./epa-subepa-dropdown.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: EpaSubepaDropdownComponent,
    },
  ],
})
export class EpaSubepaDropdownComponent
  implements
    OnChanges,
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    MatFormFieldControl<any>
{
  static nextId = 0;

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  @ViewChild(MatSelect)
  matSelect: MatSelect;

  @Input()
  formControl: FormControl;

  @Input()
  formControlName: string;

  @Input()
  multiple = true;

  @Input()
  set dataList(value: EpaSubEpaOption[]) {
    this._dataList = flattenEpaSubEpas(value);
    if (this._dataList) {
      // Keep a map of the labels to avoid looping on every getLabel call
      this._dataList.forEach(
        (e) => (this._labels[e.value.linkedEPASubEPAId] = e.label)
      );

      // For search
      this.filteredList = this._dataList.map((x) => x.value.linkedEPASubEPAId);
    }
  }
  get dataList(): EpaSubEpaOption[] {
    return this._dataList;
  }

  @Input()
  searchPlaceholder = 'Search';

  @Input()
  loading$: Observable<boolean>;

  @Input()
  get value(): any {
    const ids = this.control.value;
    return ids;
  }
  set value(v: any) {
    this.control.setValue(v);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.control.disable() : this.control.enable();
    this.stateChanges.next();
  }

  @Output()
  toggled = new EventEmitter<boolean>();

  @Output()
  valueChanged = new EventEmitter<any>();

  @HostBinding()
  id = `epa-subepa-dropdown-${EpaSubepaDropdownComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby')
  describedBy = '';

  get control(): AbstractControl {
    return (
      this.formControl?.value ||
      this.controlContainer.control.get(this.formControlName)
    );
  }

  get empty() {
    const n = this.control.value;
    return !n || n.length === 0;
  }

  get errorState(): boolean {
    const hasError =
      this.control.invalid && (this.touched || this.control.touched);
    if (hasError !== this._errorState) {
      this._errorState = hasError;
      this.stateChanges.next();
    }
    return hasError;
  }

  filteredList: string[] = [];
  stateChanges = new Subject<void>();
  searchForm = new FormControl();
  focused = false;
  touched = false;
  controlType = 'epa-subepa-dropdown';
  autofilled?: boolean;

  private _tempValue: any;
  private _errorState = false;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _dataList: EpaSubEpaOption[] = [];
  private _labels: { [id: string]: string } = {};
  private _unsubscribe$ = new Subject<void>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    private controlContainer: ControlContainer
  ) {
    focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      if (!this.disabled) {
        this.focused = !!origin;
        this.stateChanges.next();
      }
    });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.stateChanges.next();
    }
  }

  ngOnInit() {
    this.searchForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this._unsubscribe$))
      .subscribe((searchValue) => {
        this._filterList(searchValue);
      });
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();

    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  onSelectToggle(isToggled: boolean) {
    if (isToggled) {
      this._tempValue = this.value;
    } else if (this.multiple) {
      if (!epaSubEpaArrayEqual(this._tempValue, this.value)) {
        this.valueChanged.emit(this.value);
      }
    } else if (!epaSubEpaEqual(this._tempValue, this.value)) {
      this.valueChanged.emit(this.value);
    }

    this.toggled.emit(isToggled);
    this.searchForm.patchValue(null);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    this.touched = true;

    if (
      (event.target as Element).className
        .toLowerCase()
        .indexOf('mat-form-field') >= 0
    ) {
      this.matSelect.open();
    }
  }

  onOptionClick(option: MatOption, level: number, epaSubepa: any): void {
    if (option.disabled) {
      return;
    }

    if (level === EpaSubEpaLevelEnum.Epa) {
      this._dataList
        .filter(
          (x) =>
            x.level === EpaSubEpaLevelEnum.SubEpa &&
            x.value.linkedEPASubEPAId.match(`^${epaSubepa.linkedEPASubEPAId}-`)
        )
        .forEach((x) => (x.disabled = option.selected));
    } else if (level === EpaSubEpaLevelEnum.SubEpa) {
      const epaId = epaSubepa.linkedEPASubEPAId.split('-')[0];
      const matSelect = this.formControlDirective.valueAccessor as MatSelect;

      // If you unselect a subEpa, check first if there are no other subEpa that are still checked
      // before disabling the parent Epa.
      const hasDisabledChildren = matSelect.options
        .filter((x) => x.value?.linkedEPASubEPAId.match(`^${epaId}-`))
        .map((x) => x.selected)
        .reduce((p, c) => p || c, false);

      this._dataList
        .filter(
          (x) =>
            x.level === EpaSubEpaLevelEnum.Epa &&
            x.value.linkedEPASubEPAId === epaId
        )
        .forEach((x) => (x.disabled = option.selected || hasDisabledChildren));
    }
  }

  writeValue(obj: any): void {
    if (obj && this.dataList) {
      if (ObjectUtil.isIterable(obj)) {
        obj.forEach((e: EpaSubEpa) => {
          // We make sure that the selected EPA/sub-EPA exists from the data list.
          // There's a chance that the selected value by the user was disabled/deleted from the EPA database.
          const exists = this._dataList.some(
            (x) => x.value.linkedEPASubEPAId === e.linkedEPASubEPAId
          );
          if (exists) {
            // We make sure that the parent/children are disabled if an option is pre-selected
            this._updateDisableStatus(e);
          }
        });
      } else {
        const exists = this._dataList.some(
          (x) => x.value.linkedEPASubEPAId === obj.linkedEPASubEPAId
        );
        if (exists) {
          this._updateDisableStatus(obj);
        }
      }
    }

    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  getLabel(id: string) {
    return this._labels[id];
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    this._disabled = isDisabled;
    this.stateChanges.next();
  }

  valueComparer(value1: EpaSubEpa, value2: EpaSubEpa): boolean {
    return (
      value1?.linkedEPASubEPAId === value2?.linkedEPASubEPAId &&
      value1?.isEPA === value2?.isEPA
    );
  }

  private _updateDisableStatus(e: EpaSubEpa) {
    if (e.isEPA) {
      this._dataList
        .filter(
          (x) =>
            x.level === EpaSubEpaLevelEnum.SubEpa &&
            x.value.linkedEPASubEPAId.match(`^${e.linkedEPASubEPAId}-`)
        )
        .forEach((x) => (x.disabled = true));
    } else {
      const epaId = e.linkedEPASubEPAId.split('-')[0];

      this._dataList
        .filter(
          (x) =>
            x.level === EpaSubEpaLevelEnum.Epa &&
            x.value.linkedEPASubEPAId === epaId
        )
        .forEach((x) => (x.disabled = true));
    }
  }

  private _filterList(searchValue: string) {
    if (searchValue === null || searchValue === undefined) {
      return;
    } else if (searchValue === '') {
      this.filteredList = this._dataList.map((x) => x.value.linkedEPASubEPAId);
      return;
    }

    const filteredOptions = this._dataList
      ? this._dataList
          .filter(
            (item) =>
              item.label
                ?.toLocaleLowerCase()
                .indexOf(searchValue.toLocaleLowerCase()) > -1
          )
          .map((x) => x.value.linkedEPASubEPAId)
      : [];
    this.filteredList = filteredOptions;
  }
}
