import { Action, createReducer, on } from '@ngrx/store';
import { DashboardData } from '@shared/models/dashboard-data';
import { getDashboardDataSuccess, getDepartmentUserActivesSuccess, getEmployeeUserActivesSuccess, getFiltersListSuccess, setDataLoaded } from './dashboard.actions';
import { DepartmentUserActives, EmployeeUserActives, FiltersList } from '@shared/models/user-activity.model';

export interface IDashboardState {
  dashboardData: DashboardData;
  userActivityData: DepartmentUserActives;
  employeeUserActivesData: EmployeeUserActives;
  filtersList: FiltersList,
  dashboardLoaded: boolean;
}

const initialDashboardState: IDashboardState = {
  dashboardData: undefined,
  userActivityData: undefined,
  employeeUserActivesData: undefined,
  filtersList: undefined,
  dashboardLoaded: false,
};

const reducer = createReducer(
  initialDashboardState,
  on(getDashboardDataSuccess, (state, action) => ({ ...state, dashboardData: action.dashboardData })),
  on(getFiltersListSuccess, (state, action) => ({ ...state, filtersList: action.filtersList })),
  on(getDepartmentUserActivesSuccess, (state, action) => ({ ...state, userActivityData: action.userActivityData })),
  on(getEmployeeUserActivesSuccess, (state, action) => ({ ...state, employeeUserActivesData: action.employeeUserActivesData })),
  on(setDataLoaded, (state, action) => ({ ...state, dashboardLoaded: action.loaded })),
);

export function dashboardReducer(state: IDashboardState, action: Action) {
  return reducer(state, action);
}
