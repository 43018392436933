import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xf-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent {
  @Input()
  dialogTitle: string;

  @Input()
  isLoading: boolean;

  @Input()
  hideClose: boolean;

  @Input()
  tooltipMessage: string;

  @Input()
  overrideClose = false;

  @Output()
  closeDialog = new EventEmitter();

  @HostBinding('class') get hostClass() {
    return this.hideClose ? 'no-close-btn' : '';
  }

  constructor(public dialog: MatDialogRef<BaseDialogComponent>) {}

  onClose() {
    if (this.overrideClose) {
      this.closeDialog.emit();
    } else {
      this.dialog.close();
    }
  }
}
