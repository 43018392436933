import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IAppState } from '@core/store/app.reducers';
import { selectUserRoleId } from '@core/store/user-info/user-info.selectors';
import { select, Store } from '@ngrx/store';
import { appRoutes } from '@shared/enums/app-routes.enum';
import { UserRole } from '@shared/enums/user-role.enum';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

@Injectable()
export class UserRoleGuard implements CanActivate {
  userRole$: Observable<UserRole>;

  constructor(
    private router: Router,
    private store: Store<IAppState>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(selectUserRoleId),
      filter(userRole => !!userRole),
      first(),
      map(userRole => {
        if (userRole && route.data.roles) {
          if (route.data.roles.indexOf(userRole) === -1) {
            // role not authorised so redirect accordingly
            if (userRole === UserRole.Anonymous) {
              this.router.navigateByUrl(appRoutes.login.fullPath);
            } else if (userRole === UserRole.Admin) {
              this.router.navigateByUrl(appRoutes.userDatabase.fullPath);
            } else {
              this.router.navigateByUrl(appRoutes.dashboard.fullPath);
            }

            return false;
          }

          return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigateByUrl(appRoutes.login.fullPath, { queryParams: { returnUrl: state.url } });
        return false;
      }),
    );
  }
}
