import { Injectable } from '@angular/core';
import { IMutableForm } from '@client-shared-modules/models/mutable-form.model';

@Injectable()
export class FormService {

  private _form: IMutableForm;

  setForm(form: IMutableForm) {
    this._form = form;
  }

  currentFormHasChanges(): boolean {
    if (this._form) {
      return this._form.hasChanges();
    }

    return false;
  }
}
