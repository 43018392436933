import { Injectable } from '@angular/core';
import { apiGetBannerNotification } from '@api-client/api-urls';
import { IAppState } from '@core/store/app.reducers';
import { selectUserInfo } from '@core/store/user-info/user-info.selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { BannerNotification } from '@shared/models/banner-notification-api.model';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { filter, first, map } from 'rxjs/operators';
import { BannerNotificationHttpService } from './../https/banner-notification-http.service';
import { CacheKeyService } from './cache-key.service';
import { CacheService } from './cache.service';
import { IndexedDbService } from './indexed-db.service';

export interface ICacheBannerNotificationSetting {
  key: string;
  closed: boolean;
  userId: number;
  viewModeByUserId: number;
}

@Injectable()
export class BannerNotificationService {

  userId: number;
  isReviewMode: boolean;
  viewModeByUserId: number;
  userDataFromCache: ICacheBannerNotificationSetting | null;

  get bannerNotification$(): Observable<BannerNotification> {
    return this._bannerNotification$.asObservable();
  }

  private _bannerNotification$ = new BehaviorSubject<BannerNotification>({ key: null, message: null, active: false });

  constructor(
    private _bannerNotificationHttpService: BannerNotificationHttpService,
    private _indexedDbService: IndexedDbService,
    private _store: Store<IAppState>,
    private _cacheKeyService: CacheKeyService,
    private _cacheService: CacheService,
  ) { }

  init() {
    this._store.select(selectUserInfo)
      .pipe(
        filter(userInfo => userInfo.loaded),
        first())
      .subscribe(userInfo => {
        const { id, viewMode, viewModeByUserId } = userInfo;
        this.userId = id;
        this.isReviewMode = viewMode;
        this.viewModeByUserId = viewModeByUserId;
      });

    this.getDataFromIndexedDb();
    this.getBannerNotification();
  }

  signalRBannerNotification(notification: BannerNotification) {
    if (notification) {
      this.clearCache();
      this.getBannerNotification();
    }
  }

  setBannerNotificationClose(settings: ICacheBannerNotificationSetting) {
    this._indexedDbService.bannerNotification.put({
      userId: settings.viewModeByUserId ?? settings.userId,
      closed: settings.closed,
      key: settings.key,
    });

    this.clearCache();
    this.getDataFromIndexedDb();
    this.getBannerNotification();
  }

  private getBannerNotification() {
    this._bannerNotificationHttpService.getBannerNotification()
      .pipe(
        map(notification => this._checkBannerNotificationSettings(notification)),
        first())
      .subscribe(notification => this._bannerNotification$.next(notification));
  }

  private getDataFromIndexedDb() {
    this._indexedDbService.bannerNotification
      .get({ userId: this.isReviewMode ? this.viewModeByUserId : this.userId })
      .then(data => this.userDataFromCache = data ? data : null);
  }

  private _checkBannerNotificationSettings(notification: BannerNotification) {
    if (this.userDataFromCache) {
      const { closed, key } = this.userDataFromCache;

      if (closed && key === notification.key) {
        notification.active = false;
      }

      if (key !== notification.key && notification.active) {
        notification.active = true;
      }
    }

    return notification;
  }

  private clearCache() {
    const prefix = environment.apiUrl + apiGetBannerNotification;
    const userKey = this._cacheKeyService.getLoggedInUserKey();
    this._cacheService.removeByPrefix(userKey + '-' + prefix);
  }
}
