import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '../app.reducers';
import { ILanguageState } from './language.reducer';

export const selectLanguageState = createFeatureSelector<IAppState, ILanguageState>('language');

export const selectLanguages = createSelector(
  selectLanguageState,
  state => state.languages,
);

export const selectIsLocalizationLoaded = createSelector(
  selectLanguageState,
  state => state.localizationLoaded,
);
