import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { LocalizationService } from '../services/localization.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private static blacklistedUrls: string[] = [
    'assets/release-info.txt',
    'assets/browsers.txt',
    'mock.json',
    'assets/images/svg',
    'api.openai.com',
  ];

  constructor(
    private _authService: AuthService,
    private _localizationService: LocalizationService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (environment.production && request.url.indexOf('mock.json') >= 0) {
      return;
    }

    if (
      ApiInterceptor.blacklistedUrls.some(
        (x) => request.url.toLowerCase().indexOf(x.toLowerCase()) >= 0,
      )
    ) {
      return next.handle(request);
    }

    request = request.clone({
      headers: request.headers.set(
        'Access-Control-Expose-Headers',
        'ReleaseVersion',
      ),
    });

    const langCode = this._localizationService.getLanguageCodeLS();
    request = request.clone({
      url: `${environment.apiUrl + request.url}`,
      headers: request.headers.set('lang-code', langCode),
    });

    if (!!this._authService.userTokenLS()) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${this._authService.userTokenLS().token}`,
        ),
      });
    }

    return next.handle(request);
  }
}
