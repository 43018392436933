import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IAppState } from '@core/store/app.reducers';
import { getPortfolioTypeConfig } from '@core/store/portfolio-type-config/portfolio-type-config.actions';
import { selectPortfolioTypeConfigLoaded } from '@core/store/portfolio-type-config/portfolio-type-config.selector';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Injectable()
export class PortfolioTypeConfigResolver implements Resolve<boolean> {

  constructor(private store: Store<IAppState>) { }

  resolve(): Observable<boolean> {
    return this.store.pipe(
      select(selectPortfolioTypeConfigLoaded),
      tap(isPortfolioTypeConfigLoaded => {
        if (!isPortfolioTypeConfigLoaded) {
          this.store.dispatch(getPortfolioTypeConfig());
        }
      }),
      first(),
    );
  }
}
