import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CacheService } from './cache.service';

@Injectable()
export class CacheMonitorService {

  private _counter = 0;

  constructor(private cacheService: CacheService) {}

  tick() {
    if (!environment.cache.enabled) { return; }

    this._counter++;
    if (this._counter > environment.cache.expiryMonitorIntervalInSeconds) {
      this._removeExpiredCacheEntries();
    }
  }

  private _removeExpiredCacheEntries() {
    const max = 5;
    let count = 0;
    for (const key of this.cacheService.getKeys()) {
      const entry = this.cacheService.get(key);
      if (entry.expiry < Date.now()) {
        this.cacheService.remove(key);
        count++;

        if (count === max) {
          break;
        }
      }
    }
  }
}
