import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class IdleService implements OnDestroy {

  get isIdle(): boolean {
    return this._isIdle;
  }

  private _idleTimeInSeconds = 0;
  private _idleCounter = 0;
  private _isIdle = false;
  private _events: string[];
  private _resetIdleCounterCallback: any;

  ngOnDestroy() {
    this._events.forEach(e => {
      document.removeEventListener(e, this._resetIdleCounterCallback);
    });
  }

  init(events: string[], idleTimeInSeconds: number) {
    this._idleTimeInSeconds = idleTimeInSeconds;
    this._events = events;

    this._events.forEach(e => {
      this._resetIdleCounterCallback = this._resetIdleCounter.bind(this);
      document.addEventListener(e, this._resetIdleCounterCallback, true);
    });
  }

  tick() {
    this._idleCounter++;
    this._checkIsIdle();
  }

  private _resetIdleCounter() {
    this._idleCounter = 0;
    this._checkIsIdle();
  }

  private _checkIsIdle() {
    this._isIdle = this._idleCounter > this._idleTimeInSeconds;
  }

}
