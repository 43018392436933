import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IUserAuth } from '@regular-page-modules/auth/auth.model';

export const viewStudentPortfolio = createAction(
  '[App] View student portfolio',
  props<{ studentId: number; redirectPath?: string; }>(),
);

export const viewStudentPortfolioSuccess = createAction(
  '[App] View student portfolio (success)',
);

export const viewStudentPortfolioError = createAction(
  '[App] View student portfolio (error)',
  props<{ httpError: HttpErrorResponse }>(),
);

export const viewStudentPortfolioAfterIdle = createAction(
  '[App] View student portfolio after idle',
  props<{ studentId: number }>(),
);

export const viewStudentPortfolioAfterIdleSuccess = createAction(
  '[App] View student portfolio after idle success',
  props<{ userToken: IUserAuth }>(),
);

export const switchBackToOwnPortfolio = createAction(
  '[App] Switch back to own portfolio',
  props<{ redirectPath: string; }>(),
);

export const viewStudentPortfolioAfterBrowserReload = createAction(
  '[App] View student portfolio browser reload',
  props<{ studentId: number }>(),
);
