import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { getDashboardData, getDashboardDataSuccess, getDepartmentUserActives, getDepartmentUserActivesSuccess, getEmployeeUserActives, getEmployeeUserActivesSuccess, getFiltersList, getFiltersListSuccess } from './dashboard.actions';
import { DashboardHttpService } from '@core/https/dashboard-http.service';
import { DepartmentUserActives, EmployeeUserActives } from '@shared/models/user-activity.model';

@Injectable()
export class DashboardEffects {

  getDashboardData$ = createEffect(() => this.actions$.pipe(
    ofType(getDashboardData),
    concatMap(({ departmentId, periodOptionId }) => this.dashboardHttpService.getDashboardData(departmentId, periodOptionId)
      .pipe(
        map(dashboardData => getDashboardDataSuccess({ dashboardData })),
      ),
    ),
  ));

  getFiltersList$ = createEffect(() => this.actions$.pipe(
    ofType(getFiltersList),
    concatMap(() => this.dashboardHttpService.getFiltersList()
      .pipe(
        map(filtersList => getFiltersListSuccess({ filtersList })),
      ),
    ),
  ));

  getDepartmentUserActives$ = createEffect(() => this.actions$.pipe(
    ofType(getDepartmentUserActives),
    concatMap(({input}) => this.dashboardHttpService.getDepartmentUserActives(input)
      .pipe(
        map((userActivityData: DepartmentUserActives) => {
          const returnData: DepartmentUserActives = { ...userActivityData, departments: [{ id: null, key: null, name: 'Select/Unselect All' }, ...userActivityData?.departments] };
          return getDepartmentUserActivesSuccess({ userActivityData });
        }),
      ),
    ),
  ));

  getEmployeeUserActives$ = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeUserActives),
    concatMap(({input}) => this.dashboardHttpService.getEmployeeUserActives(input)
      .pipe(
        map((employeeUserActivesData: EmployeeUserActives) => {
          return getEmployeeUserActivesSuccess({ employeeUserActivesData });
        }),
      ),
    ),
  ));

  constructor(
    private actions$: Actions,
    private dashboardHttpService: DashboardHttpService) { }

}
