<mat-select
  panelClass="dropdown-multi-select-panel"
  epaCustomTooltip
  epaTooltipOriginX="start"
  epaTooltipOriginY="bottom"
  epaTooltipOverlayX="start"
  epaTooltipOverlayY="top"
  [multiple]="multiple"
  [epaTooltipTemplate]="linkedEPASubEPAList"
  [formControl]="control"
  [compareWith]="valueComparer"
  (openedChange)="onSelectToggle($event)">

  <mat-select-trigger>
    {{ control.value?.length > 0
        ? getLabel(control.value[0].linkedEPASubEPAId)
        : control.value ? getLabel(control.value.linkedEPASubEPAId) : '' }}
    <span *ngIf="control.value?.length > 1">`
      (+{{ control.value.length - 1 }} {{ (control.value?.length === 2 ? 'other' : 'others') | localize }})
    </span>
  </mat-select-trigger>

  <mat-option disabled class="dropdown-input-filter">
    <xf-input-filter
      [inputFilterPlaceholder]="searchPlaceholder"
      [formControl]="searchForm">
    </xf-input-filter>
  </mat-option>

  <mat-option *ngIf="loading$ | async" disabled class="dropdown-input-filter-loader">
    <div fxLayoutAlign="center center" fxLayoutGap="1em">
      <mat-spinner diameter="20"></mat-spinner>
      <span>{{ 'loading_data' | localize }}</span>
    </div>
  </mat-option>

  <mat-option
    *ngFor="let item of dataList"
    [ngClass]="item.className"
    [ngStyle]="{ 'display': filteredList.indexOf(item.value.linkedEPASubEPAId) >= 0 ? 'flex' : 'none' }"
    [value]="item.value"
    [disabled]="item.disabled"
    (click)="onOptionClick(option, item.level, item.value)"
    #option>
    <div fxLayoutAlign="space-between"
      [matTooltip]="getLabel(item.value.linkedEPASubEPAId) + ((item.level === 0) ? '' : ' - ' + item.completed + '/' + item.required)">
      <div class="mat-option-text-inner">{{ getLabel(item.value.linkedEPASubEPAId) }}</div>
      <div>
        <ng-container *ngIf="item.required !== undefined && item.completed !== undefined">
          <ng-container *ngIf="item.isParent else countWithStyle">
            <span>
              {{ item.completed }}/{{ item.required }}
            </span>
          </ng-container>
          <ng-template #countWithStyle>
            <span [ngClass]="item.completed >= item.required ? 'complete' : 'incomplete'">
              {{ item.completed }}/{{ item.required }}
            </span>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </mat-option>
</mat-select>
<ng-template #linkedEPASubEPAList>
  <ng-container *ngIf="disabled && value && value.length > 0 && !!value[0].linkedEPASubEPAId">
    <ul class="linked-epa-subepa-list-tooltip">
      <ng-container *ngFor="let epaSubEPA of value">
        <li>{{ getLabel(epaSubEPA.linkedEPASubEPAId) }}&nbsp;&nbsp;<span>({{ !!epaSubEPA.isEPA ? 'EPA' : 'sub-EPA' }})</span></li>
      </ng-container>
    </ul>
  </ng-container>
</ng-template>
