<div *ngFor="let filter of filtersDisplayed; let i = index">
  <mat-checkbox
    [disabled]="filtersDisplayed?.length === 1"
    [(ngModel)]="filter.selected"
    (ngModelChange)="filterChanged($event, filter)"
  >
    <span>{{ filter.name }}</span></mat-checkbox
  >
  <hr *ngIf="i === 0" />
</div>
