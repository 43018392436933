import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatGroupMembership',
})
export class FormatGroupMembership implements PipeTransform {

  transform(data: any): string {
    if (data.length) {
      let groupMembership = '';
      data.forEach(type => {
        const portfolioNames = type.portfolioGroupName.map(e => e.name).join('\n');
        groupMembership = groupMembership + '\n\n' + type.portfolioTypeName + ':\n' + portfolioNames;
      });

      return groupMembership;
    } else {
      return '-';
    }
  }
}
