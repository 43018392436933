import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Notification,
  PaginatedNotification,
  SignalRNotification,
} from '@client-shared-modules/models/notification.model';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationHttpService {
  constructor(private http: HttpClient) {}

  getNotifications(
    index: number = 1,
    limit: number = 5,
    notificationType:
      | 'All'
      | 'AssessmentRequestCompleted'
      | 'AssessmentRequestReject'
      | 'NewAssessment'
      | 'NewAssessmentRequest' = 'All',
    unreadOnly: boolean = false
  ): Observable<Notification[]> {
    let params = new HttpParams();

    params = params.append('Index', index.toString());
    params = params.append('Limit', limit.toString());
    params = params.append('NotificationType', notificationType);
    params = params.append('UnreadOnly', unreadOnly.toString());

    return this.http.get<Notification[]>('client/Notifications', { params });
  }

  getPaginatedNotification(
    index: number,
    limit: number,
    unreadOnly: boolean
  ): Observable<PaginatedNotification> {
    let params = new HttpParams();

    params = params.append('index', index.toString());
    params = params.append('limit', limit.toString());
    params = params.append('unreadOnly', unreadOnly.toString());

    return this.http.get<PaginatedNotification>('client/Notification', {
      params,
    });
  }

  readNotification(id: number): Observable<SignalRNotification> {
    return this.http.patch<SignalRNotification>(
      `client/Notification/ReadNotification`,
      { id }
    );
  }

  multipleReadNotification(
    ids: number[],
    isUnread: boolean,
    isSelectAll: boolean
  ): Observable<any> {
    return this.http.patch<SignalRNotification>(
      `client/Notification/MultipleReadNotification`,
      { ids, isUnread, isSelectAll }
    );
  }
}
