import { Component, OnDestroy, OnInit } from '@angular/core';
import { FiltersService } from '@core/services/filters.service';
import { LocalizationService } from '@core/services/localization.service';
import { IAppState } from '@core/store/app.reducers';
import {
  selectDashboardData,
  selectIsDashboardLoaded,
} from '@core/store/dashboard/dashboard.selectors';
import { Store, select } from '@ngrx/store';
import { DashboardTimeSavedAI } from '@shared/models/dashboard-data';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'xf-ai-implementation-cards',
  templateUrl: './ai-implementation-cards.component.html',
  styleUrls: ['./ai-implementation-cards.component.scss'],
})
export class AiImplementationCardsComponent implements OnInit, OnDestroy {
  data$: Observable<DashboardTimeSavedAI>;
  textFilterPeriodView: string;
  isLoading: boolean = false;

  private _unsubscribed$ = new Subject<void>();

  constructor(
    private store: Store<IAppState>,
    private filtersService: FiltersService,
    private _localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.data$ = this.store
      .pipe(select(selectDashboardData))
      .pipe(map((data) => data?.timeSavedAIImplementation));

    this.filtersService.filterPeriodView$
      .pipe(takeUntil(this._unsubscribed$))
      .subscribe((filter) => (this.textFilterPeriodView = filter));

    this.store
      .select(selectIsDashboardLoaded)
      .pipe(takeUntil(this._unsubscribed$))
      .subscribe((isLoaded) => (this.isLoading = !isLoaded));
  }

  ngOnDestroy() {
    this._unsubscribed$.next();
    this._unsubscribed$.complete();
  }

  classCheck(value: string) {
    return value?.includes('+') ? 'increase' : 'decrease';
  }

  iconCheck(value: string) {
    return value?.includes('+') ? 'trending_up' : 'trending_down';
  }

  valueParser(value: string) {
    return value
      ? `${value} ${this._localizationService.getTranslationByKey('hours')}`
      : this._localizationService.getTranslationByKey('n_a');
  }
}
