import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'xf-form-confirmation-dialog',
  templateUrl: './form-confirmation-dialog.component.html',
  styleUrls: ['./form-confirmation-dialog.component.scss'],
})
export class FormConfirmationDialogComponent {
  @Input()
  title: string;

  @Input()
  subTitle: string;

  @Input()
  cancelButtonLabel: string;

  @Input()
  confirmButtonLabel: string;

  @Output()
  selectCancel = new EventEmitter();

  @Output()
  selectConfirm = new EventEmitter();

  onCancel() {
    this.selectCancel.emit();
  }

  onConfirm() {
    this.selectConfirm.emit();
  }
}
