import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GroupActivityService {

  get getQueryString$(): Observable<string> {
    return this.queryString$.asObservable();
  }

  private queryString$ = new BehaviorSubject<string>(null);

  setQueryString(query: string) {
    this.queryString$.next(query);
  }

}
